define("ods-app/controllers/usuario/record/acceso", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      updatePassword(changeset, propertyName, value) {
        changeset.set(propertyName, value || null);
        const samePasswords = changeset.get('plainPassword') === changeset.get('confirmPassword'); // Clearing confirmPassword field.

        if (samePasswords && propertyName === 'plainPassword' && !changeset.get('plainPassword')) {
          changeset._deleteKey('_errors', 'confirmPassword');
        }

        if (!samePasswords) {
          changeset.addError('confirmPassword', {
            value: changeset.get('confirmPassword'),
            validation: ['No coincide con su contraseña']
          });
        }
      }

    }
  });

  _exports.default = _default;
});