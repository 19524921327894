define("ods-app/controllers/reporte-requerimientos", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentMonthIndex: Ember.computed(function () {
      let now = new Date();
      return now.getMonth();
    }),
    previousMonthIndex: Ember.computed(function () {
      let now = new Date();
      now.setMonth(now.getMonth() - 1);
      return now.getMonth();
    }),

    getSerializedFilters() {
      let startDate = this.changeset.get('startDate');
      let endDate = this.changeset.get('endDate');
      let filters = {
        cliente: this.changeset.get('cliente.id') || null,
        tipo: this.changeset.get('tipo') || null,
        startDate: startDate ? (0, _moment.default)(startDate).format() : null,
        endDate: endDate ? (0, _moment.default)(endDate).format() : null
      };
      return filters;
    }

  });

  _exports.default = _default;
});