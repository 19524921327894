define("ods-app/controllers/facturas/index", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "ods-app/models/factura"], function (_exports, _abstractModuleIndex, _factura) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    modelName: 'factura',
    modelClass: _factura.default,
    editRoute: 'proyecto.record.factura.record',
    reload: true,
    modelSortingProperties: Ember.computed(function () {
      return Ember.A([{
        propertyName: 'createdAt'
      }]);
    }),
    columns: Ember.computed(function () {
      return Ember.A([{
        label: 'Proyecto',
        valuePath: 'proyecto.codigo',
        sortable: false,
        width: '50px'
      }, {
        label: 'Cliente',
        valuePath: 'proyecto.cliente.nombre',
        sortable: false,
        width: '120px',
        filtrable: true,
        filterName: 'proyecto.cliente.nombre'
      }, {
        label: 'Servicio',
        valuePath: 'proyecto.servicioAdquirido.tipoServicioToString',
        sortable: false,
        filtrable: true,
        filterName: 'proyecto.servicioAdquirido.tipoServicio',
        filterInputType: 'select',
        filterLabelPath: 'name',
        filterTargetPath: 'key',
        filterOptions: this.constants.SERVICE_TYPES,
        width: '150px'
      }, {
        label: 'Fecha de creación',
        valuePath: 'createdAtFormatted',
        sortable: false,
        width: '50px'
      }, {
        label: 'Fecha de facturación',
        valuePath: 'fechaFacturacionFormatted',
        sortable: false,
        width: '50px'
      }, {
        label: 'Monto',
        valuePath: 'montoFormatted',
        sortable: false,
        width: '50px',
        align: 'right'
      }, {
        label: 'Estatus',
        valuePath: 'statusToString',
        sortable: false,
        filtrable: true,
        filterName: 'estatus',
        filterInputType: 'select',
        filterLabelPath: 'name',
        filterTargetPath: 'key',
        filterOptions: this.constants.BILLING_STATUS,
        width: '100px'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        sortable: false,
        width: '50px',
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.editFacturaProyecto.bind(this)
          },
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.delete.bind(this)
          }
        }
      }]);
    }),
    actions: {
      editFacturaProyecto(factura) {
        let editRoute = this.editRoute;

        if (Ember.isNone(editRoute)) {
          throw new Error('No \'editRoute\' parameter defined');
        }

        this.transitionToRoute(editRoute, factura.get('proyecto.id'), factura.get('id'));
      }

    }
  });

  _exports.default = _default;
});