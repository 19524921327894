define("ods-app/controllers/requerimiento/index", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "ods-app/models/requerimiento", "ods-app/helpers/is-empresa"], function (_exports, _abstractModuleIndex, _requerimiento, _isEmpresa) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    user: Ember.computed.alias('currentUser.user'),
    modelName: 'requerimiento',
    modelClass: _requerimiento.default,
    editRoute: 'requerimiento.record',
    currentUser: Ember.inject.service(),
    isAgrupacionGeneral: true,
    tabDataGeneral: Ember.A([]),
    tabDataPendienteIngreso: Ember.A([]),
    modelSortingProperties: Ember.computed(function () {
      return Ember.A([{
        propertyName: 'codigo'
      }]);
    }),
    includedRelationships: Ember.computed(function () {
      return Ember.A(['ternas']);
    }),
    estatus: Ember.computed(function () {
      let estatus = Ember.A([]);

      if ((0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'OPTIMUM')) {
        estatus = [this.constants.REQUIREMENT_STATUS_BORRADOR, this.constants.REQUIREMENT_STATUS_VACANTE, this.constants.REQUIREMENT_STATUS_CONGELADO];
      } else if ((0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'EL_TAIER') || (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'DEMO') || (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'SERVINOVA')) {
        estatus = [this.constants.REQUIREMENT_STATUS_BORRADOR, this.constants.REQUIREMENT_STATUS_VACANTE, this.constants.REQUIREMENT_STATUS_CONGELADO, this.constants.REQUIREMENT_STATUS_EL_TAIER_EN_REVISION, this.constants.REQUIREMENT_STATUS_EL_TAIER_PENDIENTE_DE_AUTORIZAR];
      }

      return estatus;
    }),
    modelFilters: Ember.computed(function () {
      let filtros = [];

      if ((0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'OPTIMUM')) {
        filtros.push({
          selectedFilter: {
            filterName: 'estatus'
          },
          value: [this.constants.REQUIREMENT_STATUS_BORRADOR, this.constants.REQUIREMENT_STATUS_VACANTE, this.constants.REQUIREMENT_STATUS_CONGELADO]
        });
      } else if ((0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'EL_TAIER') || (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'DEMO') || (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'SERVINOVA')) {
        let userProyectosIds = this.user.get('proyectos').map(p => p.id);
        filtros.push({
          selectedFilter: {
            filterName: 'proyecto'
          },
          value: userProyectosIds
        }, {
          selectedFilter: {
            filterName: 'estatus'
          },
          value: [this.constants.REQUIREMENT_STATUS_BORRADOR, this.constants.REQUIREMENT_STATUS_VACANTE, this.constants.REQUIREMENT_STATUS_CONGELADO, this.constants.REQUIREMENT_STATUS_EL_TAIER_EN_REVISION, this.constants.REQUIREMENT_STATUS_EL_TAIER_PENDIENTE_DE_AUTORIZAR]
        });
      }

      return filtros;
    }),
    tabDataByClientGeneral: Ember.computed('record', function () {
      let tabData = Ember.A([]);
      let addedClients = [];
      this.get('requerimientos.data').get('firstObject').forEach(cliente => {
        if (addedClients.includes(cliente.cliente_id)) {
          return;
        }

        let requerimientosFilters = [...this.get('modelFilters')];
        requerimientosFilters.pushObject({
          selectedFilter: {
            filterName: 'proyecto.cliente'
          },
          value: cliente.cliente_id
        });
        tabData.pushObject({
          title: cliente.nombre,
          filters: requerimientosFilters,
          data: Ember.A([]),
          cliente
        });
        addedClients.push(cliente.id);
      });
      return tabData;
    }),
    tabDataByEstatusGeneral: Ember.computed('record', function () {
      let tabData = Ember.A([]);
      let addEstatus = [];
      this.get('estatus').forEach(estatus => {
        if (addEstatus.includes(estatus)) {
          return;
        }

        let requerimientosFilters = [...this.get('modelFilters')];
        let constant = this.constants.REQUIREMENT_STATUS;

        if ((0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'EL_TAIER') || (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'DEMO') || (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'SERVINOVA')) {
          constant = this.constants.REQUIREMENT_STATUS_EL_TAIER;
        }

        let statusToString = constant.findBy('key', estatus);
        requerimientosFilters.pushObject({
          selectedFilter: {
            filterName: 'estatus'
          },
          value: estatus
        });
        tabData.pushObject({
          title: statusToString ? statusToString.name : estatus,
          filters: requerimientosFilters,
          data: Ember.A([]),
          estatus
        });
        addEstatus.push(estatus);
      });
      return tabData;
    }),
    modelFiltersPendientesIngreso: Ember.computed('record', function () {
      let filtros = [{
        selectedFilter: {
          filterName: 'estatus'
        },
        value: this.constants.REQUIREMENT_STATUS_CONFIRMADO_PENDIENTE
      }];

      if ((0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'EL_TAIER') || (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'DEMO') || (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'SERVINOVA')) {
        let userProyectosIds = this.user.get('proyectos').map(p => p.id);
        filtros.push({
          selectedFilter: {
            filterName: 'proyecto'
          },
          value: userProyectosIds
        });
      }

      return filtros;
    }),
    tabDataByClientPendienteIngreso: Ember.computed('record', function () {
      let tabData = Ember.A([]);
      let addedClients = [];
      this.get('requerimientosPendienteIngreso.data').get('firstObject').forEach(cliente => {
        if (addedClients.includes(cliente.cliente_id)) {
          return;
        }

        let requerimientosFilters = [...this.get('modelFiltersPendientesIngreso')];
        requerimientosFilters.pushObject({
          selectedFilter: {
            filterName: 'proyecto.cliente'
          },
          value: cliente.cliente_id
        });
        tabData.pushObject({
          title: cliente.nombre,
          filters: requerimientosFilters,
          data: Ember.A([]),
          cliente
        });
        addedClients.push(cliente.id);
      });
      return tabData;
    }),
    tabDataByEstatusPendienteIngreso: Ember.computed('record', function () {
      let tabData = Ember.A([]);
      let addEstatus = [];
      let estatus = [this.constants.REQUIREMENT_STATUS_CONFIRMADO_PENDIENTE];
      estatus.forEach(estatus => {
        if (addEstatus.includes(estatus)) {
          return;
        }

        let requerimientosFilters = [...this.get('modelFiltersPendientesIngreso')];
        let constant = this.constants.REQUIREMENT_STATUS;

        if ((0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'EL_TAIER') || (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'DEMO') || (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'SERVINOVA')) {
          constant = this.constants.REQUIREMENT_STATUS_EL_TAIER;
        }

        let statusToString = constant.findBy('key', estatus);
        requerimientosFilters.pushObject({
          selectedFilter: {
            filterName: 'estatus'
          },
          value: estatus
        });
        tabData.pushObject({
          title: statusToString ? statusToString.name : estatus,
          filters: requerimientosFilters,
          data: Ember.A([]),
          estatus
        });
        addEstatus.push(estatus);
      });
      return tabData;
    }),
    filterSituationsOptions: Ember.computed(function () {
      return [{
        key: this.constants.REQUIREMENT_SITUATION_EN_TIEMPO,
        name: 'EN TIEMPO'
      }, {
        key: this.constants.REQUIREMENT_SITUATION_FUERA_DE_TIEMPO,
        name: 'FUERA DE TIEMPO'
      }];
    }),
    columns: Ember.computed(function () {
      let columns = [{
        label: 'Código',
        valuePath: 'codigo',
        sortable: true,
        width: '60px',
        filtrable: true,
        filterName: 'codigo'
      }, {
        label: 'Fecha solicitud',
        valuePath: 'fechaSolicitudFormatted',
        sortable: true,
        width: '90px',
        sortingPropertyName: 'fechaSolicitud',
        sorted: true,
        ascending: false
      }, {
        label: 'Situación',
        valuePath: 'ternaActiva.situacion',
        sortable: false,
        width: '100px',
        filtrable: true,
        filterName: 'custom.situacion',
        filterInputType: 'select',
        filterLabelPath: 'name',
        filterTargetPath: 'key',
        filterOptions: this.filterSituationsOptions
      }];

      if ((0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'OPTIMUM')) {
        columns.push({
          label: 'Es urgente',
          valuePath: 'esUrgenteFormatted',
          sortable: false,
          width: '50px'
        });
      }

      columns.push({
        label: 'Estatus',
        valuePath: 'statusToString',
        sortable: false,
        width: (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'OPTIMUM') ? '100px' : '140px',
        filtrable: true,
        filterName: 'estatus',
        filterInputType: 'select',
        filterLabelPath: 'name',
        filterTargetPath: 'key',
        filterOptions: (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'OPTIMUM') ? this.constants.REQUIREMENT_STATUS : this.constants.REQUIREMENT_STATUS_EL_TAIER
      }, {
        label: 'Reclutador',
        valuePath: 'reclutador.username',
        sortable: false,
        width: '100px',
        filtrable: true,
        filterName: 'reclutador'
      }, {
        label: 'Cliente',
        valuePath: (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'OPTIMUM') ? 'proyecto.cliente.nombre' : 'proyecto.cliente.nombreComercial',
        sortable: false,
        width: '200px',
        filtrable: true,
        filterName: (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'OPTIMUM') ? 'proyecto.cliente.nombre' : 'proyecto.cliente.nombreComercial'
      });

      if ((0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'OPTIMUM')) {
        columns.push({
          label: 'Departamento',
          valuePath: 'departamento.nombre',
          sortable: false,
          width: '100px',
          filtrable: true,
          filterName: 'departamento.nombre'
        });
      }

      if ((0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'EL_TAIER') || (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'DEMO') || (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'SERVINOVA')) {
        columns.push({
          label: 'Depto. de FactoRH',
          valuePath: 'proyecto.factorhDepartamentoStr',
          sortable: false,
          width: '100px',
          filtrable: true,
          filterName: 'proyecto.factorhDepartamentoStr'
        });
      }

      columns.push({
        label: 'Nombre de la plaza',
        valuePath: 'nombrePlaza',
        sortable: true,
        width: '140px',
        filtrable: true,
        filterName: 'nombrePlaza'
      }, {
        label: 'Fecha de seguimiento',
        valuePath: 'fechaSeguimientoProgramadaFormatted',
        sortable: false,
        width: '90px'
      }, {
        label: 'Fecha vencimiento',
        valuePath: 'ternaActiva.fechaVencimientoEnvioFormatted',
        sortable: false,
        width: '90px'
      }, {
        label: 'Tiempo disponible',
        valuePath: 'ternaActiva.tiempoDisponible',
        sortable: false,
        width: '80px'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        sortable: false,
        width: '100px',
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.edit.bind(this)
          },
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.delete.bind(this)
          }
        }
      });
      return Ember.A(columns);
    }),
    columnsPendienteIngreso: Ember.computed(function () {
      let columnsPendienteIngreso = [{
        label: 'Código',
        valuePath: 'codigo',
        sortable: true,
        width: '60px',
        filtrable: true,
        filterName: 'codigo'
      }, {
        label: 'Fecha solicitud',
        valuePath: 'fechaSolicitudFormatted',
        sortable: true,
        width: '90px',
        sortingPropertyName: 'fechaSolicitud',
        sorted: true,
        ascending: false
      }, {
        label: 'Situación',
        valuePath: 'ternaActiva.situacion',
        sortable: false,
        width: '100px',
        filtrable: true,
        filterName: 'custom.situacion',
        filterInputType: 'select',
        filterLabelPath: 'name',
        filterTargetPath: 'key',
        filterOptions: this.filterSituationsOptions
      }];

      if ((0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'OPTIMUM')) {
        columnsPendienteIngreso.push({
          label: 'Es urgente',
          valuePath: 'esUrgenteFormatted',
          sortable: false,
          width: '60px'
        });
      }

      columnsPendienteIngreso.push({
        label: 'Estatus',
        valuePath: 'statusToString',
        sortable: false,
        width: (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'OPTIMUM') ? '100px' : '140px',
        filtrable: true,
        filterName: 'estatus',
        filterInputType: 'select',
        filterLabelPath: 'name',
        filterTargetPath: 'key',
        filterOptions: this.constants.REQUIREMENT_STATUS
      }, {
        label: 'Reclutador',
        valuePath: 'reclutador.username',
        sortable: false,
        width: '100px',
        filtrable: true,
        filterName: 'reclutador'
      });

      if ((0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'OPTIMUM')) {
        columnsPendienteIngreso.push({
          label: 'Departamento',
          valuePath: 'departamento.nombre',
          sortable: false,
          width: '100px',
          filtrable: true,
          filterName: 'departamento.nombre'
        }, {
          label: 'Cliente',
          valuePath: 'proyecto.cliente.nombre',
          sortable: false,
          width: '200px',
          filtrable: true,
          filterName: 'proyecto.cliente.nombre'
        });
      }

      if ((0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'EL_TAIER') || (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'DEMO') || (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'SERVINOVA')) {
        columnsPendienteIngreso.push({
          label: 'Cliente',
          valuePath: 'proyecto.cliente.nombreComercial',
          sortable: false,
          width: '200px',
          filtrable: true,
          filterName: 'proyecto.cliente.nombreComercial'
        }, {
          label: 'Depto. de FactoRH',
          valuePath: 'proyecto.factorhDepartamentoStr',
          sortable: false,
          width: '100px'
        });
      }

      columnsPendienteIngreso.push({
        label: 'Nombre de la plaza',
        valuePath: 'nombrePlaza',
        sortable: true,
        width: '140px',
        filtrable: true,
        filterName: 'nombrePlaza'
      });

      if ((0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'EL_TAIER') || (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'DEMO') || (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'SERVINOVA')) {
        columnsPendienteIngreso.push({
          label: 'Fecha Finalizacion',
          valuePath: 'fechaFinalizacionFormatted',
          sortable: false,
          width: '90px'
        });
      }

      if ((0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'OPTIMUM')) {
        columnsPendienteIngreso.push({
          label: 'Fecha de seguimiento',
          valuePath: 'fechaSeguimientoProgramadaFormatted',
          sortable: false,
          width: '90px'
        });
      }

      columnsPendienteIngreso.push({
        label: 'Tiempo del proceso',
        valuePath: 'cantidadDiasProceso',
        sortable: false,
        width: '80px'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        sortable: false,
        width: '100px',
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.edit.bind(this)
          } // delete: {
          //   title: 'Eliminar',
          //   buttonClass: 'btn-danger',
          //   icon: 'trash-o',
          //   action: this.actions.delete.bind(this),
          // }

        }
      });
      return Ember.A(columnsPendienteIngreso);
    }),
    actions: {
      agrupacionGeneral() {
        this.set('isAgrupacionGeneral', true);
      },

      agrupacionCliente() {
        this.set('tabDataGeneral', this.get('tabDataByClientGeneral'));
        this.set('tabDataPendienteIngreso', this.get('tabDataByClientPendienteIngreso'));
        this.set('isAgrupacionGeneral', false);
      },

      agrupacionEstatus() {
        this.set('tabDataGeneral', this.get('tabDataByEstatusGeneral'));
        this.set('tabDataPendienteIngreso', this.get('tabDataByEstatusPendienteIngreso'));
        this.set('isAgrupacionGeneral', false);
      }

    }
  });

  _exports.default = _default;
});