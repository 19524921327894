define("ods-app/routes/requerimiento/record/informacion/link-portal-web", ["exports", "ods-app/helpers/value-config"], function (_exports, _valueConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    toast: Ember.inject.service(),
    loader: Ember.inject.service(),
    indexRoute: 'requerimiento.record.informacion',

    model() {
      let requerimiento = this.modelFor('requerimiento.record').record;

      if (!requerimiento.get('hashLinkAplicaciones')) {
        this.toast.error('Ha habido un problema al cargar el link al portal web.');
        return this.transitionTo(this.indexRoute);
      }
    },

    setupController(controller, model) {
      this._super(controller, model);

      let requerimiento = this.modelFor('requerimiento.record').record;
      let url = (0, _valueConfig.valueConfig)(this.currentUser.get('configs'), 'LINK_PORTAL'); // Si el cliente del proyecto que tiene el requerimiento es Tribal,
      // se busca una configuración diferente.

      if (requerimiento.get('proyecto.cliente.codigo') == '10548702-3') {
        url = (0, _valueConfig.valueConfig)(this.currentUser.get('configs'), 'LINK_PORTAL_TRIBAL');
      }

      controller.set('link', url + 'aplicacion/' + requerimiento.get('hashLinkAplicaciones'));
    }

  });

  _exports.default = _default;
});