define("ods-app/templates/proyecto/record/empleado/cargos/cargo-submit-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oNbGHGRx",
    "block": "{\"symbols\":[],\"statements\":[[1,[26,\"forms/controls/ace-async-save-button\",null,[[\"onClick\"],[[26,\"action\",[[21,0,[]],\"submit\"],null]]]],false],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ods-app/templates/proyecto/record/empleado/cargos/cargo-submit-button.hbs"
    }
  });

  _exports.default = _default;
});