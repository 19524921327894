define("ods-app/models/modelo-configuracion", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    nombreModelo: attr('string'),
    usaCampoIdentificadorAutomatico: attr('boolean', {
      defaultValue: false
    }),
    formatoCampoIdentificador: attr('string'),
    numeroCorrelativoActual: attr('number'),
    usaCorrelativoAutoincrementable: attr('boolean', {
      defaultValue: false
    }),
    nombreCampoIdentificadorAutomatico: attr('string'),
    permiteActualizarCampoIdentificador: attr('boolean', {
      defaultValue: false
    })
  });

  _exports.default = _default;
});