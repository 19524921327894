define("ods-app/routes/proyecto/record/empleado/informacion", ["exports", "servir-ember-utilities/routes/abstract-module-record", "ember-changeset-validations/validators"], function (_exports, _abstractModuleRecord, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    modelName: 'proyecto-candidato',
    indexRoute: 'proyecto.record.empleado',
    validateOnChange: false,
    routeRecordIdentifierDynamicSegment: 'empleado_id',
    validations: Ember.computed(function () {
      return {
        proyecto: (0, _validators.validatePresence)({
          presence: true
        }),
        candidato: (0, _validators.validatePresence)({
          presence: true
        }),
        fechaAlta: (0, _validators.validatePresence)({
          presence: true
        })
      };
    }),

    setupController(controller, model) {
      this._super(controller, model);

      controller.setProperties(model);
    }

  });

  _exports.default = _default;
});