define("ods-app/routes/cliente/record/rangos-salariales/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "ember-changeset-validations/validators", "servir-ember-utilities/validators/custom-presence", "servir-ember-utilities/validators/is-codigo"], function (_exports, _abstractModuleRecord, _validators, _customPresence, _isCodigo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    routeRecordIdentifierDynamicSegment: 'rangossalariales_id',
    modelName: 'rango-salarial',
    indexRoute: 'cliente.record.rangos-salariales',
    currentUser: Ember.inject.service(),
    validations: Ember.computed(function () {
      return {
        codigo: [(0, _customPresence.default)({
          presence: true
        }), (0, _isCodigo.default)()],
        salarioMonedaLocal: (0, _validators.validateNumber)({
          gte: 0
        }),
        monedaLocal: (0, _validators.validatePresence)({
          presence: true
        }),
        salarioMonedaExtranjera: (0, _validators.validateNumber)({
          gte: 0
        }),
        monedaExtranjera: (0, _validators.validatePresence)({
          presence: true
        })
      };
    }),
    relatedModelsToQuery: Ember.computed(function () {
      let relatedModels = [{
        name: 'monedas',
        modelName: 'moneda'
      }];
      return relatedModels;
    }),

    createRecordInstance() {
      const cliente = this.modelFor('cliente.record').record;
      const modelName = this.get('modelName');
      return this.store.createRecord(modelName, {
        cliente
      });
    },

    setupController(controller, model) {
      this._super(controller, model);

      if (model.record.get('isNew')) {
        let changeset = controller.changeset;
        let monedaLocal = controller.get('monedas').findBy('codigo', this.constants.CODIGO_MONEDA_QUETZAL);
        let monedaExtranjera = controller.get('monedas').findBy('codigo', this.constants.CODIGO_MONEDA_DOLAR);
        changeset.set('monedaLocal', monedaLocal);
        changeset.set('monedaExtranjera', monedaExtranjera);
      }
    },

    actions: {
      willTransition() {
        return true;
      }

    }
  });

  _exports.default = _default;
});