define("ods-app/templates/components/app-breadcrumbs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NzgTKwm9",
    "block": "{\"symbols\":[\"breadcrumb\"],\"statements\":[[6,\"div\"],[10,\"class\",\"breadcrumbs ace-save-state\"],[8],[0,\"\\n  \"],[6,\"ul\"],[10,\"class\",\"breadcrumb\"],[8],[0,\"\\n    \"],[6,\"li\"],[8],[0,\"\\n\"],[4,\"link-to\",[\"index\"],[[\"class\"],[\"dropdown-toggle\"]],{\"statements\":[[0,\"        Inicio\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\\n\"],[4,\"each\",[[22,[\"breadcrumbs\"]]],null,{\"statements\":[[0,\"      \"],[6,\"li\"],[8],[0,\"\\n\"],[4,\"if\",[[21,1,[\"queryParam\"]]],null,{\"statements\":[[4,\"link-to\",[[21,1,[\"route\"]],[21,1,[\"queryParam\"]]],[[\"disabled\"],[[21,1,[\"disabled\"]]]],{\"statements\":[[0,\"            \"],[1,[21,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"link-to\",[[21,1,[\"route\"]]],[[\"disabled\"],[[21,1,[\"disabled\"]]]],{\"statements\":[[0,\"            \"],[1,[21,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ods-app/templates/components/app-breadcrumbs.hbs"
    }
  });

  _exports.default = _default;
});