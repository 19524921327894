define("ods-app/components/validate-link-to", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    currentUser: Ember.inject.service(),
    hasPermission: Ember.computed('permissionCode', function () {
      return this.get('currentUser').hasPermission(this.get('permissionCode'));
    })
  });

  _exports.default = _default;
});