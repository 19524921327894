define("ods-app/routes/servicio/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "servir-ember-utilities/validators/custom-presence", "ember-changeset-validations/validators", "servir-ember-utilities/validators/is-codigo", "ods-app/mixins/permission-validator"], function (_exports, _abstractModuleRecord, _customPresence, _validators, _isCodigo, _permissionValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend(_permissionValidator.default, {
    modelName: 'servicio',
    indexRoute: 'servicio',
    routePermission: 'VIEW_SERVICES',
    validations: Ember.computed(function () {
      return {
        codigo: [(0, _customPresence.default)({
          presence: true
        }), (0, _isCodigo.default)()],
        nombre: (0, _validators.validatePresence)({
          presence: true
        }),
        tipoServicio: (0, _validators.validatePresence)({
          presence: true
        }),
        periodicidad: (0, _validators.validatePresence)({
          presence: true
        })
      };
    }),

    createRecordInstance() {
      return this.store.createRecord('servicio', {
        tipoServicio: this.constants.SERVICE_TYPES.get('firstObject.key'),
        periodicidad: this.constants.PERIODICITY.get('firstObject.key')
      });
    },

    actions: {
      willTransition() {
        return true;
      }

    }
  });

  _exports.default = _default;
});