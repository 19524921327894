define("ods-app/mirage/fixtures/proyectos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    nombreCliente: 'CEMPRO',
    montoFacturar: '3000',
    fechaArranqueServicio: '2020-03-03',
    estatus: 'Activo',
    servicio: 'Reclutamiento y selección'
  }];
  _exports.default = _default;
});