define("ods-app/controllers/cliente/record/datos-facturacion", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "ods-app/utils/common"], function (_exports, _abstractModuleIndex, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    modelName: 'cliente-dato-facturacion',
    editRoute: 'cliente.record.datos-facturacion.record',
    columns: Ember.computed(function () {
      return Ember.A([{
        label: 'NIT',
        valuePath: 'nit',
        sortable: false,
        width: '100px'
      }, {
        label: 'Razón social',
        valuePath: 'razonSocial',
        sortable: false,
        width: '100px'
      }, {
        label: 'Dirección fiscal',
        valuePath: 'direccionFiscal',
        sortable: false,
        width: '150px'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        width: '100px',
        sortable: false,
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.edit.bind(this)
          },
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.delete.bind(this)
          }
        }
      }]);
    }),

    reloadModel() {
      return this.store.query('cliente-dato-facturacion', {
        filter: {
          cliente: this.record.get('id')
        }
      }).then(datosFacturacion => {
        this.model.datosFacturacion.setObjects(datosFacturacion);
      });
    },

    actions: {
      delete(datoFacturacion) {
        this.send('openModal', 'popups/simple-confirm', {
          message: '¿Está seguro de eliminar el registro?',
          onSubmit: () => {
            datoFacturacion.destroyRecord().then(() => {
              this.toast.success('Registro eliminado correctamente.');
            }).catch(error => (0, _common.handleDeleteError)(datoFacturacion, this.toast, error));
          }
        });
      }

    }
  });

  _exports.default = _default;
});