define("ods-app/controllers/requerimiento/record/informacion/link-portal-web", ["exports", "servir-ember-utilities/controllers/abstract-module-record"], function (_exports, _abstractModuleRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    indexRoute: 'requerimiento.record.informacion',
    link: null,
    actions: {
      discardChanges() {
        this.transitionToRoute(this.get('indexRoute'));
      },

      copyToClipboard() {
        if (!this.link) {
          this.toast.error('El enlace no existe.');
          return;
        }
        /**
         * WARNING: Unable to use "ember-cli-clipboard" with Bootstrap Modals.
         * Fixed in v0.15.0 but compatible with ember-source@3.17
         * Error: Assertion Failed: You cannot use `elementId` on a tag-less component
         * Ref: https://github.com/jkusa/ember-cli-clipboard/issues/180
         *
         * WARNING: document.execCommand() is deprecated.
         */


        navigator.clipboard.writeText(this.link).then(() => this.toast.info('Enlace copiado correctamente.')).catch(error => {
          this.toast.error('No se puede copiar el enlace. Debe realizarlo manualmente.');
          throw error;
        });
      }

    }
  });

  _exports.default = _default;
});