define("ods-app/adapters/application", ["exports", "ember-data", "ember-simple-auth/mixins/data-adapter-mixin"], function (_exports, _emberData, _dataAdapterMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPIAdapter.extend(_dataAdapterMixin.default, {
    config: Ember.inject.service(),
    host: Ember.computed.readOnly('config.API.host'),
    namespace: Ember.computed.readOnly('config.API.namespace'),
    authorizer: Ember.computed.readOnly('config.ember-simple-auth.authorizer'),
    headers: Ember.computed('session.data.authenticated.token', function () {
      let headers = {};

      if (this.session.isAuthenticated) {
        headers['Authorization'] = `Bearer ${this.session.data.authenticated.token}`;
      }

      return headers;
    }),

    /**
     * Automatically add page_enabled=null query parameter to all queries if
     * pagination is not present.
     *
     * @param  {[type]} store [description]
     * @param  {[type]} type  [description]
     * @param  {[type]} query [description]
     * @return {[type]}       [description]
     */
    query(store, type, query) {
      if (Ember.isNone(query.filter) || Ember.isNone(query.filter.page_number)) {
        if (Ember.isNone(query.filter)) {
          query.filter = {};
        }

        query.filter.page_enabled = null;
      }

      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});