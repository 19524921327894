define("ods-app/controllers/requerimiento/record/informacion-compensacion", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "ods-app/models/requerimiento-archivo", "ods-app/mixins/upload-helper"], function (_exports, _abstractModuleIndex, _requerimientoArchivo, _uploadHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend(_uploadHelper.default, {
    indexCtrl: Ember.inject.controller('requerimiento.record'),
    modelClass: _requerimientoArchivo.default,
    editRoute: 'requerimiento.record.informacion-compensacion.planilla-cargo',
    reload: true,
    modelFilters: Ember.computed('record', function () {
      return [{
        selectedFilter: {
          filterName: 'requerimiento'
        },
        value: this.get('record.id') || -1
      }];
    }),
    planillaCargosColumns: Ember.computed('record.{esEditable,estaDeshabilitado}', function () {
      let columns = [{
        label: 'Cargo',
        valuePath: 'planillaCargo.nombre',
        width: '100px',
        sortable: false,
        titleValuePath: 'observaciones',
        cellComponent: 'content-with-title-cell'
      }, {
        label: 'Monto',
        valuePath: 'montoFormatted',
        width: '75px',
        sortable: false,
        align: 'right'
      }, {
        label: 'Desc. empleado',
        valuePath: 'generaDescuentoEmpleadoFormatted',
        width: '75px',
        sortable: false
      }];

      if (this.record.get('esEditable') && !this.record.get('estaDeshabilitado')) {
        columns.pushObject({
          label: 'Acciones',
          cellComponent: 'tables/cell/object-actions',
          table: this,
          sortable: false,
          width: '100px',
          // WARNING: Cuando se recomputan las columnas, la tabla no se actualiza.
          objectActions: {
            edit: {
              title: 'Editar',
              buttonClass: 'btn-primary',
              icon: 'pencil-square-o',
              action: this.actions.preEdit.bind(this)
            },
            delete: {
              title: 'Eliminar',
              buttonClass: 'btn-danger',
              icon: 'trash-o',
              action: this.actions.preDelete.bind(this)
            }
          }
        });
      }

      return Ember.A(columns);
    }),
    documentosColumns: Ember.computed(function () {
      return Ember.A([{
        label: 'Nombre',
        valuePath: 'nombre',
        sortable: false,
        width: '100px'
      }, {
        label: 'Observaciones',
        valuePath: 'observaciones',
        sortable: false,
        width: '100px'
      }, {
        label: 'Fecha',
        valuePath: 'createdAtFormatted',
        sortable: false,
        width: '50px'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        sortable: false,
        width: '60px',
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.editarDocumento.bind(this)
          },
          descargarDocumento: {
            title: 'Descargar documento',
            buttonClass: 'btn-success',
            icon: 'download',
            action: this.downloadFile.bind(this)
          },
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.delete.bind(this)
          }
        }
      }]);
    }),

    validarEsEditable() {
      if (this.record.get('noEsEditable') || this.record.get('estaDeshabilitado')) {
        this.toast.error('Ya no se puede modificar el requerimiento.');
        return false;
      }

      return true;
    },

    reloadModel() {
      this.set('reload', true);
    },

    actions: {
      preEdit() {
        if (this.validarEsEditable()) {
          this.actions.edit.call(this, ...arguments);
        }
      },

      preDelete() {
        if (this.validarEsEditable()) {
          this.actions.delete.call(this, ...arguments);
        }
      },

      editarDocumento(documento) {
        let editRoute = 'requerimiento.record.informacion-compensacion.documentos'; // If the item is new, transition with the whole object (wont trigger the
        // model hook), otherwise transition with its id (will trigger the model hook)

        if (Ember.isNone(documento.get('id'))) {
          this.transitionToRoute(editRoute, documento);
          return;
        }

        this.transitionToRoute(editRoute, documento.get('id'));
      },

      onChangeTipoPlaza(changeset, propertyName, tipoPlaza = null) {
        changeset.set(propertyName, tipoPlaza);
        this.indexCtrl.updateInformacionCompensacion(changeset.get('esBorderless'));
      },

      onChangeMonedaUtilizarRangoSalarial(changeset, propertyName, monedaUtilizarRangoSalarial = null) {
        changeset.set(propertyName, monedaUtilizarRangoSalarial);
        let esMonedaExtranjera = monedaUtilizarRangoSalarial === this.constants.REQUERIMIENTO_MONEDA_RANGO_SALARIAL_EXTRANJERA ? true : false;
        this.indexCtrl.updateInformacionCompensacion(esMonedaExtranjera);
      },

      updateCargaLaboral(changeset, propertyName, value = null) {
        changeset.set(propertyName, value);
        let carga = 0;
        let tipoPlaza = changeset.get('tipoPlaza');

        if (tipoPlaza == this.constants.HIRING_TYPES_EL_TAIER_FACTURACION_INTERNA_14_MESES) {
          carga = parseInt(changeset.get('montoHonorario')) * this.constants.VALOR_COMPENSACION_CARGA;
        } else if (tipoPlaza == this.constants.HIRING_TYPES_EL_TAIER_PLANILLA) {
          carga = (parseInt(changeset.get('salarioBase')) + parseInt(changeset.get('bonificacionDecreto'))) * this.constants.VALOR_COMPENSACION_CARGA;
        } else if (tipoPlaza == this.constants.HIRING_TYPES_EL_TAIER_FACTURACION_EXTERNA || tipoPlaza == this.constants.HIRING_TYPES_EL_TAIER_FACTURACION_INTERNA_12_MESES) {
          carga = parseInt(changeset.get('montoHonorario'));
        }

        changeset.set('cargaLaboral', parseInt(carga));
      }

    }
  });

  _exports.default = _default;
});