define("ods-app/templates/cliente/record/contactos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AgxUa/il",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[6,\"div\"],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"actions\"],[8],[0,\"\\n\"],[4,\"link-to\",[[22,[\"editRoute\"]],\"new\"],[[\"classNames\",\"disabled\"],[\"btn btn-primary btn-xs pull-right\",[22,[\"model\",\"params\",\"isEdit\"]]]],{\"statements\":[[0,\"        \"],[6,\"i\"],[10,\"class\",\"fa fa-plus-circle\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n        Crear\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\\n\"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[6,\"div\"],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n    \"],[1,[26,\"tables/simple-table\",null,[[\"model\",\"modelName\",\"columns\",\"enableSync\"],[[22,[\"model\",\"contactos\"]],[22,[\"modelName\"]],[22,[\"columns\"]],true]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[20,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ods-app/templates/cliente/record/contactos.hbs"
    }
  });

  _exports.default = _default;
});