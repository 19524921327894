define("ods-app/controllers/cliente/record/datos-generales/factorh-empresa", ["exports", "servir-ember-utilities/controllers/abstract-module-record"], function (_exports, _abstractModuleRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    indexRoute: 'cliente.record.datos-generales',
    factorhEmpresa: null,
    actions: {
      onChange(factorhEmpresa) {
        if (!factorhEmpresa) {
          this.changeset.set('factorhEmpresaId', null);
          this.changeset.set('factorhEmpresaStr', null);
          return;
        }

        this.changeset.set('factorhEmpresaId', factorhEmpresa.id);
        this.changeset.set('factorhEmpresaStr', factorhEmpresa.abreviatura);
      },

      discardChanges() {
        this.changeset.rollback();
        this.record.rollbackAttributes();
        this.transitionToRoute(this.get('indexRoute'));
      }

    }
  });

  _exports.default = _default;
});