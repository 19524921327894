define("ods-app/templates/proyecto/record/empleado/cargos/cargo-record-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BKncrzme",
    "block": "{\"symbols\":[],\"statements\":[[4,\"bs-button\",null,[[\"onClick\",\"size\",\"type\"],[[26,\"action\",[[21,0,[]],\"record\"],null],\"xs\",\"success\"]],{\"statements\":[[0,\"  Nuevo\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ods-app/templates/proyecto/record/empleado/cargos/cargo-record-button.hbs"
    }
  });

  _exports.default = _default;
});