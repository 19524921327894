define("ods-app/models/requerimiento-planilla-cargo", ["exports", "ember-data", "accounting/format-number"], function (_exports, _emberData, _formatNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    requerimiento: belongsTo('requerimiento'),
    planillaCargo: belongsTo('planilla-cargo'),
    monto: attr('number', {
      defaultValue: 0
    }),
    generaDescuentoEmpleado: attr('boolean', {
      defaultValue: false
    }),
    generaDescuentoEmpleadoFormatted: Ember.computed('generaDescuentoEmpleado', function () {
      return this.generaDescuentoEmpleado ? 'Sí' : 'No';
    }),
    observaciones: attr('string'),
    montoFormatted: Ember.computed('monto', function () {
      return (0, _formatNumber.default)(this.get('monto'), 2);
    })
  });

  _exports.default = _default;
});