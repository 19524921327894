define("ods-app/utils/common", ["exports", "ember-data", "ember-changeset/utils/is-changeset"], function (_exports, _emberData, _isChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.handleChangesetSaveErrors = handleChangesetSaveErrors;
  _exports.handleDeleteError = handleDeleteError;
  _exports.default = void 0;
  const {
    InvalidError
  } = _emberData.default;

  function handleChangesetSaveErrors(entity, error, toast, throwError = true) {
    // Ignore errors of type TransitionAborted.
    if (error.name && error.name === 'TransitionAborted') {
      return;
    }

    if (error instanceof InvalidError && (0, _isChangeset.default)(entity)) {
      entity.get('_content').get('errors').forEach(error => {
        if ('base' === error.attribute) {
          return;
        }

        entity.addError(error.attribute, [error.message]);
      });
    }

    if (Array.isArray(error.errors)) {
      error.errors.forEach(e => (e.message || e.title) && toast.error(e.message || e.title));
    }

    if (throwError) {
      throw error;
    }
  }

  function handleDeleteError(entity, toast, error) {
    entity.rollbackAttributes();

    if (Array.isArray(error.errors)) {
      error.errors.forEach(error => toast.error(error.message));
    } else {
      toast.error('No se pudo eliminar el registro.');
    }

    throw error;
  }

  var _default = {
    handleChangesetSaveErrors,
    handleDeleteError
  };
  _exports.default = _default;
});