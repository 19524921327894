define("ods-app/models/fuente-reclutamiento", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    codigo: attr('string'),
    nombre: attr('string'),
    tipo: attr('string'),
    activo: attr('boolean'),
    typeToString: Ember.computed('tipo', function () {
      const type = this.constants.REQUIREMENT_SOURCE_TYPES.findBy('key', this.tipo);
      return type ? type.name : this.tipo;
    }),
    activoFormatted: Ember.computed('activo', function () {
      return this.activo ? 'Sí' : 'No';
    })
  });

  _exports.default = _default;
});