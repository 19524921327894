define("ods-app/components/content-with-title-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Este componente es rendereado por node_modules/ember-light-table/addon/templates/components/cells/base.hbs
   * y recibe los siguientes argumentos:
   *   tableActions
   *   extra
   *   table
   *   column
   *   row
   *   value
   *   rawValue
   */
  var _default = Ember.Component.extend({
    titleContent: Ember.computed('column.titleValuePath', function () {
      if (!this.get('column.titleValuePath')) {
        return '';
      }

      return this.get('row.content').get(this.get('column.titleValuePath'));
    })
  });

  _exports.default = _default;
});