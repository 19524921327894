define("ods-app/controllers/application", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    loader: Ember.inject.service(),
    breadcrumbs: null,
    user: Ember.computed.readOnly('currentUser.user'),
    isAuthenticated: Ember.computed.and('session.isAuthenticated', 'user'),
    userReloader: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(10800);

      if (this.get('user')) {
        this.get('user').reload();
      }

      this.get('userReloader').perform();
    }).restartable(),

    init() {
      this._super(...arguments);

      this.get('userReloader').perform();
    },

    actions: {
      refreshBreadCrumbs(newBreadcrumbs) {
        this.set('breadcrumbs', newBreadcrumbs);
      }

    }
  });

  _exports.default = _default;
});