define("ods-app/routes/dashboard", ["exports", "servir-ember-utilities/routes/abstract-module-index", "ods-app/mixins/permission-validator", "ods-app/helpers/is-empresa"], function (_exports, _abstractModuleIndex, _permissionValidator, _isEmpresa) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend(_permissionValidator.default, {
    routePermission: 'VIEW_DASHBOARD',
    user: Ember.computed.alias('currentUser.user'),
    currentUser: Ember.inject.service(),
    breadcrumbsRoute: Ember.computed(function () {
      return [{
        name: 'Tablero',
        route: 'dashboard'
      }];
    }),

    model() {
      let userId = this.user.get('id');

      if (!this.user.get('permissions')) {
        throw new Error('Ha ocurrido un problema cargado los permisos del usuario.');
      } else if ('VIEW_ALL_REQUIREMENTS_DASHBOARD' in this.user.get('permissions')) {
        userId = null;
      }

      let estatusArray = [];

      if ((0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'OPTIMUM')) {
        estatusArray = [this.constants.REQUIREMENT_STATUS_VACANTE, this.constants.REQUIREMENT_STATUS_CONFIRMADO_PENDIENTE, this.constants.REQUIREMENT_STATUS_CONGELADO];
      } else if ((0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'EL_TAIER') || (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'DEMO') || (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'SERVINOVA')) {
        estatusArray = [this.constants.REQUIREMENT_STATUS_EL_TAIER_EN_REVISION, this.constants.REQUIREMENT_STATUS_EL_TAIER_PENDIENTE_DE_AUTORIZAR, this.constants.REQUIREMENT_STATUS_EL_TAIER_VACANTE];
      }

      let queries = {
        requerimientosEnSeguimiento: this.store.query('requerimiento', {
          filter: {
            reclutador: userId,
            'custom.enSeguimiento': 1,
            estatus: estatusArray,
            esReprocesoDe: {
              exists: 0
            }
          },
          include: 'proyecto, proyecto.cliente'
        }),
        requerimientoEnTerna: this.store.query('requerimiento', {
          filter: {
            reclutador: userId,
            'custom.enSeguimiento': 0,
            estatus: estatusArray,
            esReprocesoDe: {
              exists: 0
            }
          },
          include: 'proyecto, proyecto.cliente'
        }),
        requerimientosDeReproceso: this.store.query('requerimiento', {
          filter: {
            reclutador: userId,
            estatus: estatusArray,
            esReprocesoDe: {
              exists: 1
            }
          },
          include: 'proyecto, proyecto.cliente'
        })
      };
      return Ember.RSVP.hash(queries).then(data => {
        let clientesIds = Ember.A();
        clientesIds.addObjects(data.requerimientosEnSeguimiento.mapBy('proyecto.content.cliente.id'));
        clientesIds.addObjects(data.requerimientoEnTerna.mapBy('proyecto.content.cliente.id'));
        clientesIds.addObjects(data.requerimientosDeReproceso.mapBy('proyecto.content.cliente.id'));

        if (!clientesIds.length) {
          return data;
        }

        return this.store.query('cliente', {
          filter: {
            id: clientesIds.uniq()
          }
        }).then(() => data);
      });
    },

    setupController(controller, model) {
      this._super(controller, model);

      controller.setProperties(model);
    }

  });

  _exports.default = _default;
});