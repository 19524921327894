define("ods-app/routes/candidato/record/documentos", ["exports", "servir-ember-utilities/mixins/route/is-tab-subroute"], function (_exports, _isTabSubroute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_isTabSubroute.default, {
    setupController(controller) {
      this._super(...arguments);

      controller.set('reload', true);
    }

  });

  _exports.default = _default;
});