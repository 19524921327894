define("ods-app/templates/components/content-with-title-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GZbTbt+b",
    "block": "{\"symbols\":[],\"statements\":[[6,\"span\"],[11,\"title\",[27,[[20,\"titleContent\"]]]],[8],[1,[20,\"value\"],false],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ods-app/templates/components/content-with-title-cell.hbs"
    }
  });

  _exports.default = _default;
});