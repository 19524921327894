define("ods-app/routes/requerimiento/record/entrevista/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "ember-changeset-validations/validators", "ods-app/helpers/is-empresa"], function (_exports, _abstractModuleRecord, _validators, _isEmpresa) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    routeRecordIdentifierDynamicSegment: 'entrevista_id',
    modelName: 'entrevista',
    indexRoute: 'requerimiento.record.entrevista',
    currentUser: Ember.inject.service(),
    requerimientoPlaceHolderId: null,
    aplicacionPlaceHolderId: null,
    validations: Ember.computed(function () {
      let validaciones = {
        fecha: (0, _validators.validatePresence)({
          presence: true
        }),
        aplicacion: (0, _validators.validatePresence)({
          presence: true
        }),
        tipo: (0, _validators.validatePresence)({
          presence: true
        })
      };

      if ((0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'OPTIMUM')) {
        validaciones = Object.assign(validaciones, {
          estatus: (0, _validators.validatePresence)({
            presence: true
          })
        });
      }

      return validaciones;
    }),
    relatedModelsToQuery: Ember.computed('requerimientoPlaceHolderId', 'aplicacionPlaceHolderId', function () {
      const requerimiento = this.modelFor('requerimiento.record').record;
      let relatedModels = [];
      relatedModels.push({
        name: 'aplicaciones',
        modelName: 'aplicacion',
        queryParams: {
          filter: {
            requerimiento: requerimiento.get('id')
          }
        }
      });

      if (this.get('requerimientoPlaceHolderId')) {
        relatedModels.push({
          name: 'requerimientosPlaceHolder',
          modelName: 'requerimiento',
          queryParams: {
            filter: {
              id: this.get('requerimientoPlaceHolderId')
            }
          }
        });
      }

      if (this.get('aplicacionPlaceHolderId')) {
        relatedModels.push({
          name: 'aplicacionesPlaceHolder',
          modelName: 'aplicacion',
          queryParams: {
            filter: {
              id: this.get('aplicacionPlaceHolderId')
            }
          }
        });
      }

      return relatedModels;
    }),

    createRecordInstance(params, transition) {
      const requerimiento = this.modelFor('requerimiento.record').record;
      const modelName = this.get('modelName');

      if (!Ember.isNone(transition.queryParams.requerimientoId)) {
        this.set('requerimientoPlaceHolderId', transition.queryParams.requerimientoId);
      }

      if (!Ember.isNone(transition.queryParams.aplicacionId)) {
        this.set('aplicacionPlaceHolderId', transition.queryParams.aplicacionId);
      }

      return this.store.createRecord(modelName, {
        requerimiento
      });
    },

    setupController(controller, model) {
      this._super(controller, model);

      if (!Ember.isNone(controller.get('requerimientosPlaceHolder'))) {
        let entrevista = model.record;
        let requerimiento = controller.get('requerimientosPlaceHolder.firstObject');
        entrevista.set('requerimiento', requerimiento);
      }

      if (!Ember.isNone(controller.get('aplicacionesPlaceHolder'))) {
        let entrevista = model.record;
        let aplicacion = controller.get('aplicacionesPlaceHolder.firstObject');
        entrevista.set('aplicacion', aplicacion);
      }
    },

    actions: {
      willTransition() {
        return true;
      }

    }
  });

  _exports.default = _default;
});