define("ods-app/routes/cliente/record/referencias/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "ember-changeset-validations/validators"], function (_exports, _abstractModuleRecord, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    routeRecordIdentifierDynamicSegment: 'referencia_id',
    modelName: 'cliente-referencia',
    indexRoute: 'cliente.record.referencias',
    validations: Ember.computed(function () {
      return {
        tipo: (0, _validators.validatePresence)({
          presence: true
        }),
        nombre: (0, _validators.validatePresence)({
          presence: true
        }),
        contacto: (0, _validators.validatePresence)({
          presence: true
        }),
        telefono: (0, _validators.validatePresence)({
          presence: true
        })
      };
    }),

    createRecordInstance() {
      const cliente = this.modelFor('cliente.record').record;
      const modelName = this.get('modelName');
      return this.store.createRecord(modelName, {
        cliente
      });
    },

    actions: {
      willTransition() {
        return true;
      }

    }
  });

  _exports.default = _default;
});