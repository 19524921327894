define("ods-app/templates/popups/multiple-option-confirm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R7zO6Bzn",
    "block": "{\"symbols\":[\"modal\",\"footer\",\"buttonAction\"],\"statements\":[[4,\"bs-modal\",null,[[\"onHidden\"],[[26,\"route-action\",[\"closeModal\"],null]]],{\"statements\":[[4,\"if\",[[22,[\"title\"]]],null,{\"statements\":[[4,\"component\",[[21,1,[\"header\"]]],null,{\"statements\":[[0,\"      \"],[6,\"h4\"],[10,\"class\",\"modal-title\"],[8],[0,\"\\n        \"],[1,[20,\"title\"],true],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[21,1,[\"body\"]]],null,{\"statements\":[[0,\"    \"],[1,[20,\"message\"],true],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[21,1,[\"footer\"]]],null,{\"statements\":[[4,\"each\",[[22,[\"buttonActions\"]]],null,{\"statements\":[[4,\"bs-button\",null,[[\"onClick\",\"type\",\"size\"],[[26,\"pipe\",[[26,\"action\",[[21,0,[]],[21,1,[\"close\"]]],null],[26,\"action\",[[21,0,[]],\"onSubmit\",[21,3,[\"methodName\"]]],null]],null],[26,\"or\",[[21,3,[\"type\"]],\"default\"],null],\"xs\"]],{\"statements\":[[0,\"        \"],[1,[21,3,[\"title\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[3]},null],[0,\"\\n\"],[4,\"if\",[[22,[\"showCancel\"]]],null,{\"statements\":[[4,\"bs-button\",null,[[\"onClick\",\"type\",\"size\"],[[26,\"action\",[[21,0,[]],[21,1,[\"close\"]]],null],\"danger\",\"xs\"]],{\"statements\":[[0,\"        Cancelar\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ods-app/templates/popups/multiple-option-confirm.hbs"
    }
  });

  _exports.default = _default;
});