define("ods-app/templates/menu/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UiehF8Qs",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",\"page-header\"],[8],[0,\"\\n  \"],[6,\"h1\"],[8],[0,\"\\n    Menús\\n    \"],[6,\"div\"],[10,\"class\",\"pull-right\"],[8],[0,\"\\n      \"],[1,[26,\"buttons/create-record\",[\"menu.record\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[6,\"div\"],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n    \"],[1,[26,\"tables/paginated-table\",null,[[\"model\",\"columns\",\"modelName\",\"modelClassObject\"],[[22,[\"model\"]],[22,[\"columns\"]],[22,[\"modelName\"]],[22,[\"modelClass\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ods-app/templates/menu/index.hbs"
    }
  });

  _exports.default = _default;
});