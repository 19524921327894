define("ods-app/routes/planilla-cargo/record/factorh-tipo-descuento", ["exports", "ods-app/mixins/permission-validator"], function (_exports, _permissionValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_permissionValidator.default, {
    ajaxService: Ember.inject.service(),
    toast: Ember.inject.service(),
    loader: Ember.inject.service(),
    routePermission: 'EDIT_CARGO_FACTORH_TIPO_DESCUENTO',
    indexRoute: 'planilla-cargo.record',

    model() {
      this.loader.setIsLoading();
      return this.ajaxService.request('/factorh-tipos-descuentos').then(({
        data: factorhTiposDescuentos
      }) => ({
        factorhTiposDescuentos
      })).catch(error => {
        this.toast.error('Ocurrió un error al obtener la lista de tipos de descuentos.');
        this.transitionTo(this.indexRoute);
        throw error;
      }).finally(() => this.loader.setNotLoading());
    },

    setupController(controller, model) {
      this._super(controller, model);

      let changeset = this.controllerFor(this.indexRoute).get('changeset');
      controller.set('factorhTipoDescuento', null); // Set the controller variable to show the current 'factorhTipoDescuento'.

      if (changeset.get('factorhTipoDescuentoId')) {
        const factorhTipoDescuento = model.factorhTiposDescuentos.findBy('id', changeset.get('factorhTipoDescuentoId'));

        if (factorhTipoDescuento) {
          controller.set('factorhTipoDescuento', factorhTipoDescuento);
        } else {
          this.toast.error('El tipo descuento asignado ya no existe en FactoRH.');
        }
      }

      controller.setProperties({
        changeset,
        record: this.modelFor(this.indexRoute).record
      });
    }

  });

  _exports.default = _default;
});