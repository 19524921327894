define("ods-app/templates/components/buttons/create-record", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q/I2DjLH",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"link-to\",[[22,[\"route\"]],\"new\"],[[\"classNames\"],[\"btn btn-success btn-xs\"]],{\"statements\":[[4,\"if\",[[23,1]],null,{\"statements\":[[0,\"    \"],[13,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,\"i\"],[10,\"class\",\"fa fa-plus-circle\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n    Crear\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ods-app/templates/components/buttons/create-record.hbs"
    }
  });

  _exports.default = _default;
});