define("ods-app/routes/cliente/record/datos-generales", ["exports", "servir-ember-utilities/mixins/route/is-tab-subroute", "servir-ember-utilities/mixins/route/has-dependent-fields"], function (_exports, _isTabSubroute, _hasDependentFields) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_isTabSubroute.default, _hasDependentFields.default, {
    dependentRelatedAttributes: Ember.computed(function () {
      return [{
        dependentAttributePath: 'changeset.departamento',
        dependentAttributeModelName: 'departamento',
        dependedUponAttributeName: 'pais',
        dependentAttributeDependentPath: 'pais'
      }, {
        dependentAttributePath: 'changeset.municipio',
        dependentAttributeModelName: 'municipio',
        dependedUponAttributeName: 'changeset.departamento',
        dependentAttributeDependentPath: 'departamento'
      }];
    }),
    actions: {
      updatePais(changeset, propertyName, selectedValue = null) {
        changeset.set(propertyName, selectedValue);
        this.refreshOptionsForDependentOnSelects('pais', selectedValue);
      },

      updateDepartamento(changeset, propertyName, selectedValue = null) {
        changeset.set(propertyName, selectedValue);
        this.refreshOptionsForDependentOnSelects('changeset.departamento', selectedValue);
      },

      updateMunicipio(changeset, propertyName, selectedValue = null) {
        changeset.set(propertyName, selectedValue);
      }

    }
  });

  _exports.default = _default;
});