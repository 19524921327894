define("ods-app/routes/cliente/record/perfiles-puestos/record/descriptor", ["exports", "servir-ember-utilities/mixins/route/is-tab-subroute"], function (_exports, _isTabSubroute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_isTabSubroute.default, {
    model() {
      let perfilPuesto = this.modelFor('cliente.record.perfiles-puestos.record').record;
      return Ember.RSVP.hash({
        rangosSalariales: this.store.query('rango-salarial', {
          filter: {
            'cliente.id': perfilPuesto.get('cliente.id')
          },
          include: 'moneda'
        })
      });
    }

  });

  _exports.default = _default;
});