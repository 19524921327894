define("ods-app/routes/requerimiento/record/terna/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "ember-changeset-validations/validators", "moment", "ods-app/helpers/is-empresa"], function (_exports, _abstractModuleRecord, _validators, _moment, _isEmpresa) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    modelName: 'terna',
    routeRecordIdentifierDynamicSegment: 'terna_id',
    entrevista: false,
    datesCalculator: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    validations: Ember.computed(function () {
      return {
        situacion: (0, _validators.validatePresence)({
          presence: true
        }),
        fechaVencimientoEnvio: (0, _validators.validatePresence)({
          presence: true
        }),
        fechaConfirmacion: (0, _validators.validatePresence)({
          presence: true
        })
      };
    }),

    setupController(controller) {
      this._super(...arguments);

      let requerimiento = controller.get('record.requerimiento'); // Se hace el format de esta manera para que se pueda seleccionar el día de solicitud

      let fechaSolicitud = (0, _moment.default)(requerimiento.get('fechaSolicitud')).format('MM/DD/YYYY');
      let currentDate = new Date();
      currentDate = (0, _moment.default)(currentDate).format('MM/DD/YYYY');

      if (currentDate >= fechaSolicitud) {
        currentDate = fechaSolicitud;
      }

      controller.set('currentDate', currentDate);
    },

    createRecordInstance()
    /*params, transition*/
    {
      const modelName = this.get('modelName');
      const requerimiento = this.modelFor('requerimiento.record').record;
      let cantidadDias = requerimiento.get('diasCompletarRequerimiento');

      if ((0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'OPTIMUM') || !cantidadDias) {
        cantidadDias = 6;
      }

      let fechaVencimientoEnvio = this.get('datesCalculator').getFechaLuegoDeDiasHabiles((0, _moment.default)(new Date()), cantidadDias);
      let fechaVencimientoConfirmacion = this.get('datesCalculator').getFechaLuegoDeDiasHabiles((0, _moment.default)(new Date()), cantidadDias + 2);
      return this.store.createRecord(modelName, {
        requerimiento,
        fechaVencimientoEnvio: fechaVencimientoEnvio.toDate(),
        fechaConfirmacion: fechaVencimientoConfirmacion.toDate(),
        activo: true,
        correlativo: requerimiento.get('ternas.length') + 1
      });
    },

    actions: {
      willTransition(transition) {
        if (this.get('pendingTransition.targetName') == transition.targetName) {
          this.set('pendingTransition', null);
          return false;
        }

        transition.abort();
        const changeset = this.controller.changeset;
        const record = this.controller.record;
        changeset.validate().then(() => {
          if (!changeset.get('isValid')) {
            this.toast.error('Hay errores en el formulario.');
            return;
          }

          this.set('pendingTransition', transition);
          return changeset.save().then(() => {
            if (record.get('isNew')) {
              this.toast.success('Terna creada correctamente');
            } else {
              this.toast.success('Terna actualizada correctamente');
            }

            transition.retry();
          }).catch(() => {
            this.toast.error('No se pudo guardar la terna.');
          });
        });
        return false;
      }

    }
  });

  _exports.default = _default;
});