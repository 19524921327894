define("ods-app/templates/role/record/permisos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UNO5md0p",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[6,\"div\"],[10,\"class\",\"col-sm-6\"],[8],[0,\"\\n    \"],[1,[26,\"ember-jstree\",null,[[\"selectedNodes\",\"data\",\"themes\",\"plugins\",\"checkboxOptions\",\"eventDidChange\"],[[22,[\"jstreeSelectedNodes\"]],[22,[\"treeData\"]],[22,[\"themes\"]],[22,[\"plugins\"]],[22,[\"checkboxOptions\"]],[26,\"action\",[[21,0,[]],\"jstreeDidChange\"],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ods-app/templates/role/record/permisos.hbs"
    }
  });

  _exports.default = _default;
});