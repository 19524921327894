define("ods-app/routes/proyecto/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "servir-ember-utilities/mixins/route/has-tabs", "servir-ember-utilities/validators/custom-presence", "ember-changeset-validations/validators", "servir-ember-utilities/validators/is-codigo", "moment", "ods-app/mixins/permission-validator", "ods-app/helpers/is-empresa"], function (_exports, _abstractModuleRecord, _hasTabs, _customPresence, _validators, _isCodigo, _moment, _permissionValidator, _isEmpresa) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend(_hasTabs.default, _permissionValidator.default, {
    modelName: 'proyecto',
    indexRoute: 'proyecto',
    defaultTabSubRoute: 'servicio',
    routePermission: 'VIEW_PROJECTS',
    datesCalculator: Ember.inject.service(),
    breadcrumbsRoute: Ember.computed('recordId', function () {
      let record = this.controller.get('record');
      let indexRoute = {
        name: 'Proyectos existentes',
        route: 'proyectos-existentes'
      };

      if (record.get('estatus') == this.constants.PROJECT_STATUS_COMPLETED) {
        indexRoute = {
          name: 'Proyecto histórico',
          route: 'proyecto-historico'
        };
      }

      return [indexRoute, {
        name: 'Editar',
        route: `proyecto.record.${this.defaultTabSubRoute}`,
        queryParam: this.recordId
      }];
    }),
    validations: Ember.computed(function () {
      return {
        codigo: [(0, _customPresence.default)({
          presence: true
        }), (0, _isCodigo.default)()],
        servicioAdquirido: (0, _customPresence.default)({
          presence: true
        }),
        fechaSolicitud: (0, _customPresence.default)({
          presence: true
        }),
        fechaAlta: (0, _customPresence.default)({
          presence: true
        }),
        montoFacturar: [(0, _validators.validateNumber)({
          gte: 0
        })],
        cantidadRequerimientos: [(0, _validators.validateNumber)({
          gte: 0
        })],
        porcentajeFee: [(0, _validators.validateNumber)({
          gte: 0,
          lte: 100
        })],
        porcentajeAnticipo: [(0, _validators.validateNumber)({
          gte: 0,
          lte: 100
        })],
        porcentajeIsr: [(0, _validators.validateNumber)({
          gte: 0,
          lte: 100
        })],
        porcentajeIva: [(0, _validators.validateNumber)({
          gte: 0,
          lte: 100
        })],
        estatus: (0, _customPresence.default)({
          presence: true
        })
      };
    }),
    relatedModelsToQuery: Ember.computed(function () {
      let relatedModels = [{
        name: 'servicios',
        modelName: 'servicio'
      }];

      if (!this.get('isNew') && this.get('recordId')) {
        relatedModels.push({
          name: 'facturas',
          modelName: 'factura',
          queryParams: {
            filter: {
              proyecto: this.get('recordId')
            }
          }
        } // {
        //   name: 'requerimientos',
        //   modelName: 'requerimiento',
        //   queryParams: {
        //     filter: {
        //       proyecto: this.get('recordId')
        //     },
        //     include: 'terna'
        //   }
        // }
        );
      }

      return relatedModels;
    }),
    preSelectedCliente: null,
    preSelectedContacto: null,
    preSelectedDatoFacturacion: null,
    preselectedServicio: null,
    estatus: null,

    beforeModel(transition) {
      this._super(transition);

      let promises = {};

      if (transition.queryParams.cliente) {
        promises['preSelectedCliente'] = this.store.findRecord('cliente', transition.queryParams.cliente);
      }

      if (transition.queryParams.clienteContacto) {
        promises['preSelectedContacto'] = this.store.findRecord('cliente-contacto', transition.queryParams.clienteContacto);
      }

      if (transition.queryParams.clienteDatoFacturacion) {
        promises['preSelectedDatoFacturacion'] = this.store.findRecord('cliente-dato-facturacion', transition.queryParams.clienteDatoFacturacion);
      }

      if (transition.queryParams.servicioId) {
        promises['preselectedServicio'] = this.store.findRecord('servicio', transition.queryParams.servicioId);
      }

      if (transition.queryParams.estatus) {
        this.set('estatus', transition.queryParams.estatus);
      }

      return Ember.RSVP.hash(promises).then(preSelectedData => {
        this.setProperties(preSelectedData);
      });
    },

    model(params, transition) {
      if (params.id === 'new') {
        let errorMsg = null;

        if (!transition.queryParams.cliente) {
          errorMsg = 'Primero debe seleccionar un cliente para poder crear un proyecto.';
        }

        if ((0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'OPTIMUM')) {
          if (!errorMsg && !transition.queryParams.clienteContacto) {
            errorMsg = 'Primero debe seleccionar un contacto para poder crear un proyecto.';
          }

          if (!errorMsg && !transition.queryParams.clienteDatoFacturacion) {
            errorMsg = 'Primero debe seleccionar un dato de facturación para poder crear un proyecto.';
          }
        }

        if (errorMsg) {
          this.toast.error(errorMsg);
          transition.abort();
          return this.transitionTo(this.get('indexRoute'));
        }
      }

      return this._super(...arguments);
    },

    createRecordInstance() {
      const modelName = this.get('modelName'); // Asignar la fecha de alta del proyecto como 8 días hábiles a partir de la
      // fecha de hoy

      let fechaAlta = this.get('datesCalculator').getFechaLuegoDeDiasHabiles((0, _moment.default)(this.fechaSolicitud), 8); // if (this.preselectedServicio.usaFechaRenovacion) {
      // Fecha de renovacion
      // let renovacion = moment(this.fechaSolicitud).endOf('month');
      // }

      return this.store.createRecord(modelName, {
        cliente: this.preSelectedCliente,
        clienteContacto: this.preSelectedContacto,
        clienteDatoFacturacion: this.preSelectedDatoFacturacion,
        servicioAdquirido: this.preselectedServicio,
        fechaAlta: fechaAlta.toDate(),
        // fechaRenovacion: renovacion.toDate(),
        montoFacturar: 0,
        porcentajeAnticipo: 0,
        porcentajeFee: 0,
        cantidadFee: 0,
        cantidadRequerimientos: 0,
        usaIsr: true,
        porcentajeIsr: 25,
        usaIva: true,
        porcentajeIva: 12,
        incluirCargos: true,
        incluirPrestaciones: true,
        estatus: this.constants.PROJECT_STATUS.get('firstObject.key')
      });
    },

    actions: {
      // mandarFacturar() {
      //   let changeset = this.controller.get('changeset');
      //   changeset.set('estatus', this.constants.PROJECT_STATUS_BILLING);
      // },
      completarProyecto() {
        let changeset = this.controller.get('changeset');
        this.send('openModal', 'popups/simple-confirm', {
          title: `¿Está seguro que desea completar el proyecto?`,
          message: null,
          onSubmit: () => {
            changeset.set('estatus', this.constants.PROJECT_STATUS_COMPLETED);
          }
        });
      },

      arrancarReclutamiento() {
        const proyectoId = this.modelFor('proyecto.record').record.get('id');
        this.transitionTo('requerimiento.record', 'new', {
          queryParams: {
            proyectoId
          }
        });
      }

    }
  });

  _exports.default = _default;
});