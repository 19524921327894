define("ods-app/routes/planilla-cargo/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "ember-changeset-validations/validators", "servir-ember-utilities/validators/is-codigo", "ods-app/mixins/permission-validator"], function (_exports, _abstractModuleRecord, _validators, _isCodigo, _permissionValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend(_permissionValidator.default, {
    modelName: 'planilla-cargo',
    indexRoute: 'planilla-cargo',
    routePermission: 'VIEW_PAYROLL_COLLECTION',
    validations: Ember.computed(function () {
      return {
        codigo: [(0, _validators.validatePresence)({
          presence: true
        }), (0, _isCodigo.default)()],
        nombre: (0, _validators.validatePresence)({
          presence: true
        }),
        montoDefault: [(0, _validators.validatePresence)({
          presence: true
        }), (0, _validators.validateNumber)({
          gte: 0
        })]
      };
    }),
    actions: {
      willTransition() {
        return true;
      }

    }
  });

  _exports.default = _default;
});