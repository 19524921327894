define("ods-app/controllers/proyecto/record/servicio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    toast: Ember.inject.service(),
    factorhDepartamentoActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editFactorhDepartamento.bind(this)
      }];
    }),
    sirvowsCentroCostoActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editSirvowsCentroCosto.bind(this)
      }];
    }),
    actions: {
      // Según la última indicación recibida no debemos actualizar la cantidad de fee
      //
      // updateMonto(changeset, propertyName, value = null) {
      //   changeset.set(propertyName, value);
      //   const montoFacturar = parseFloat(changeset.get('montoFacturar'));
      //   const porcentajeAnticipo = parseFloat(changeset.get('porcentajeAnticipo'));
      //   const porcentajeFee = parseFloat(changeset.get('porcentajeFee'));
      //   changeset.set('montoAnticipo', (montoFacturar * porcentajeAnticipo)/100);
      //   changeset.set('cantidadFee', (montoFacturar * porcentajeFee)/100);
      // },
      // updateCantidad(changeset, propertyName, value = null) {
      //   changeset.set(propertyName, value);
      //   const montoFacturar = parseFloat(changeset.get('montoFacturar'));
      //   const porcentajeFee = parseFloat(changeset.get('porcentajeFee'));
      //   changeset.set('cantidadFee', (montoFacturar * porcentajeFee)/100);
      // },
      //
      updateMontoAnticipo(changeset, propertyName, value = null) {
        changeset.set(propertyName, value);
        const montoFacturar = parseFloat(changeset.get('montoFacturar'));
        const porcentajeAnticipo = parseFloat(changeset.get('porcentajeAnticipo'));
        changeset.set('montoAnticipo', montoFacturar * porcentajeAnticipo / 100);
      },

      editFactorhDepartamento() {
        if (this.record.get('isNew')) {
          this.toast.error('Guarde el registro para poder realizar esta acción.');
          return;
        }

        return this.changeset.validate().then(() => {
          if (!this.changeset.get('isValid')) {
            this.toast.error('Corrija los errores para poder realizar esta acción.');
            return;
          }

          this.transitionToRoute('proyecto.record.servicio.factorh-departamento');
        });
      },

      editSirvowsCentroCosto() {
        return this.changeset.validate().then(() => {
          if (!this.changeset.get('isValid')) {
            this.toast.error('Corrija los errores para poder realizar esta acción.');
            return;
          }

          this.transitionToRoute('proyecto.record.servicio.sirvows-centro-costo');
        });
      }

    }
  });

  _exports.default = _default;
});