define("ods-app/templates/facturas/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tscb54P7",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",\"page-header\"],[8],[0,\"\\n  \"],[6,\"h1\"],[8],[0,\"\\n    Solicitudes de factura\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[6,\"div\"],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n    \"],[1,[26,\"tables/paginated-table\",null,[[\"model\",\"columns\",\"modelName\",\"modelClassObject\",\"modelSortingProperties\",\"load\"],[[22,[\"model\"]],[22,[\"columns\"]],[22,[\"modelName\"]],[22,[\"modelClass\"]],[22,[\"modelSortingProperties\"]],[22,[\"reload\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ods-app/templates/facturas/index.hbs"
    }
  });

  _exports.default = _default;
});