define("ods-app/templates/cliente/index/resumen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CiuWtWtr",
    "block": "{\"symbols\":[\"modal\",\"footer\"],\"statements\":[[4,\"bs-modal\",null,[[\"onHidden\"],[[26,\"action\",[[21,0,[]],\"discardChanges\"],null]]],{\"statements\":[[4,\"component\",[[21,1,[\"header\"]]],null,{\"statements\":[[0,\"    \"],[6,\"h3\"],[10,\"class\",\"modal-title\"],[8],[0,\"\\n      Resumen - \"],[1,[22,[\"changeset\",\"nombre\"]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[21,1,[\"body\"]]],null,{\"statements\":[[0,\"    \"],[6,\"h4\"],[8],[0,\"\\n      Contactos\\n    \"],[9],[0,\"\\n\\n    \"],[1,[26,\"tables/paginated-table\",null,[[\"columns\",\"modelFilters\",\"modelName\",\"modelClassObject\"],[[22,[\"columnsContactos\"]],[22,[\"modelFilters\"]],\"cliente-contacto\",[22,[\"modelClassContacto\"]]]]],false],[0,\"\\n\\n    \"],[6,\"br\"],[8],[9],[0,\"\\n\\n    \"],[6,\"h4\"],[8],[0,\"\\n      Datos facturación\\n    \"],[9],[0,\"\\n\\n    \"],[1,[26,\"tables/paginated-table\",null,[[\"columns\",\"modelFilters\",\"modelName\",\"modelClassObject\"],[[22,[\"columnsDatoFacturacion\"]],[22,[\"modelFilters\"]],\"cliente-dato-facturacion\",[22,[\"modelClassDatoFacturacion\"]]]]],false],[0,\"\\n\\n    \"],[6,\"br\"],[8],[9],[0,\"\\n\\n    \"],[6,\"h4\"],[8],[0,\"\\n      Referencias\\n    \"],[9],[0,\"\\n\\n    \"],[1,[26,\"tables/paginated-table\",null,[[\"columns\",\"modelFilters\",\"modelName\",\"modelClassObject\"],[[22,[\"columnsReferencia\"]],[22,[\"modelFilters\"]],\"cliente-referencia\",[22,[\"modelClassReferencia\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[21,1,[\"footer\"]]],null,{\"statements\":[[4,\"bs-button\",null,[[\"onClick\",\"size\"],[[26,\"action\",[[21,0,[]],[21,1,[\"close\"]]],null],\"xs\"]],{\"statements\":[[0,\"      Salir\\n\"]],\"parameters\":[]},null]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ods-app/templates/cliente/index/resumen.hbs"
    }
  });

  _exports.default = _default;
});