define("ods-app/helpers/has-config", ["exports", "ods-app/utils/app-utils"], function (_exports, _appUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hasConfig = hasConfig;
  _exports.default = void 0;
  const {
    isTruthhy
  } = _appUtils.default;

  function hasConfig(configs, configCode, configValue) {
    let config = configs.findBy('codigo', configCode);

    if (!config) {
      return false;
    }

    if (!config.get('activo')) {
      return false;
    }

    if (configValue === undefined) {
      return isTruthhy(config.get('valor'));
    } else {
      return configValue == config.get('valor');
    }
  }

  var _default = Ember.Helper.extend({
    currentUser: Ember.inject.service(),

    compute([configCode, configValue]) {
      return hasConfig(this.currentUser.get('configs'), configCode, configValue);
    }

  });

  _exports.default = _default;
});