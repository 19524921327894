define("ods-app/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "U3xoV94u",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[22,[\"loader\",\"isLoading\"]]],null,{\"statements\":[[0,\"  \"],[1,[26,\"ember-cli-spinner\",null,[[\"isShow\"],[true]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[22,[\"isAuthenticated\"]]],null,{\"statements\":[[0,\"  \"],[1,[20,\"app-navigation\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[6,\"div\"],[10,\"class\",\"main-container\"],[8],[0,\"\\n\"],[4,\"if\",[[22,[\"isAuthenticated\"]]],null,{\"statements\":[[0,\"    \"],[1,[20,\"app-sidebar\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[6,\"div\"],[10,\"class\",\"main-content\"],[8],[0,\"\\n\"],[4,\"if\",[[22,[\"isAuthenticated\"]]],null,{\"statements\":[[0,\"      \"],[1,[26,\"app-breadcrumbs\",[[22,[\"breadcrumbs\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[22,[\"isAuthenticated\"]]],null,{\"statements\":[[0,\"      \"],[6,\"div\"],[10,\"class\",\"page-content\"],[8],[0,\"\\n\"],[4,\"if\",[[26,\"not\",[[22,[\"user\",\"hasLastVersion\"]]],null]],null,{\"statements\":[[0,\"          \"],[1,[20,\"alerts/new-app-version\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n        \"],[1,[20,\"outlet\"],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[1,[26,\"outlet\",[\"login\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[26,\"outlet\",[\"modal\"],null],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ods-app/templates/application.hbs"
    }
  });

  _exports.default = _default;
});