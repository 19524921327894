define("ods-app/routes/cliente/record/contratos", ["exports", "servir-ember-utilities/mixins/route/is-tab-subroute"], function (_exports, _isTabSubroute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { set as emberSet } from '@ember/object';
  var _default = Ember.Route.extend(_isTabSubroute.default, {
    model() {
      const cliente = this.modelFor('cliente.record').record;
      const clienteId = cliente.get('id');
      return Ember.RSVP.hash({
        proyectos: this.store.query('proyecto', {
          filter: {
            'servicioAdquirido.periodicidad': this.constants.PERIODICITY_MENSUAL,
            'cliente.id': clienteId
          }
        })
      }); // TODO: Averiguar porque la llamada al super no devuelve una promesa.
      // return RSVP.resolve(this._super(...arguments))
      //   .then((parentModel) => {
      //     return parentModel.record
      //       .get('proyectos')
      //       .then((proyectos) => {
      //         emberSet(parentModel, 'proyectos', proyectos);
      //         return parentModel;
      //       });
      //   });
    }

  });

  _exports.default = _default;
});