define("ods-app/components/menu-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'li',
    permission: null,
    menu: Ember.computed.readOnly('permission.menu'),
    classNameBindings: ['hover'],
    hover: Ember.computed.bool('permission.children.length')
  });

  _exports.default = _default;
});