define("ods-app/controllers/aplicacion/record/llamada", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "ods-app/models/llamada"], function (_exports, _abstractModuleIndex, _llamada) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    editRoute: 'aplicacion.record.llamada.record',
    modelClass: _llamada.default,
    reload: true,
    modelFilters: Ember.computed('record', function () {
      return [{
        selectedFilter: {
          filterName: 'aplicacion'
        },
        value: this.get('record.id') || -1
      }];
    }),
    columns: Ember.computed(function () {
      return Ember.A([{
        label: 'Fecha',
        valuePath: 'fechaFormatted',
        sortable: false,
        width: '100px'
      }, {
        label: 'Teléfono',
        valuePath: 'aplicacion.candidato.telefono',
        sortable: false,
        width: '100px'
      }, {
        label: 'Duración',
        valuePath: 'duracion',
        sortable: false,
        width: '100px'
      }, {
        label: 'Observaciones',
        valuePath: 'observaciones',
        sortable: false,
        width: '100px'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        width: '100px',
        sortable: false,
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.edit.bind(this)
          },
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.delete.bind(this)
          }
        }
      }]);
    }),

    reloadModel() {
      this.set('reload', true);
    }

  });

  _exports.default = _default;
});