define("ods-app/models/cliente-contacto", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    nombre: attr('string'),
    telefono: attr('string'),
    puesto: attr('string'),
    correo: attr('string'),
    cliente: belongsTo('cliente'),
    tipo: attr('string'),
    tipoToString: Ember.computed('tipo', function () {
      const status = this.constants.CLIENT_CONTACT_TYPE.findBy('key', this.tipo);
      return status ? status.name : this.status;
    })
  });

  _exports.default = _default;
});