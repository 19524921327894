define("ods-app/mirage/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    // These comments are here to help you get started. Feel free to delete them.

    /*
      Config (with defaults).
       Note: these only affect routes defined *after* them!
    */
    // this.urlPrefix = '';    // make this `http://localhost:8080`, for example, if your API is on a different server
    // this.namespace = '';    // make this `/api`, for example, if your API is namespaced
    // this.timing = 400;      // delay for each request, automatically set to 0 during testing

    /*
      Shorthand cheatsheet:
       this.get('/posts');
      this.post('/posts');
      this.get('/posts/:id');
      this.put('/posts/:id'); // or this.patch
      this.del('/posts/:id');
       https://www.ember-cli-mirage.com/docs/route-handlers/shorthands
    */
    this.get('/proyectos', (schema, request) => {
      const data = schema.proyectos.all();
      const reqPageNumber = request.queryParams['page_number'];
      const reqPageSize = request.queryParams['page_size'];
      const pageEnabled = reqPageNumber !== undefined && reqPageSize !== undefined;

      if (!pageEnabled) {
        return data;
      }

      const pageNumber = parseInt(reqPageNumber);
      const pageSize = parseInt(reqPageSize);
      const offset = (pageNumber - 1) * pageSize;
      const pagedData = data.slice(offset, offset + pageSize);
      request.queryParams.totalItems = data.length;
      return pagedData;
    });
    this.get('/proyectos/:id');
    this.post('/proyectos');
    this.del('/proyectos/:id');
    this.get('/countries');
    this.get('/countries/:id');
    this.get('/states', (schema, request) => {
      let result = schema.states.all();
      let country = request.queryParams['filter[country]'];

      if (country !== undefined) {
        return result.filter(i => i.countryId === parseInt(country));
      }

      return result;
    });
    this.get('/states/:id');
    this.get('/cities', (schema, request) => {
      let result = schema.cities.all();
      let state = request.queryParams['filter[state]'];

      if (state !== undefined) {
        return result.filter(i => i.stateId === parseInt(state));
      }

      return result;
    });
    this.get('/cities/:id');
    this.get('/languages');
    this.get('/languages/:id');
  }
});