define("ods-app/routes/requerimiento/record/ingreso", ["exports", "servir-ember-utilities/mixins/route/is-tab-subroute", "ods-app/mixins/permission-validator"], function (_exports, _isTabSubroute, _permissionValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_isTabSubroute.default, _permissionValidator.default, {
    routePermission: 'VIEW_RQ_TAB_ENTRY',
    noPermissionTransitionRoute: 'requerimiento',
    cantidadAplicaciones: 0,
    toast: Ember.inject.service(),

    model() {
      let parentData = this._super(...arguments);

      return Ember.RSVP.hash({
        aplicaciones: this.store.query('aplicacion', {
          filter: {
            'requerimiento.id': parentData.record.get('id')
          }
        })
      }).then(routeData => Ember.merge(parentData, routeData));
    },

    setupController(controller
    /*, model*/
    ) {
      this._super(...arguments);

      let requerimiento = controller.get('record');
      let ternaActiva = requerimiento.get('ternaActiva');
      let cantidadSeleccionadas = 0;
      let currentDate = new Date(); // Dummy function to set hours

      currentDate.setHours(0, 0, 0, 0);

      if (ternaActiva) {
        let aplicacionesTerna = ternaActiva.get('aplicaciones');
        aplicacionesTerna.forEach(aplicacion => {
          if (aplicacion.seleccionado) {
            cantidadSeleccionadas += 1;
          }
        });
      }

      if (cantidadSeleccionadas > requerimiento.cantidadPlazas) {
        this.toast.error('La cantidad de aplicaciones seleccionadas es mayor a la cantidad de plazas requerida para este requerimiento.');
      }

      controller.set('cantidadAplicaciones', cantidadSeleccionadas);
      controller.set('currentDate', currentDate);
    }

  });

  _exports.default = _default;
});