define("ods-app/templates/requerimiento/record/informacion/reasignar-proyecto", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tbKsA/4S",
    "block": "{\"symbols\":[\"modal\",\"footer\",\"f\",\"proyecto\"],\"statements\":[[4,\"bs-modal\",null,[[\"onHidden\"],[[26,\"action\",[[21,0,[]],\"discardChanges\"],null]]],{\"statements\":[[4,\"component\",[[21,1,[\"header\"]]],null,{\"statements\":[[0,\"    \"],[6,\"h4\"],[10,\"class\",\"modal-title\"],[8],[0,\"\\n      Asignar puesto de FactoRH\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[21,1,[\"body\"]]],null,{\"statements\":[[4,\"extended-form-for\",[[21,0,[]]],null,{\"statements\":[[4,\"component\",[[21,3,[\"ace-power-select\"]],\"nuevoProyecto\",\"Proyecto:\"],[[\"selected\",\"options\",\"searchField\",\"placeholder\",\"oninput\",\"onchange\"],[[22,[\"nuevoProyecto\"]],[22,[\"availableProjects\"]],\"nombreProyecto\",\"Escribe aquí...\",[26,\"action\",[[21,0,[]],\"searchProjects\"],null],[26,\"queue\",[[26,\"action\",[[21,0,[]],[26,\"mut\",[[22,[\"nuevoProyecto\"]]],null]],null],[26,\"action\",[[21,0,[]],\"onChange\"],null]],null]]],{\"statements\":[[0,\"        \"],[1,[21,4,[\"nombreProyecto\"]],false],[0,\"\\n\"]],\"parameters\":[4]},null],[0,\"\\n      \"],[1,[26,\"component\",[[21,3,[\"ace-text-field\"]],\"nuevoProyecto.factorhDepartamentoStr\",\"Departamento de FactoRH:\"],[[\"placeholder\",\"disabled\"],[\"Departamento de FactoRH\",true]]],false],[0,\"\\n\"]],\"parameters\":[3]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[21,1,[\"footer\"]]],null,{\"statements\":[[0,\"    \"],[1,[26,\"forms/controls/ace-async-save-button\",null,[[\"onClick\"],[[26,\"action\",[[21,0,[]],\"submit\"],null]]]],false],[0,\"\\n\\n\"],[4,\"bs-button\",null,[[\"size\",\"onClick\"],[\"xs\",[26,\"action\",[[21,0,[]],[21,1,[\"close\"]]],null]]],{\"statements\":[[0,\"      Cancelar\\n\"]],\"parameters\":[]},null]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ods-app/templates/requerimiento/record/informacion/reasignar-proyecto.hbs"
    }
  });

  _exports.default = _default;
});