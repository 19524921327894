define("ods-app/templates/requerimiento/record/ingreso/asociar-proyecto", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dAXI2JO/",
    "block": "{\"symbols\":[\"modal\",\"footer\",\"f\",\"proyecto\"],\"statements\":[[4,\"bs-modal\",null,[[\"onHidden\"],[[26,\"action\",[[21,0,[]],\"discardChanges\"],null]]],{\"statements\":[[4,\"component\",[[21,1,[\"header\"]]],null,{\"statements\":[[0,\"    \"],[6,\"h4\"],[10,\"class\",\"modal-title\"],[8],[0,\"\\n      Asociar candidato a un proyecto\\n    \"],[9],[0,\"\\n    \"],[6,\"h6\"],[8],[0,\"\\n      Esto asociará el candidato seleccionado a la planilla del proyecto seleccionado\\n      y creará un empleado y una contratación en FactoRH.\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[21,1,[\"body\"]]],null,{\"statements\":[[4,\"extended-form-for\",[[21,0,[]]],null,{\"statements\":[[4,\"component\",[[21,3,[\"ace-power-select\"]],\"proyecto\",\"Proyecto:\"],[[\"selected\",\"options\",\"searchField\",\"placeholder\",\"onchange\"],[[22,[\"proyecto\"]],[22,[\"model\",\"proyectos\"]],\"nombreProyecto\",\"Escribe aquí...\",[26,\"action\",[[21,0,[]],[26,\"mut\",[[22,[\"proyecto\"]]],null]],null]]],{\"statements\":[[0,\"        \"],[1,[21,4,[\"nombreProyecto\"]],false],[0,\"\\n\"]],\"parameters\":[4]},null]],\"parameters\":[3]},null],[0,\"    \"],[6,\"br\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[21,1,[\"footer\"]]],null,{\"statements\":[[4,\"bs-button\",null,[[\"onClick\",\"type\",\"size\"],[[26,\"action\",[[21,0,[]],\"asociarAPlanilla\"],null],\"success\",\"xs\"]],{\"statements\":[[0,\"      Asignar\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"bs-button\",null,[[\"onClick\",\"type\",\"size\"],[[26,\"action\",[[21,0,[]],[21,1,[\"close\"]]],null],\"danger\",\"xs\"]],{\"statements\":[[0,\"      Cancelar\\n\"]],\"parameters\":[]},null]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ods-app/templates/requerimiento/record/ingreso/asociar-proyecto.hbs"
    }
  });

  _exports.default = _default;
});