define("ods-app/routes/cliente/record/contactos/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "ember-changeset-validations/validators", "servir-ember-utilities/validators/email"], function (_exports, _abstractModuleRecord, _validators, _email) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    modelName: 'cliente-contacto',
    indexRoute: 'cliente.record.contactos',
    routeRecordIdentifierDynamicSegment: 'contacto_id',
    validations: Ember.computed(function () {
      return {
        nombre: (0, _validators.validatePresence)({
          presence: true
        }),
        telefono: (0, _validators.validatePresence)({
          presence: true
        }),
        puesto: (0, _validators.validatePresence)({
          presence: true
        }),
        correo: [(0, _validators.validatePresence)({
          presence: true
        }), (0, _email.default)()],
        tipo: (0, _validators.validatePresence)({
          presence: true
        })
      };
    }),

    createRecordInstance() {
      const modelName = this.get('modelName');
      const cliente = this.modelFor('cliente.record').record;
      return this.store.createRecord(modelName, {
        cliente
      });
    },

    actions: {
      willTransition() {
        return true;
      }

    }
  });

  _exports.default = _default;
});