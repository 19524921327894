define("ods-app/helpers/value-config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.valueConfig = valueConfig;
  _exports.default = void 0;

  function valueConfig(configs, configCode) {
    let config = configs.findBy('codigo', configCode);

    if (!config) {
      return false;
    }

    if (!config.get('activo')) {
      return false;
    }

    return config.get('valor');
  }

  var _default = Ember.Helper.extend({
    currentUser: Ember.inject.service(),

    compute([configCode]) {
      return valueConfig(this.currentUser.get('configs'), configCode);
    }

  });

  _exports.default = _default;
});