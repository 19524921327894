define("ods-app/validations/changeset/usuario", ["exports", "ember-changeset-validations/validators", "servir-ember-utilities/validators/custom-presence", "servir-ember-utilities/validators/is-codigo", "servir-ember-utilities/validators/email"], function (_exports, _validators, _customPresence, _isCodigo, _email) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (isNew = false) => {
    let validations = {
      codigo: [(0, _customPresence.default)({
        presence: true
      }), (0, _isCodigo.default)()],
      nombres: (0, _validators.validatePresence)({
        presence: true
      }),
      apellidos: (0, _validators.validatePresence)({
        presence: true
      }),
      username: (0, _validators.validatePresence)({
        presence: true
      }),
      email: [(0, _validators.validatePresence)({
        presence: true
      }), (0, _email.default)()],
      role: (0, _validators.validatePresence)({
        presence: true
      }),
      plainPassword: (0, _validators.validatePresence)({
        presence: true
      }),
      confirmPassword: (0, _validators.validateConfirmation)({
        on: 'plainPassword',
        message: 'No coincide con su contraseña'
      })
    };

    if (!isNew) {
      delete validations.plainPassword;
      delete validations.confirmPassword;
    }

    return validations;
  };

  _exports.default = _default;
});