define("ods-app/controllers/proyecto/record/servicio/sirvows-centro-costo", ["exports", "servir-ember-utilities/controllers/abstract-module-record"], function (_exports, _abstractModuleRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    indexRoute: 'proyecto.record.servicio',
    sirvowsCentroCosto: null,
    actions: {
      onChange(sirvowsCentroCosto) {
        if (!sirvowsCentroCosto) {
          this.changeset.set('centroCosto', null);
          return;
        }

        this.changeset.set('centroCosto', sirvowsCentroCosto.codigo);
      },

      discardChanges() {
        this.changeset.rollback();
        this.record.rollbackAttributes();
        this.transitionToRoute(this.get('indexRoute'));
      }

    }
  });

  _exports.default = _default;
});