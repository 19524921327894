define("ods-app/routes/requerimiento/record/informacion-compensacion/planilla-cargo", ["exports", "servir-ember-utilities/routes/abstract-module-record", "ember-changeset-validations/validators"], function (_exports, _abstractModuleRecord, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    modelName: 'requerimiento-planilla-cargo',
    routeRecordIdentifierDynamicSegment: 'cargo_id',
    indexRoute: 'requerimiento.record.informacion-compensacion',
    validations: Ember.computed(function () {
      return {
        requerimiento: (0, _validators.validatePresence)({
          presence: true
        }),
        planillaCargo: (0, _validators.validatePresence)({
          presence: true
        }),
        monto: [(0, _validators.validatePresence)({
          presence: true
        }), (0, _validators.validateNumber)({
          gt: 0
        })]
      };
    }),
    relatedModelsToQuery: Ember.computed(function () {
      return [{
        name: 'planillaCargos',
        modelName: 'planilla-cargo'
      }];
    }),

    model() {
      return this._super(...arguments).then(modelData => {
        let requerimiento = this.modelFor('requerimiento.record').record;
        return requerimiento.get('planillaCargos').then(() => Ember.RSVP.all(requerimiento.get('planillaCargos').map(pc => pc.get('planillaCargo')))).then(() => modelData);
      });
    },

    createRecordInstance() {
      let requerimiento = this.modelFor('requerimiento.record').record;
      return this.store.createRecord(this.modelName, {
        requerimiento
      });
    },

    setupController(controller, model) {
      this._super(...arguments);

      let requerimiento = this.modelFor('requerimiento.record').record;
      let currentCargosIds = requerimiento.get('planillaCargos').mapBy('planillaCargo.id'); // Mostrar solo los que aún no se han asignado.

      controller.set('planillaCargos', model.planillaCargos.filter(c => !currentCargosIds.includes(c.id)));
    },

    actions: {
      willTransition() {
        return false;
      }

    }
  });

  _exports.default = _default;
});