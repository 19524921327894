define("ods-app/controllers/proyectos-existentes/index", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "ods-app/models/proyecto", "ods-app/helpers/is-empresa"], function (_exports, _abstractModuleIndex, _proyecto, _isEmpresa) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    user: Ember.computed.alias('currentUser.user'),
    modelName: 'proyecto',
    modelClass: _proyecto.default,
    editRoute: 'proyecto.record',
    currentUser: Ember.inject.service(),
    modelSortingProperties: Ember.computed(function () {
      return Ember.A([{
        propertyName: 'fechaSolicitud'
      }]);
    }),
    modelFilters: Ember.computed('record', function () {
      let userProyectosIds = this.user.get('proyectos').map(p => p.id);
      return [{
        selectedFilter: {
          filterName: 'id'
        },
        value: userProyectosIds
      }, {
        selectedFilter: {
          filterName: 'estatus'
        },
        value: [this.constants.PROJECT_STATUS_ACTIVE, this.constants.PROJECT_STATUS_BILLING, this.constants.PROJECT_STATUS_INVOICED]
      }, {
        selectedFilter: {
          filterName: 'servicioAdquirido.tipoServicio'
        },
        value: {
          '!exact': this.constants.SERVICE_TYPES_PAYROLL_ADMINISTRATION_FACTORH
        }
      }];
    }),
    modelFiltersAdminPlanilla: Ember.computed('record', function () {
      let userProyectosIds = this.user.get('proyectos').map(p => p.id);
      return [{
        selectedFilter: {
          filterName: 'id'
        },
        value: userProyectosIds
      }, {
        selectedFilter: {
          filterName: 'servicioAdquirido.tipoServicio'
        },
        value: this.constants.SERVICE_TYPES_PAYROLL_ADMINISTRATION_FACTORH
      }];
    }),
    columnsAdminPlanilla: Ember.computed(function () {
      let columnsAdminPlanilla = [{
        label: 'Código',
        valuePath: 'codigo',
        sortable: false,
        width: '50px',
        filtrable: true,
        filterName: 'codigo'
      }, {
        label: 'Fecha de solicitud',
        valuePath: 'fechaSolicitudFormatted',
        sortable: false,
        width: '70px'
      }, {
        label: 'Cliente',
        valuePath: (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'OPTIMUM') ? 'cliente.nombre' : 'cliente.nombreComercial',
        sortable: false,
        width: '120px',
        filtrable: true,
        filterName: (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'OPTIMUM') ? 'cliente.nombre' : 'cliente.nombreComercial'
      }];

      if ((0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'EL_TAIER') || (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'DEMO') || (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'SERVINOVA')) {
        columnsAdminPlanilla.push({
          label: 'Depto. de FactoRH',
          valuePath: 'factorhDepartamentoStr',
          sortable: false,
          width: '100px',
          filtrable: true,
          filterName: 'factorhDepartamentoStr'
        });
      }

      if ((0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'OPTIMUM')) {
        columnsAdminPlanilla.push({
          label: 'Fecha de alta',
          valuePath: 'fechaAltaFormatted',
          sortable: false,
          width: '70px'
        }, {
          label: 'Fecha de renovación',
          valuePath: 'fechaRenovacionFormatted',
          sortable: false,
          width: '70px'
        });
      }

      columnsAdminPlanilla.push({
        label: 'Estatus',
        valuePath: 'statusToString',
        sortable: false,
        width: '80px',
        filtrable: true,
        filterName: 'estatus'
      });

      if ((0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'OPTIMUM')) {
        columnsAdminPlanilla.push({
          label: 'Departamento de FactoRH',
          valuePath: 'factorhDepartamentoStr',
          sortable: false,
          width: '100px',
          filtrable: true,
          filterName: 'factorhDepartamentoStr'
        });
      }

      columnsAdminPlanilla.push({
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        sortable: false,
        width: '50px',
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.edit.bind(this)
          },
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.delete.bind(this)
          }
        }
      });
      return Ember.A(columnsAdminPlanilla);
    }),
    columns: Ember.computed(function () {
      return Ember.A([{
        label: 'Código',
        valuePath: 'codigo',
        sortable: false,
        width: '50px',
        filtrable: true,
        filterName: 'codigo'
      }, {
        label: 'Cliente',
        valuePath: 'cliente.nombre',
        sortable: false,
        width: '120px',
        filtrable: true,
        filterName: 'cliente.nombre'
      }, {
        label: 'Monto a facturar',
        valuePath: 'montoFacturarFormatted',
        sortable: false,
        width: '60px',
        align: 'right'
      }, {
        label: 'Fecha de solicitutd',
        valuePath: 'fechaSolicitudFormatted',
        sortable: false,
        width: '70px'
      }, {
        label: 'Fecha de alta',
        valuePath: 'fechaAltaFormatted',
        sortable: false,
        width: '70px'
      }, {
        label: 'Estatus',
        valuePath: 'statusToString',
        sortable: false,
        width: '80px',
        filtrable: true,
        filterName: 'estatus'
      }, {
        label: 'Servicio',
        valuePath: 'servicioAdquirido.nombre',
        sortable: false,
        width: '150px',
        filtrable: true,
        filterName: 'servicioAdquirido.nombre'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        sortable: false,
        width: '50px',
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.edit.bind(this)
          },
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.delete.bind(this)
          }
        }
      }]);
    })
  });

  _exports.default = _default;
});