define("ods-app/templates/requerimiento/record/seguimiento", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XRs+mMsF",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[4,\"extended-form-for\",[[22,[\"changeset\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"col-xs-6\"],[8],[0,\"\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-datetimepicker\"]],\"fechaSeguimientoProgramada\",\"Fecha seguimiento programada:\"],[[\"date\",\"format\",\"pickTime\",\"forceDateOutput\",\"showIcon\",\"placeholder\",\"disabled\"],[[22,[\"changeset\",\"fechaSeguimientoProgramada\"]],\"DD/MM/YYYY\",false,true,false,\"dd/mm/yyyy\",true]]],false],[0,\"\\n\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-datetimepicker\"]],\"fechaSeguimiento\",\"Fecha seguimiento:\"],[[\"date\",\"format\",\"pickTime\",\"forceDateOutput\",\"showIcon\",\"placeholder\",\"disabled\"],[[22,[\"changeset\",\"fechaSeguimiento\"]],\"DD/MM/YYYY\",false,true,false,\"dd/mm/yyyy\",[22,[\"changeset\",\"estaDeshabilitado\"]]]]],false],[0,\"\\n\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-datetimepicker\"]],\"horaContacto\",\"Hora contacto:\"],[[\"date\",\"format\",\"forceDateOutput\",\"showIcon\",\"placeholder\",\"disabled\"],[[22,[\"changeset\",\"horaContacto\"]],\"h:mm a\",true,false,\"hh:mm am/pm\",[22,[\"changeset\",\"estaDeshabilitado\"]]]]],false],[0,\"\\n\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-select-field\"]],\"responsable\",\"Responsable:\",[22,[\"usuarios\"]]],[[\"optionLabelPath\",\"optionValuePath\",\"promptText\",\"promptIsSelectable\",\"disabled\"],[\"username\",\"id\",\"Seleccionar\",true,[22,[\"changeset\",\"estaDeshabilitado\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[6,\"div\"],[10,\"class\",\"col-xs-6\"],[8],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ods-app/templates/requerimiento/record/seguimiento.hbs"
    }
  });

  _exports.default = _default;
});