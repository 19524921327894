define("ods-app/controllers/cliente/record/perfiles-puestos/record/competencias", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "ods-app/utils/common"], function (_exports, _abstractModuleIndex, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    modelName: 'competencia',
    editRoute: 'cliente.record.perfiles-puestos.record.competencias.record',
    modelFilters: Ember.computed('record', function () {
      return [{
        selectedFilter: {
          filterName: 'tipo'
        },
        value: this.constants.COMPETENCE_TYPE_TECNICA
      }];
    }),
    columns: Ember.computed(function () {
      return Ember.A([{
        label: 'Competencia',
        valuePath: 'competencia',
        sortable: false,
        width: '250px'
      }, {
        label: 'Grado de dominio',
        valuePath: 'gradoToString',
        sortable: false,
        width: '70px'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        sortable: false,
        width: '100px',
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.edit.bind(this)
          },
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.delete.bind(this)
          }
        }
      }]);
    }),
    actions: {
      delete(competencia) {
        this.send('openModal', 'popups/simple-confirm', {
          message: '¿Está seguro de eliminar el registro?',
          onSubmit: () => {
            competencia.destroyRecord().then(() => {
              if (competencia.get('tipo') === this.constants.COMPETENCE_TYPE_TECNICA) {
                Ember.set(this.model, 'competenciasTecnicas', this.model.competenciasTecnicas.without(competencia));
              } else if (competencia.get('tipo') === this.constants.COMPETENCE_TYPE_LIDERAZGO) {
                Ember.set(this.model, 'competenciasLiderazgo', this.model.competenciasLiderazgo.without(competencia));
              }

              this.toast.success('Registro eliminado correctamente.');
            }).catch(error => (0, _common.handleDeleteError)(competencia, this.toast, error));
          }
        });
      },

      nuevaCompetencia(tipo) {
        this.transitionToRoute('cliente.record.perfiles-puestos.record.competencias.record', 'new', {
          queryParams: {
            'tipo': tipo
          }
        });
      }

    }
  });

  _exports.default = _default;
});