define("ods-app/controllers/requerimiento/record/informacion/factorh-puesto", ["exports", "servir-ember-utilities/controllers/abstract-module-record"], function (_exports, _abstractModuleRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    indexRoute: 'requerimiento.record.informacion',
    factorhPuesto: null,
    actions: {
      onChange(factorhPuesto) {
        if (!factorhPuesto) {
          this.changeset.set('factorhPuestoId', null);
          this.changeset.set('factorhPuestoStr', null);
          return;
        }

        this.changeset.set('factorhPuestoId', factorhPuesto.id);
        this.changeset.set('factorhPuestoStr', factorhPuesto.descripcion);
      },

      discardChanges() {
        this.changeset.rollback();
        this.record.rollbackAttributes();
        this.transitionToRoute(this.get('indexRoute'));
      }

    }
  });

  _exports.default = _default;
});