define("ods-app/templates/components/bs-datetimepicker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "D9xDMTA3",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,1]],null,{\"statements\":[[0,\"  \"],[13,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[26,\"input\",null,[[\"type\",\"class\",\"disabled\",\"name\",\"placeholder\"],[\"text\",\"form-control\",[22,[\"disabled\"]],[22,[\"textFieldName\"]],[22,[\"placeholder\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[4,\"unless\",[[22,[\"noIcon\"]]],null,{\"statements\":[[6,\"span\"],[10,\"class\",\"input-group-addon\"],[8],[0,\"\\n  \"],[6,\"span\"],[11,\"class\",[27,[[26,\"if\",[[22,[\"icons\",\"date\"]],[22,[\"icons\",\"date\"]],[22,[\"dateIcon\"]]],null]]]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ods-app/templates/components/bs-datetimepicker.hbs"
    }
  });

  _exports.default = _default;
});