define("ods-app/routes/pais/departamento/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "servir-ember-utilities/validators/custom-presence", "ember-changeset-validations/validators", "servir-ember-utilities/validators/is-codigo"], function (_exports, _abstractModuleRecord, _customPresence, _validators, _isCodigo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    modelName: 'departamento',
    indexRoute: 'pais.departamento',
    routeRecordIdentifierDynamicSegment: 'departamento_id',
    validations: Ember.computed(function () {
      return {
        codigo: [(0, _customPresence.default)({
          presence: true
        }), (0, _isCodigo.default)()],
        nombre: (0, _validators.validatePresence)({
          presence: true
        })
      };
    }),

    createRecordInstance() {
      let modelName = this.get('modelName');
      return this.store.createRecord(modelName, {
        pais: this.controllerFor('pais.departamento').get('pais')
      });
    },

    actions: {
      willTransition() {
        return true;
      }

    }
  });

  _exports.default = _default;
});