define("ods-app/templates/requerimiento/record/informacion/cancelar-requerimiento", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1Wixd+kx",
    "block": "{\"symbols\":[\"modal\",\"footer\",\"f\",\"razonCancelacion\"],\"statements\":[[4,\"bs-modal\",null,[[\"onHidden\"],[[26,\"action\",[[21,0,[]],\"discardChanges\"],null]]],{\"statements\":[[4,\"component\",[[21,1,[\"header\"]]],null,{\"statements\":[[0,\"    \"],[6,\"h4\"],[10,\"class\",\"modal-title\"],[8],[0,\"\\n      Cancelar requerimiento\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[21,1,[\"body\"]]],null,{\"statements\":[[4,\"extended-form-for\",[[22,[\"changeset\"]]],null,{\"statements\":[[4,\"component\",[[21,3,[\"ace-power-select\"]],\"razonCancelacion\",\"Razon de cancelación:\"],[[\"selected\",\"options\",\"searchField\",\"placeholder\",\"onchange\"],[[22,[\"changeset\",\"razonCancelacion\"]],[22,[\"model\",\"razonesCancelacion\"]],\"nombre\",\"Seleccionar...\",[26,\"action\",[[21,0,[]],[26,\"mut\",[[22,[\"changeset\",\"razonCancelacion\"]]],null]],null]]],{\"statements\":[[0,\"        \"],[1,[21,4,[\"nombre\"]],false],[0,\"\\n\"]],\"parameters\":[4]},null],[0,\"\\n      \"],[1,[26,\"component\",[[21,3,[\"ace-textarea-field\"]],\"observacionesCancelacion\",\"Observaciones de cancelación:\"],[[\"placeholder\"],[\"Observaciones de cancelación\"]]],false],[0,\"\\n\"]],\"parameters\":[3]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[21,1,[\"footer\"]]],null,{\"statements\":[[4,\"bs-button\",null,[[\"size\",\"type\",\"onClick\"],[\"xs\",\"success\",[26,\"action\",[[21,0,[]],\"submitCancelacion\"],null]]],{\"statements\":[[0,\"      Guardar\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"bs-button\",null,[[\"size\",\"onClick\"],[\"xs\",[26,\"action\",[[21,0,[]],[21,1,[\"close\"]]],null]]],{\"statements\":[[0,\"      Cancelar\\n\"]],\"parameters\":[]},null]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ods-app/templates/requerimiento/record/informacion/cancelar-requerimiento.hbs"
    }
  });

  _exports.default = _default;
});