define("ods-app/models/requerimiento-competencia", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    requerimiento: belongsTo('requerimiento'),
    competencia: attr('string'),
    grado: attr('string'),
    tipo: attr('string'),
    gradoToString: Ember.computed('grado', function () {
      const type = this.constants.COMPETENCE_TYPE.findBy('key', this.grado);
      return type ? type.name : this.grado;
    })
  });

  _exports.default = _default;
});