define("ods-app/routes/requerimiento/record/perfil", ["exports", "servir-ember-utilities/mixins/route/is-tab-subroute", "ods-app/mixins/permission-validator"], function (_exports, _isTabSubroute, _permissionValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_isTabSubroute.default, _permissionValidator.default, {
    routePermission: 'VIEW_RQ_TAB_PROFILE',
    noPermissionTransitionRoute: 'requerimiento',

    model() {
      let parentModel = this._super(...arguments);

      if (parentModel.record.get('isNew')) {
        return parentModel;
      }

      return Ember.RSVP.hash({
        competencias: this.store.query('requerimiento-competencia', {
          filter: {
            'requerimiento': parentModel.record.get('id')
          }
        })
      }).then(({
        competencias
      }) => ({
        competenciasTecnicas: competencias.toArray().filterBy('tipo', this.constants.COMPETENCE_TYPE_TECNICA),
        competenciasLiderazgo: competencias.toArray().filterBy('tipo', this.constants.COMPETENCE_TYPE_LIDERAZGO)
      }));
    }

  });

  _exports.default = _default;
});