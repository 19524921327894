define("ods-app/models/terna", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    datesCalculator: Ember.inject.service(),
    correlativo: attr('number'),
    fechaVencimientoEnvio: attr('custom-date'),
    fechaEnvioReal: attr('custom-date'),
    aplicaciones: hasMany('aplicacion'
    /*, { inverse: 'terna' }*/
    ),
    fechaConfirmacion: attr('custom-date'),
    fechaConfirmacionReal: attr('custom-date'),
    fechaEntrevista: attr('custom-date'),
    fechaConfirmacionEntrevista: attr('custom-date'),
    fechaEntrevistaReprogramada: attr('custom-date'),
    comentarios: attr('string'),
    activo: attr('boolean'),
    aceptada: attr('boolean'),
    reprogramar: attr('boolean'),
    requerimiento: belongsTo('requerimiento'),
    fechaAceptadaDeshabilitada: attr('custom-date'),

    /**
     * Deprecated
     */
    totalDias: attr('number', {
      defaultValue: 0
    }),
    createdAt: attr('custom-date'),

    /**
     * Tiempo disponible antes de la fecha de vencimiento de envío de la terna
     */
    tiempoDisponible: Ember.computed('fechaVencimientoEnvio', 'requerimiento', 'fechaAceptadaDeshabilitada', 'activo', 'aceptada', function () {
      if (this.get('requerimiento.estatus') === this.constants.REQUIREMENT_STATUS_CONFIRMADO_PENDIENTE || this.get('requerimiento.estatus') === this.constants.REQUIREMENT_STATUS_REPROCESO || this.get('requerimiento.estatus') === this.constants.REQUIREMENT_STATUS_CONGELADO || this.get('requerimiento.estatus') === this.constants.REQUIREMENT_STATUS_CANCELADO) {
        return;
      }

      let fechaVencimiento = (0, _moment.default)(this.get('fechaVencimientoEnvio'));
      let comparacion = new Date();

      if (!this.get('activo') || this.get('aceptada')) {
        comparacion = (0, _moment.default)(this.get('fechaAceptadaDeshabilitada'));
      }

      return this.get('datesCalculator').getCantidadDiasHabiles(comparacion, fechaVencimiento);
    }),
    situacion: Ember.computed('fechaVencimientoEnvio', 'fechaEnvioReal', function () {
      var comparacion = new Date();
      let situacion = 'EN TIEMPO';

      if (this.fechaEnvioReal) {
        comparacion = this.fechaEnvioReal;
      }

      let diasParaVencimientoEnvio = this.get('datesCalculator').getCantidadDiasHabiles(comparacion, this.fechaVencimientoEnvio);

      if (diasParaVencimientoEnvio < 0) {
        situacion = 'FUERA DE TIEMPO';
      }

      return situacion;
    }),
    fechaVencimientoEnvioFormatted: Ember.computed('fechaVencimientoEnvio', function () {
      const momentDate = (0, _moment.default)(this.fechaVencimientoEnvio);

      if (Ember.isNone(this.fechaVencimientoEnvio) || !momentDate.isValid()) {
        return '';
      }

      return momentDate.format('DD/MM/YYYY');
    })
  });

  _exports.default = _default;
});