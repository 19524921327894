define("ods-app/routes/servicio/tutorial", ["exports", "servir-ember-utilities/routes/abstract-module-record"], function (_exports, _abstractModuleRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    modelName: 'servicio',
    indexRoute: 'servicio',
    toast: Ember.inject.service(),

    setupController(controller
    /*, model*/
    ) {
      this._super(...arguments);

      this.store.query('tutorial', {
        filter: {
          codigo: 'SERVICIO'
        }
      }).then(tutorial => {
        if (!tutorial.get('firstObject')) {
          this.toast.error('Video no encontrado.');
          this.transitionTo(this.get('indexRoute'));
          return;
        }

        tutorial = tutorial.get('firstObject');
        controller.set('linkVideo', tutorial.get('link'));
      });
    }

  });

  _exports.default = _default;
});