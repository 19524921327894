define("ods-app/models/experiencia-laboral", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    nombreEmpresa: attr('string'),
    puesto: attr('string'),
    fechaIngreso: attr('custom-date'),
    fechaEgreso: attr('custom-date'),
    motivoRetiro: attr('string'),
    esTrabajoActual: attr('boolean', {
      defaultValue: false
    }),
    esPenultimoTrabajo: attr('boolean', {
      defaultValue: false
    }),
    telefonosEmpresa: attr(),
    candidato: belongsTo('candidato'),
    fechaIngresoFormatted: Ember.computed('fechaIngreso', function () {
      let date = (0, _moment.default)(this.fechaIngreso || '');
      return date.isValid() ? date.format('DD/MM/YYYY') : '';
    }),
    fechaEgresoFormatted: Ember.computed('fechaEgreso', function () {
      let date = (0, _moment.default)(this.fechaEgreso || '');
      return date.isValid() ? date.format('DD/MM/YYYY') : '';
    })
  });

  _exports.default = _default;
});