define("ods-app/models/rango-salarial", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    codigo: attr('string'),
    salarioMonedaLocal: attr('number', {
      defaultValue: 0
    }),
    monedaLocal: belongsTo('moneda'),
    salarioMonedaExtranjera: attr('number', {
      defaultValue: 0
    }),
    monedaExtranjera: belongsTo('moneda'),
    cliente: belongsTo('cliente'),
    nombreRangoSalarial: Ember.computed('codigo', 'salarioMonedaLocal', 'monedaLocal', function () {
      return `${this.codigo} - ${this.monedaLocal.get('simbolo')}${this.salarioMonedaLocal}`;
    })
  });

  _exports.default = _default;
});