define("ods-app/routes/cliente/record/datos-facturacion/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "ember-changeset-validations/validators"], function (_exports, _abstractModuleRecord, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    modelName: 'cliente-dato-facturacion',
    indexRoute: 'cliente.record.datos-facturacion',
    routeRecordIdentifierDynamicSegment: 'datosfacturacion_id',
    validations: Ember.computed(function () {
      return {
        nit: (0, _validators.validatePresence)({
          presence: true
        }),
        razonSocial: (0, _validators.validatePresence)({
          presence: true
        }),
        direccionFiscal: (0, _validators.validatePresence)({
          presence: true
        })
      };
    }),

    createRecordInstance() {
      const modelName = this.get('modelName');
      const cliente = this.modelFor('cliente.record').record;
      return this.store.createRecord(modelName, {
        cliente
      });
    },

    actions: {
      willTransition() {
        return true;
      }

    }
  });

  _exports.default = _default;
});