define("ods-app/routes/requerimiento/record/informacion/reasignar-proyecto", ["exports", "ods-app/mixins/permission-validator"], function (_exports, _permissionValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_permissionValidator.default, {
    toast: Ember.inject.service(),
    loader: Ember.inject.service(),
    routePermission: 'REASSIGN_PROJECT',
    indexRoute: 'requerimiento.record.informacion',

    setupController(controller, model) {
      this._super(controller, model);

      let changeset = this.controllerFor(this.indexRoute).get('changeset');
      controller.setProperties({
        changeset,
        record: this.modelFor(this.indexRoute).record
      });
    }

  });

  _exports.default = _default;
});