define("ods-app/templates/components/requerimiento-aplicacion-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "F2VSZWPg",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[22,[\"isDisabled\"]]],null,{\"statements\":[[0,\"  \"],[6,\"span\"],[10,\"class\",\"light-grey\"],[8],[1,[20,\"value\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[20,\"value\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ods-app/templates/components/requerimiento-aplicacion-cell.hbs"
    }
  });

  _exports.default = _default;
});