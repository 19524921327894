define("ods-app/controllers/requerimiento/record/perfil", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "ods-app/utils/common"], function (_exports, _abstractModuleIndex, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    indexCtrl: Ember.inject.controller('requerimiento.record'),
    editRoute: 'requerimiento.record.perfil.competencia',
    columns: Ember.computed(function () {
      let columns = [{
        label: 'Competencia',
        valuePath: 'competencia',
        width: '100px',
        sortable: false
      }, {
        label: 'Grado',
        valuePath: 'grado',
        width: '50px',
        sortable: false
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        sortable: false,
        width: '50px',
        // WARNING: Cuando se recomputan las columnas, la tabla no se actualiza.
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.edit.bind(this)
          },
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.delete.bind(this)
          }
        }
      }];
      return Ember.A(columns);
    }),
    actions: {
      onChangeEsBorderless(changeset, propertyName, esBorderless = null) {
        changeset.set(propertyName, esBorderless);
        this.indexCtrl.updateInformacionCompensacion(esBorderless);
      },

      delete(competencia) {
        this.send('openModal', 'popups/simple-confirm', {
          message: '¿Está seguro de eliminar el registro?',
          onSubmit: () => {
            competencia.destroyRecord().then(() => {
              if (competencia.get('tipo') === this.constants.COMPETENCE_TYPE_TECNICA) {
                Ember.set(this.model, 'competenciasTecnicas', this.model.competenciasTecnicas.without(competencia));
              } else if (competencia.get('tipo') === this.constants.COMPETENCE_TYPE_LIDERAZGO) {
                Ember.set(this.model, 'competenciasLiderazgo', this.model.competenciasLiderazgo.without(competencia));
              }

              this.toast.success('Registro eliminado correctamente.');
            }).catch(error => (0, _common.handleDeleteError)(competencia, this.toast, error));
          }
        });
      },

      beneficiosExtraUpdated() {
        this.changeset.set('beneficiosExtra', this.changeset.get('beneficiosExtraMapped').map(r => r.key));
      },

      nuevaCompetencia(tipo) {
        this.transitionToRoute('requerimiento.record.perfil.competencia', 'new', {
          queryParams: {
            'tipo': tipo
          }
        });
      }

    }
  });

  _exports.default = _default;
});