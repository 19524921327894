define("ods-app/routes/role/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "servir-ember-utilities/mixins/route/has-tabs", "ember-changeset-validations/validators", "servir-ember-utilities/validators/custom-presence", "servir-ember-utilities/validators/is-codigo"], function (_exports, _abstractModuleRecord, _hasTabs, _validators, _customPresence, _isCodigo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend(_hasTabs.default, {
    modelName: 'role',
    indexRoute: 'role',
    defaultTabSubRoute: 'generales',
    breadcrumbsRoute: Ember.computed('recordId', function () {
      return [{
        name: 'Roles',
        route: 'role'
      }, {
        name: 'Editar',
        route: `role.record.${this.defaultTabSubRoute}`,
        queryParam: this.recordId
      }];
    }),
    validations: Ember.computed(function () {
      return {
        codigo: [(0, _customPresence.default)({
          presence: true
        }), (0, _isCodigo.default)()],
        nombre: (0, _validators.validatePresence)({
          presence: true
        })
      };
    }),
    relatedModelsToQuery: Ember.computed(function () {
      return [{
        name: 'allPermissions',
        modelName: 'permission',
        queryParams: {
          filter: {
            activo: 1
          }
        }
      }];
    }),

    model()
    /*params, transition*/
    {
      return this._super(...arguments).then(modelData => {
        const role = modelData.record;
        const rolePermissionIds = role.hasMany('permissions').ids();

        if (!rolePermissionIds.length) {
          return modelData;
        }

        return this.store.query('permission', {
          filter: {
            id: rolePermissionIds
          }
        }).then(() => modelData);
      });
    }

  });

  _exports.default = _default;
});