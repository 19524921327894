define("ods-app/routes/cliente/record/proyectos", ["exports", "servir-ember-utilities/mixins/route/is-tab-subroute"], function (_exports, _isTabSubroute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_isTabSubroute.default, {
    model() {
      // TODO: Averiguar porque la llamada al super no devuelve una promesa.
      return Ember.RSVP.resolve(this._super(...arguments)).then(parentModel => {
        return parentModel.record.get('proyectos').then(proyectos => {
          Ember.set(parentModel, 'proyectos', proyectos);
          return parentModel;
        });
      });
    }

  });

  _exports.default = _default;
});