define("ods-app/routes/proyecto/record/servicio", ["exports", "servir-ember-utilities/mixins/route/is-tab-subroute"], function (_exports, _isTabSubroute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //import HasDependentFields from 'servir-ember-utilities/mixins/route/has-dependent-fields';
  //import { computed } from '@ember/object';
  var _default = Ember.Route.extend(_isTabSubroute.default, {});

  _exports.default = _default;
});