define("ods-app/templates/pais/tutorial", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hkoDhEQK",
    "block": "{\"symbols\":[\"modal\",\"footer\"],\"statements\":[[4,\"bs-modal\",null,[[\"size\",\"onHidden\"],[\"lg\",[26,\"action\",[[21,0,[]],\"discardChanges\"],null]]],{\"statements\":[[4,\"component\",[[21,1,[\"header\"]]],null,{\"statements\":[[0,\"    \"],[6,\"h3\"],[10,\"class\",\"modal-title\"],[8],[0,\"\\n      Tutorial\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[21,1,[\"body\"]]],null,{\"statements\":[[0,\"    \"],[6,\"iframe\"],[10,\"width\",\"100%\"],[10,\"height\",\"500px\"],[11,\"src\",[20,\"linkVideo\"],null],[8],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[21,1,[\"footer\"]]],null,{\"statements\":[[4,\"bs-button\",null,[[\"onClick\",\"size\",\"type\"],[[26,\"action\",[[21,0,[]],[21,1,[\"close\"]]],null],\"xs\",\"danger\"]],{\"statements\":[[0,\"      Salir\\n\"]],\"parameters\":[]},null]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ods-app/templates/pais/tutorial.hbs"
    }
  });

  _exports.default = _default;
});