define("ods-app/controllers/role/record/permisos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    user: Ember.computed.readOnly('currentUser.user'),
    plugins: 'checkbox, wholerow',
    jstreeSelectedNodes: Ember.computed(function () {
      return Ember.A([]);
    }),
    availablePermissions: Ember.computed('user', 'allPermissions', function () {
      if (this.get('user').get('isSuperAdmin')) {
        return this.allPermissions;
      }

      return this.allPermissions.rejectBy('requireSuperAdmin');
    }),
    selectedPermissions: Ember.computed('availablePermissions', 'jstreeSelectedNodes', function () {
      const selectedIds = this.jstreeSelectedNodes.map(n => n.id);
      const finalPermissionsIds = this.mapIds(this.availablePermissions.filter(p => selectedIds.includes(p.get('id'))));
      return this.availablePermissions.filter(p => finalPermissionsIds.includes(p.get('id')));
    }),
    themes: Ember.computed(function () {
      return {
        icons: false
      };
    }),
    checkboxOptions: Ember.computed(function () {
      return {
        keep_selected_style: false
      };
    }),
    treeData: Ember.computed('availablePermissions', 'record', function () {
      const rootPermissions = this.availablePermissions.filter(p => !p.get('parent.id'));
      const rootIds = rootPermissions.map(p => p.get('id'));
      const childPermissions = this.availablePermissions.filter(p => !rootIds.includes(p.get('id')));
      const currentParentsPermissionsIds = this.record.get('permissions').filter(p => p.get('parent.id')).map(p => p.get('parent.id')).uniq();
      const currentParentsIds = this.record.get('permissions').filter(p => p.get('parent.id')).map(p => p.get('id'));
      const currentRootPermissionsIds = this.record.get('permissions').filter(p => !p.get('parent.id')).map(p => p.get('id'));
      const currentPermissionRootIds = currentRootPermissionsIds.filter(p => !currentParentsPermissionsIds.includes(p));
      const currentPermissionIds = currentParentsIds.concat(currentPermissionRootIds).uniq();
      let treeData = rootPermissions.sortBy('prioridad').map(p => this.mapPermissionToJsTreeNode(p, currentPermissionIds));
      treeData.forEach(node => this.serializeChildren(node, childPermissions, currentPermissionIds));
      return treeData;
    }),

    mapIds(selectedPermissions) {
      const selectedIds = selectedPermissions.map(p => p.get('id'));
      const selectedParentsIds = selectedPermissions.filter(p => p.get('parent.id') != null).map(p => p.get('parent.id'));
      const mergeIds = selectedIds.concat(selectedParentsIds).uniq();
      const areEquals = mergeIds.reduce((prev, id) => prev && selectedIds.includes(id), true);

      if (areEquals) {
        return mergeIds;
      }

      return this.mapIds(this.availablePermissions.filter(p => mergeIds.includes(p.get('id'))));
    },

    mapPermissionToJsTreeNode(permission, currentPermissionIds) {
      return {
        id: permission.get('id'),
        text: permission.get('nombre'),
        state: {
          opened: true,
          selected: currentPermissionIds.includes(permission.get('id'))
        },
        children: Ember.A([])
      };
    },

    serializeChildren(node, childPermissions, currentPermissionIds) {
      node.children = [];
      const nodeChildren = childPermissions.filter(p => p.get('parent.id') === node.id).sortBy('prioridad');
      nodeChildren.forEach(childPermission => {
        const childNode = this.mapPermissionToJsTreeNode(childPermission, currentPermissionIds);
        node.children.push(childNode); // If any child permission is not granted yet, the parent node cannot be in selected state.

        if (!currentPermissionIds.includes(childPermission.get('id'))) {
          node.state.selected = false;
        }

        this.serializeChildren(childNode, childPermissions, currentPermissionIds);
      });
    },

    actions: {
      jstreeDidChange() {
        this.changeset.set('permissions', this.selectedPermissions);
      }

    }
  });

  _exports.default = _default;
});