define("ods-app/routes/proyecto/record/calculo-planilla", ["exports", "servir-ember-utilities/mixins/route/is-tab-subroute"], function (_exports, _isTabSubroute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_isTabSubroute.default, {
    years: Ember.computed(function () {
      let nowYear = new Date().getFullYear();
      let years = [nowYear];

      while (nowYear-- >= 2016) {
        years.push(nowYear);
      }

      return years;
    }),

    setupController(controller)
    /*model*/
    {
      this._super(...arguments);

      let now = new Date();
      controller.set('anio', now.getFullYear());
      controller.set('mes', now.getMonth());
      controller.set('anios', this.years);
    }

  });

  _exports.default = _default;
});