define("ods-app/models/requerimiento-archivo", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    belongsTo,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    requerimiento: belongsTo('requerimiento'),
    upload: belongsTo('upload'),
    nombre: attr('string'),
    observaciones: attr('string'),
    createdAt: attr('custom-date', {
      defaultValue() {
        return new Date();
      }

    }),
    createdBy: attr('string'),
    createdAtFormatted: Ember.computed('createdAt', function () {
      const momentDate = (0, _moment.default)(this.createdAt);

      if (Ember.isNone(this.createdAt) || !momentDate.isValid()) {
        return '';
      }

      return momentDate.format('DD/MM/YYYY');
    })
  });

  _exports.default = _default;
});