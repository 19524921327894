define("ods-app/routes/pais/departamento", ["exports", "servir-ember-utilities/routes/abstract-module-index"], function (_exports, _abstractModuleIndex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    pais: null,
    breadcrumbsRoute: Ember.computed(function () {
      return [{
        name: 'Países',
        route: 'pais'
      }, {
        name: 'Departamentos',
        route: 'pais.departamento'
      }];
    }),

    model(params) {
      this.set('pais', null);

      if (!params.pais_id) {
        throw new Error('This route expects pais_id parameter.');
      }

      return this.store.findRecord('pais', params.pais_id).then(pais => {
        this.set('pais', pais);
        return Ember.A([]);
      });
    },

    setupController(controller, model) {
      controller.set('reload', true);
      controller.set('pais', this.get('pais'));

      this._super(controller, model);
    },

    renderTemplate: function () {
      this.render('pais/departamento', {
        into: 'application'
      });
    }
  });

  _exports.default = _default;
});