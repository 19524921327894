define("ods-app/routes/notification-config/index", ["exports", "servir-ember-utilities/routes/abstract-module-index", "ods-app/mixins/permission-validator"], function (_exports, _abstractModuleIndex, _permissionValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend(_permissionValidator.default, {
    routePermission: 'VIEW_NOTIFICATION_CONFIGS',
    breadcrumbsRoute: Ember.computed(function () {
      return [{
        name: 'Notificaciones dinámicas',
        route: 'notification-config'
      }];
    })
  });

  _exports.default = _default;
});