define("ods-app/routes/logout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),

    beforeModel(transition) {
      this.session.invalidate();
      transition.abort();
      return this.transitionTo('login');
    }

  });

  _exports.default = _default;
});