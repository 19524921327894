define("ods-app/controllers/planilla-cargo/record", ["exports", "servir-ember-utilities/controllers/abstract-module-record"], function (_exports, _abstractModuleRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    toast: Ember.inject.service(),
    indexCtrl: Ember.inject.controller('planilla-cargo'),
    indexRoute: 'planilla-cargo',
    postSaveTransitionRoute: Ember.computed.readOnly('indexRoute'),
    factorhTipoDescuentoActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editFactorhTipoDescuento.bind(this)
      }];
    }),

    postSave() {
      this.indexCtrl.reloadModel();
    },

    actions: {
      editFactorhTipoDescuento() {
        return this.changeset.validate().then(() => {
          if (!this.changeset.get('isValid')) {
            this.toast.error('Corrija los errores para poder realizar esta acción.');
            return;
          }

          this.transitionToRoute('planilla-cargo.record.factorh-tipo-descuento');
        });
      },

      discardChanges() {
        if (this.changeset.get('isDirty')) {
          this.record.rollbackAttributes();
        }

        this.transitionToRoute(this.get('indexRoute'));
      }

    }
  });

  _exports.default = _default;
});