define("ods-app/controllers/candidato/record/aplicacion", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "ods-app/models/aplicacion"], function (_exports, _abstractModuleIndex, _aplicacion) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    modelName: 'aplicacion',
    modelClass: _aplicacion.default,
    editRoute: 'aplicacion.record',
    reload: true,
    modelFilters: Ember.computed('record', function () {
      return [{
        selectedFilter: {
          filterName: 'candidato'
        },
        value: this.get('record.id') || -1
      }];
    }),
    columns: Ember.computed(function () {
      return Ember.A([{
        label: 'Código',
        valuePath: 'codigo',
        sortable: false,
        width: '50px'
      }, {
        label: 'Fecha de aplicación',
        valuePath: 'createdAtFormatted',
        sortable: false,
        width: '100px'
      }, {
        label: 'Cliente',
        valuePath: 'requerimiento.proyecto.cliente.nombre',
        sortable: false,
        width: '100px'
      }, {
        label: 'Proyecto',
        valuePath: 'requerimiento.proyecto.codigo',
        sortable: false,
        width: '100px'
      }, {
        label: 'Requerimiento',
        valuePath: 'requerimiento.nombreReq',
        sortable: false,
        width: '100px'
      }, {
        label: 'Funnel',
        valuePath: 'statusToString',
        sortable: false,
        width: '100px'
      }, {
        label: 'Estatus requerimiento',
        valuePath: 'requerimiento.statusToString',
        sortable: false,
        width: '100px'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        width: '70px',
        table: this,
        sortable: false,
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.edit.bind(this)
          }
        }
      }]);
    })
  });

  _exports.default = _default;
});