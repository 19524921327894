define("ods-app/controllers/candidato/record/informacion/asociar-proyecto", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    toast: Ember.inject.service(),
    loader: Ember.inject.service(),
    proyecto: '',
    crearContratacion: false,
    crearEmpleado: false,
    actions: {
      discardChanges() {
        this.transitionToRoute('candidato.record.informacion', this.candidato.get('id'));
      },

      asociarAPlanilla() {
        if (!this.proyecto) {
          this.toast.error('Debe seleccionar un proyecto.');
        }

        let proyectoCandidato = this.store.createRecord('proyecto-candidato', {
          proyecto: this.proyecto,
          candidato: this.candidato,
          fechaBaja: new Date(),
          fechaAlta: new Date(),
          activo: true
        });
        this.loader.setIsLoading();
        proyectoCandidato.save().then(() => {
          this.toast.success('Registro creado correctamente.');
          this.transitionToRoute('candidato.record.informacion', this.candidato.get('id'));
        }).catch(error => {
          this.toast.error('No se pudo asociar el candidato al proyecto.');
          throw error;
        }).finally(() => this.loader.setNotLoading());
      }

    }
  });

  _exports.default = _default;
});