define("ods-app/app", ["exports", "ods-app/resolver", "ember-load-initializers", "ods-app/config/environment", "ods-app/models/custom-inflector-rules", "@sentry/browser", "@sentry/integrations", "@sentry/tracing"], function (_exports, _resolver, _emberLoadInitializers, _environment, _customInflectorRules, Sentry, Integrations, _tracing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  if (_environment.default.SENTRY_DSN) {
    Sentry.init({
      dsn: _environment.default.SENTRY_DSN,
      integrations: [new Integrations.Ember(), new _tracing.Integrations.BrowserTracing({
        tracingOrigins: [_environment.default.API.host],
        beforeNavigate: context => {
          // Agrupar las rutas de tipo record que son del mismo modelo para no
          // crear una transacción por cada id: proyecto/1, proyecto/2, etc.
          let routeRegex;
          let recordRoutes = ['/proyecto', '/requerimiento', '/aplicacion', '/candidato'];
          recordRoutes.forEach(route => {
            if (context.name.indexOf(route) !== -1) {
              routeRegex = new RegExp(`${route}/\\d+`, 'g');
              context.name = context.name.replace(routeRegex, `${route}/id`);
            }
          });
          return context;
        }
      })],
      ignoreErrors: ['TransitionAborted', 'TransitionAbortedError', 'AbortError' // Adapter aborted operation
      ],
      tracesSampler: samplingContext => {
        // TODO: Investigar cómo obtener la ruta a la que se va a navegar (es más útil).
        // Ahora mismo, samplingContext.location.pathname devuelve la ruta actual antes
        // de transicionar, a pesar que al hacer un console log, se muestra la ruta de
        // transición. Esto implica que la primera vez que se alcanza una ruta que sí
        // queremos medir, no se hace. Por otro lado, cuando se transiciona desde una
        // medible a otra no es medible, sí se hace xq se toma en cuenta la ruta previa.
        let criticalRoutes = ['/login', '/login-check', '/usuarios', '/proyecto', '/proyectos-existentes', '/requerimiento', '/aplicacion', '/candidato', '/dashboard'];
        let shouldSample = criticalRoutes.reduce((prev, route) => {
          return prev || samplingContext.location.pathname.indexOf(route) !== -1;
        }, false);
        return shouldSample;
      }
    });
  }

  const App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
  var _default = App;
  _exports.default = _default;
});