define("ods-app/controllers/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    loader: Ember.inject.service(),
    toast: Ember.inject.service(),
    actions: {
      authenticate(username, password) {
        this.loader.setIsLoading();
        return this.session.authenticate('authenticator:jwt', {
          username,
          password
        }).catch(error => {
          let errorMsg = 'No se puede acceder';

          if (error.status === 401) {
            errorMsg = 'Credenciales inválidos';
          }

          this.toast.error(errorMsg);
          throw error;
        }).finally(() => this.loader.setNotLoading());
      }

    }
  });

  _exports.default = _default;
});