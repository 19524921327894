define("ods-app/routes/candidato/record/informacion/asociar-proyecto", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return Ember.RSVP.hash({
        proyectos: this.store.query('proyecto', {
          filter: {
            'servicioAdquirido.tipoServicio': this.constants.SERVICE_TYPES_HIRED_FACTORH
          }
        }),
        // Se obtienen aquí los clientes para efectos del rendering del nombre del
        // proyecto
        clientes: this.store.query('cliente', {
          filter: {
            proyectos: {
              exists: 1
            }
          }
        })
      });
    },

    setupController(controller, model) {
      this._super(controller, model);

      const candidato = this.modelFor('candidato.record').record;
      controller.set('candidato', candidato);
    }

  });

  _exports.default = _default;
});