define("ods-app/templates/reset-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XHer/Dfu",
    "block": "{\"symbols\":[\"modal\",\"f\"],\"statements\":[[4,\"bs-modal\",null,[[\"backdropClose\",\"keyboard\"],[false,false]],{\"statements\":[[4,\"component\",[[21,1,[\"header\"]]],[[\"closeButton\"],[false]],{\"statements\":[[0,\"    \"],[6,\"h4\"],[10,\"class\",\"modal-title\"],[8],[0,\"\\n      Reinicio de contraseña\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[21,1,[\"body\"]]],null,{\"statements\":[[4,\"extended-form-for\",[[21,0,[\"changeset\"]]],null,{\"statements\":[[0,\"      \"],[1,[26,\"component\",[[21,2,[\"ace-password-field\"]],\"plainPassword\",\"Contraseña\"],[[\"placeholder\"],[\"Contraseña\"]]],false],[0,\"\\n\\n      \"],[1,[26,\"component\",[[21,2,[\"ace-password-field\"]],\"confirmPassword\",\"Confirmar contraseña\"],[[\"placeholder\"],[\"Contraseña\"]]],false],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[21,1,[\"footer\"]]],[[\"classNames\"],[\"center\"]],{\"statements\":[[4,\"bs-button\",null,[[\"size\",\"type\",\"defaultText\",\"pendingText\",\"onClick\"],[\"xs\",\"success\",\"Guardar\",\"Guardando...\",[26,\"action\",[[21,0,[]],\"resetPassword\"],null]]],{\"statements\":[],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ods-app/templates/reset-password.hbs"
    }
  });

  _exports.default = _default;
});