define("ods-app/helpers/is-empresa", ["exports", "ods-app/helpers/has-config"], function (_exports, _hasConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isEmpresa = isEmpresa;
  _exports.default = void 0;

  function isEmpresa(configs, empresaCodigo) {
    return (0, _hasConfig.hasConfig)(configs, 'SISTEMA_EMPRESA', empresaCodigo);
  }

  var _default = Ember.Helper.extend({
    currentUser: Ember.inject.service(),

    compute([empresaCodigo]) {
      return isEmpresa(this.currentUser.get('configs'), empresaCodigo);
    }

  });

  _exports.default = _default;
});