define("ods-app/controllers/requerimiento/record/ingreso/asociar-planilla", ["exports", "servir-ember-utilities/controllers/abstract-module-record"], function (_exports, _abstractModuleRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    indexCtrl: Ember.inject.controller('requerimiento.record.ingreso'),
    indexRoute: 'requerimiento.record.ingreso',
    postSaveTransitionRoute: Ember.computed.readOnly('indexRoute'),
    factorhTipoPlanilla: null,
    factorhDepartamento: null,
    factorhPuesto: null,
    numeroSeguroSocial: null,

    resetProperties() {
      this.setProperties({
        factorhTipoPlanilla: null,
        factorhDepartamento: null,
        factorhPuesto: null,
        numeroSeguroSocial: null
      });
    },

    submit() {
      if (!this.changeset.get('factorhPuestoId')) {
        this.toast.error('Debe seleccionar un puesto de FactoRH.');
        return;
      }

      if (!this.changeset.get('factorhTipoPlanillaId')) {
        this.toast.error('Debe seleccionar un tipo de planilla de FactoRH.');
        return;
      } // if (!this.changeset.get('numeroSeguroSocial')) {
      //   this.toast.error('Debe poner el numero de seguro social.');
      //   return;
      // }


      return this._super(...arguments).then(() => {
        let aplicacion = this.model.aplicacionesPlaceHolder.firstObject;
        aplicacion.set('estatus', this.constants.APPLICATION_STATUS_CONTRATADO);
        aplicacion.save();
      });
    },

    actions: {
      discardChanges() {
        if (this.changeset.get('isDirty') || this.record.get('isNew')) {
          this.record.rollbackAttributes();
        }

        this.transitionToRoute(this.indexRoute);
      },

      onChangePuesto(factorhPuesto) {
        if (!factorhPuesto) {
          this.changeset.set('factorhPuestoId', null);
          this.changeset.set('factorhPuestoStr', null);
          return;
        }

        this.changeset.set('factorhPuestoId', factorhPuesto.id);
        this.changeset.set('factorhPuestoStr', factorhPuesto.descripcion);
      },

      onChangeDepartamento(factorhDepartamento) {
        if (!factorhDepartamento) {
          this.changeset.set('factorhDepartamentoId', null);
          this.changeset.set('factorhDepartamentoStr', null);
          return;
        }

        this.changeset.set('factorhDepartamentoId', factorhDepartamento.id);
        this.changeset.set('factorhDepartamentoStr', factorhDepartamento.descripcion);
      },

      onChangeTipoPlanilla(factorhTipoPlanilla) {
        if (!factorhTipoPlanilla) {
          this.changeset.set('factorhTipoPlanillaId', null);
          this.changeset.set('factorhTipoPlanillaStr', null);
          return;
        }

        this.changeset.set('factorhTipoPlanillaId', factorhTipoPlanilla.id);
        this.changeset.set('factorhTipoPlanillaStr', factorhTipoPlanilla.nombre);
      }

    }
  });

  _exports.default = _default;
});