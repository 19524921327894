define("ods-app/controllers/cliente/record/datos-generales", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    toast: Ember.inject.service(),
    factorhEmpresaActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editFactorhEmpresa.bind(this)
      }];
    }),
    actions: {
      editFactorhEmpresa() {
        if (this.record.get('isNew')) {
          this.toast.error('Guarde el registro para poder realizar esta acción.');
          return;
        }

        return this.changeset.validate().then(() => {
          if (!this.changeset.get('isValid')) {
            this.toast.error('Corrija los errores para poder realizar esta acción.');
            return;
          }

          this.transitionToRoute('cliente.record.datos-generales.factorh-empresa');
        });
      }

    }
  });

  _exports.default = _default;
});