define("ods-app/models/upload", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    config: Ember.inject.service(),
    ajaxService: Ember.inject.service(),
    host: Ember.computed.readOnly('config.API.host'),
    nombreOriginal: attr('string'),
    mimeType: attr('string'),
    fileSize: attr('number'),
    fileSizeString: Ember.computed('fileSize', function () {
      return formatBytes(this.fileSize);
    }),
    archivoLink: Ember.computed('host', 'id', function () {
      if (!this.id) {
        return '';
      }

      return `${this.host}/uploads/${this.id}`;
    }),
    downloadLink: Ember.computed('id', function () {
      if (!this.id) {
        return null;
      } // Get signed download link.


      return this.ajaxService.request(`${this.get('host')}/uploads/${this.id}/get-signed-link`).then(response => response.signedLink).catch(error => {
        this.get('toast').error('Error al intentar visualizar un archivo.');
        throw error;
      });
    }),
    thumbnailUrl: Ember.computed('host', 'id', 'mimeType', function () {
      if (!this.id) {
        return '';
      }

      let icon = 'file.png';

      if (this.mimeType) {
        if (this.mimeType.includes('image')) {
          return this.downloadLink;
        }

        if (this.mimeType.includes('text')) {
          icon = 'text.png';
        }

        if (this.mimeType.includes('pdf')) {
          icon = 'pdf.png';
        }
      }

      return `${this.host}/app-icons/${icon}`;
    })
  });

  _exports.default = _default;
});