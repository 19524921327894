define("ods-app/controllers/requerimiento/record/llamada", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "ods-app/models/llamada"], function (_exports, _abstractModuleIndex, _llamada) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    editRoute: 'requerimiento.record.llamada.record',
    modelClass: _llamada.default,
    reload: true,
    modelFilters: Ember.computed('record', function () {
      return [{
        selectedFilter: {
          filterName: 'requerimiento'
        },
        value: this.get('record.id') || -1
      }];
    }),
    columns: Ember.computed(function () {
      return Ember.A([{
        label: 'Fecha',
        valuePath: 'fechaFormatted',
        sortable: false,
        width: '100px'
      }, {
        label: 'Aplicación',
        valuePath: 'aplicacion.candidato.nombreCompleto',
        sortable: false,
        width: '100px'
      }, {
        label: 'Teléfono',
        valuePath: 'aplicacion.candidato.telefonos',
        sortable: false,
        width: '100px'
      }, {
        label: 'Duración',
        valuePath: 'duracion',
        sortable: false,
        width: '100px'
      }, {
        label: 'Observaciones',
        valuePath: 'observaciones',
        sortable: false,
        width: '150px'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        width: '100px',
        sortable: false,
        objectActions: {
          crearLlamada: {
            title: 'Nueva llamada',
            buttonClass: 'btn-warning',
            icon: 'plus-square',
            action: this.actions.crearLlamada.bind(this)
          }
        }
      }]);
    }),

    reloadModel() {
      this.set('reload', true);
    },

    actions: {
      crearLlamada(llamada) {
        const aplicacionId = llamada.get('aplicacion.id');
        const requerimientoId = llamada.get('requerimiento.id');
        let controller = this;

        if (controller.changeset.get('estaDeshabilitado')) {
          this.toast.error('El requerimiento está deshabilitado');
          return;
        }

        this.transitionToRoute('requerimiento.record.llamada.record', 'new', {
          queryParams: {
            'aplicacionId': aplicacionId,
            'requerimientoId': requerimientoId
          }
        });
      }

    }
  });

  _exports.default = _default;
});