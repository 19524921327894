define("ods-app/routes/proyecto/record/empleado/cargos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    proyectoCandidatoIdPlaceholder: '',
    candidatoIdPlaceholder: '',

    model(params, transition) {
      let proyectoCandidatoId = transition.queryParams.proyectoCandidatoId;
      let candidatoId = transition.queryParams.candidatoId;
      this.set('proyectoCandidatoIdPlaceholder', proyectoCandidatoId);
      this.set('candidatoIdPlaceholder', candidatoId);
    },

    setupController(controller, model) {
      this._super(controller, model);

      const proyecto = this.modelFor('proyecto.record').record;
      controller.set('proyecto', proyecto);
      controller.set('proyectoCandidatoId', this.proyectoCandidatoIdPlaceholder);
      controller.set('candidatoId', this.candidatoIdPlaceholder);
    }

  });

  _exports.default = _default;
});