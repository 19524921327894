define("ods-app/templates/proyecto/record/empleado/cargos/agregar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rsgevhGW",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[4,\"extended-form-for\",[[22,[\"changeset\"]]],null,{\"statements\":[[4,\"if\",[[22,[\"record\",\"isNew\"]]],null,{\"statements\":[[0,\"    \"],[1,[26,\"component\",[[21,1,[\"ace-select-field\"]],\"planillaCargo\",\"Cargo:\"],[[\"options\",\"optionLabelPath\",\"optionValuePath\",\"includeBlank\",\"promptText\",\"promptIsSelectable\",\"disabled\"],[[22,[\"validPlanillaCargos\"]],\"nombre\",\"id\",true,\"Seleccionar\",true,[26,\"not\",[[22,[\"record\",\"isNew\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[26,\"component\",[[21,1,[\"ace-text-field\"]],\"planillaCargo.nombre\",\"Monto:\"],[[\"placeholder\",\"disabled\"],[\"Monto\",true]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n  \"],[1,[26,\"component\",[[21,1,[\"ace-money-field\"]],\"monto\",\"Monto:\"],[[\"placeholder\",\"disabled\"],[\"Monto\",[26,\"not\",[[22,[\"changeset\",\"planillaCargo\"]]],null]]]],false],[0,\"\\n\\n  \"],[1,[26,\"component\",[[21,1,[\"ace-checkbox-field\"]],\"activo\",\"Activo\"],[[\"defultValue\",\"disabled\"],[\"true\",[26,\"not\",[[22,[\"changeset\",\"planillaCargo\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ods-app/templates/proyecto/record/empleado/cargos/agregar.hbs"
    }
  });

  _exports.default = _default;
});