define("ods-app/validators/requerimiento-tipo-plaza-facturacion", ["exports", "ember-changeset-validations/validators", "ods-app/models/constants"], function (_exports, _validators, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateRequerimientoTipoPlazaFacturacion;

  function validateRequerimientoTipoPlazaFacturacion(options = {}) {
    let {
      changeset
    } = options;
    let wrappedValidateNumber = (0, _validators.validateNumber)({
      allowBlank: false,
      gt: 0
    });

    if (Ember.isBlank(changeset)) {
      throw new Error('Missing arguments for validator \'requerimiento-tipo-plaza-facturacion\'.');
    }

    return (key, value, oldValue, changes, content) => {
      if ([_constants.default.HIRING_TYPES_FACTURACION, _constants.default.HIRING_TYPES_FACTURA_ESPECIAL, _constants.default.HIRING_TYPES_FACTURACION_EXTERNA].includes(changeset.get('tipoPlaza'))) {
        return wrappedValidateNumber(key, value, oldValue, changes, content);
      }

      return true;
    };
  }
});