define("ods-app/controllers/reporte-planilla", ["exports", "ods-app/mixins/ods-app-file-saver"], function (_exports, _odsAppFileSaver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_odsAppFileSaver.default, {
    toast: Ember.inject.service(),
    currentMonthIndex: Ember.computed(function () {
      let now = new Date();
      return now.getMonth();
    }),
    previousMonthIndex: Ember.computed(function () {
      let now = new Date();
      now.setMonth(now.getMonth() - 1);
      return now.getMonth();
    }),
    actions: {
      setMonthIndex(monthIndex, mes) {
        if (monthIndex < 0 || monthIndex > 11) {
          throw Error('Invalid month index provided.');
        }

        if (mes === 'anterior') {
          this.changeset.set('mesActualSeleccionado', false);
          this.changeset.set('mesAnteriorSeleccionado', true);
        } else {
          this.changeset.set('mesActualSeleccionado', true);
          this.changeset.set('mesAnteriorSeleccionado', false);
        }

        this.changeset.set('mes', monthIndex);
      },

      generarReporte() {
        return this.changeset.validate().then(() => {
          if (!this.changeset.get('isValid')) {
            this.toast.error('Todos los campos son obligatorios.');
            return;
          }

          this.getFileFromServer('/reporte-planilla', {
            proyecto: this.changeset.get('proyecto.id') || null,
            anio: this.changeset.get('anio') || null,
            mes: this.changeset.get('mes') || 0
          });
        });
      }

    }
  });

  _exports.default = _default;
});