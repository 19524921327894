define("ods-app/mixins/permission-validator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    currentUser: Ember.inject.service(),
    toast: Ember.inject.service(),
    user: Ember.computed.alias('currentUser.user'),
    routePermission: null,
    noPermissionTransitionRoute: 'index',

    beforeModel() {
      const user = this.get('user');
      const routePermission = this.get('routePermission');
      let havePermission = false;

      if (!Ember.isNone(routePermission)) {
        const validPermissions = Array.isArray(routePermission) ? routePermission : [routePermission];
        havePermission = validPermissions.reduce((prev, permission) => prev || user.get(permission) || user.permissions[permission], false);
      }

      if (!havePermission) {
        this.toast.error('No tiene permiso para acceder a esta ruta.');
        const transitionRoute = this.get('indexRoute') || this.get('noPermissionTransitionRoute');
        return this.transitionTo(transitionRoute);
      }

      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});