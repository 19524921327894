define("ods-app/templates/components/alerts/new-app-version", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vZVsP5YX",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",\"alert alert-block alert-info\"],[8],[0,\"\\n  \"],[6,\"i\"],[10,\"class\",\"ace-icon fa fa-info-circle blue\"],[8],[9],[0,\"\\n  Hay una nueva versión del sistema. Haga click en\\n  \"],[6,\"strong\"],[10,\"class\",\"green\"],[8],[0,\"\\\"Actualizar\\\"\"],[9],[0,\" por favor.   \\n  \"],[6,\"button\"],[10,\"class\",\"btn btn-success btn-xs\"],[3,\"action\",[[21,0,[]],\"doHardReload\"]],[8],[0,\"\\n    \"],[6,\"i\"],[10,\"class\",\"fa fa-refresh\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n    Actualizar\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ods-app/templates/components/alerts/new-app-version.hbs"
    }
  });

  _exports.default = _default;
});