define("ods-app/controllers/candidato/record/informacion", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentDate: null,
    currentDateTitle: Ember.computed('currentDate', function () {
      if (!this.get('currentDate')) {
        return;
      }

      let currentDate = (0, _moment.default)(this.get('currentDate'));
      return 'Debe ser anterior al ' + currentDate.format('DD/MM/YYYY');
    })
  });

  _exports.default = _default;
});