define("ods-app/templates/modelo-configuracion/record", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JCYwaP5X",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[6,\"div\"],[10,\"class\",\"page-header\"],[8],[0,\"\\n  \"],[6,\"h1\"],[8],[0,\"\\n\"],[4,\"if\",[[22,[\"record\",\"isNew\"]]],null,{\"statements\":[[0,\"      Crear configuración de modelo\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      Configuración de modelo \"],[1,[22,[\"record\",\"nombreModelo\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"pull-right\"],[8],[0,\"\\n      \"],[1,[26,\"forms/controls/ace-async-save-button\",null,[[\"onClick\"],[[26,\"action\",[[21,0,[]],\"submit\"],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[6,\"div\"],[10,\"class\",\"col-xs-6\"],[8],[0,\"\\n\"],[4,\"extended-form-for\",[[22,[\"changeset\"]]],null,{\"statements\":[[0,\"      \"],[1,[26,\"component\",[[21,1,[\"ace-text-field\"]],\"nombreModelo\",\"Modelo\"],null],false],[0,\"\\n\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-checkbox-field\"]],\"usaCampoIdentificadorAutomatico\",\"Usa identificador automático\"],null],false],[0,\"\\n\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-text-field\"]],\"nombreCampoIdentificadorAutomatico\",\"Campo\"],null],false],[0,\"\\n\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-text-field\"]],\"formatoCampoIdentificador\",\"Formato\"],null],false],[0,\"\\n\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-checkbox-field\"]],\"usaCorrelativoAutoincrementable\",\"Usa correlativo\"],null],false],[0,\"\\n\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-checkbox-field\"]],\"permiteActualizarCampoIdentificador\",\"Permite actualizar campo identificador\"],null],false],[0,\"\\n\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-text-field\"]],\"numeroCorrelativoActual\",\"Correlativo actual\"],null],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ods-app/templates/modelo-configuracion/record.hbs"
    }
  });

  _exports.default = _default;
});