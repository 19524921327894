define("ods-app/validations/changeset/aplicacion", ["exports", "ember-changeset-validations/validators", "servir-ember-utilities/validators/custom-presence", "servir-ember-utilities/validators/is-codigo"], function (_exports, _validators, _customPresence, _isCodigo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    codigo: [(0, _customPresence.default)({
      presence: true
    }), (0, _isCodigo.default)()],
    candidato: (0, _validators.validatePresence)({
      presence: true
    }),
    requerimiento: (0, _validators.validatePresence)({
      presence: true
    }),
    createdAt: (0, _validators.validatePresence)({
      presence: true
    }),
    expectativaSalarial: [(0, _validators.validatePresence)({
      presence: true
    }), (0, _validators.validateNumber)()],
    fuenteReclutamiento: (0, _validators.validatePresence)({
      presence: true
    }),
    estatus: (0, _validators.validatePresence)({
      presence: true
    })
  };
  _exports.default = _default;
});