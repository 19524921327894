define("ods-app/routes/reset-password", ["exports", "ember-changeset", "ember-changeset-validations", "ember-changeset-validations/validators"], function (_exports, _emberChangeset, _emberChangesetValidations, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    user: Ember.computed.alias('currentUser.user'),
    validations: Ember.computed(function () {
      return {
        plainPassword: (0, _validators.validatePresence)({
          presence: true
        }),
        confirmPassword: (0, _validators.validateConfirmation)({
          on: 'plainPassword',
          message: 'No coincide con su contraseña'
        })
      };
    }),

    setupController(controller, model) {
      this._super(controller, model);

      let changeset = new _emberChangeset.default(this.get('user'), (0, _emberChangesetValidations.default)(this.get('validations')), this.get('validations'));
      controller.set('changeset', changeset);
    }

  });

  _exports.default = _default;
});