define("ods-app/models/llamada", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    fecha: attr('custom-date', {
      defaultValue() {
        return new Date();
      }

    }),
    nombre: attr('string'),
    telefono: attr('string'),
    minutos: attr('number'),
    segundos: attr('number'),
    aplicacion: belongsTo('aplicacion'),
    requerimiento: belongsTo('requerimiento'),
    observaciones: attr('string'),
    fechaFormatted: Ember.computed('fecha', function () {
      const momentDate = (0, _moment.default)(this.fecha);

      if (Ember.isNone(this.fecha) || !momentDate.isValid()) {
        return '';
      }

      return momentDate.format('DD/MM/YYYY hh:mm a');
    }),
    duracion: Ember.computed('minutos', 'segundos', function () {
      return `${this.minutos}min  ${this.segundos}seg`;
    })
  });

  _exports.default = _default;
});