define("ods-app/controllers/requerimiento/record/aplicacion/asociar-aplicacion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    toast: Ember.inject.service(),
    requerimientoExistente: '',
    actions: {
      discardChanges() {
        this.transitionToRoute('requerimiento.record.aplicacion', this.requerimiento.get('id'));
      },

      asociarAplicacion() {
        if (!this.requerimientoExistente) {
          this.toast.error('Debe seleccionar un requerimiento.');
          return;
        }

        if (!this.aplicacion) {
          this.toast.error('No se pudo crear la nueva aplicación.');
          this.set('requerimientoExistente', null);
          this.transitionToRoute('requerimiento.record.aplicacion', this.requerimiento.get('id'));
          return;
        }

        let codigoNuevaAplicacion = this.aplicacion.get('codigo');
        let requerimientoExistente = this.requerimientoExistente;
        codigoNuevaAplicacion = codigoNuevaAplicacion.concat('N');
        let nuevaAplicacion = this.store.createRecord('aplicacion', {
          candidato: this.aplicacion.get('candidato'),
          requerimiento: requerimientoExistente,
          codigo: codigoNuevaAplicacion,
          expectativaSalarial: this.aplicacion.get('expectativaSalarial') || 0,
          fuenteReclutamiento: this.aplicacion.get('fuenteReclutamiento'),
          estatus: this.constants.APPLICATION_STATUS_PRE_SCREENING,
          experiencia: this.aplicacion.get('experiencia') || null,
          comentarios: this.aplicacion.get('comentarios') || null,
          observacionesEntrevista: this.aplicacion.get('observacionesEntrevista'),
          observacionesRechazo: this.aplicacion.get('observacionesRechazo'),
          seleccionado: this.aplicacion.get('seleccionado')
        });
        nuevaAplicacion.save().then(() => {
          this.aplicacion.set('estatus', this.constants.APPLICATION_STATUS_ENVIADO_REQUERIMIENTO);
          this.aplicacion.save();
          this.set('requerimientoExistente', null);
          this.toast.success('Aplicación asociada correctamente al requerimiento.');
          this.transitionToRoute('requerimiento.record.aplicacion', this.requerimiento.get('id'));
        });
      }

    }
  });

  _exports.default = _default;
});