define("ods-app/models/reporte-plazas", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    hasMany,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    clientes: hasMany('cliente'),
    proyectos: hasMany('proyecto'),
    requerimientos: hasMany('requerimiento'),
    tipo: attr('string'),
    startDate: attr('custom-date'),
    endDate: attr('custom-date'),
    pais: belongsTo('pais'),
    departamento: belongsTo('departamento'),
    municipio: belongsTo('municipio'),
    // mes: attr('date'),
    reclutadores: hasMany('usuario'),
    status: attr(),
    fuentesReclutamiento: hasMany('fuente-reclutamiento')
  });

  _exports.default = _default;
});