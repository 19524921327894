define("ods-app/routes/requerimiento/record/informacion/cancelar-requerimiento", ["exports", "ods-app/mixins/permission-validator"], function (_exports, _permissionValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_permissionValidator.default, {
    routePermission: 'CANCEL_REQUIREMENT',
    indexRoute: 'requerimiento.record.informacion',

    model() {
      return Ember.RSVP.hash({
        razonesCancelacion: this.store.query('razon-cancelacion', {
          filter: {
            tipo: this.constants.CANCELLATION_REASON_SOURCE_TYPE_REQUERIMIENTO,
            activo: 1
          }
        })
      });
    },

    setupController(controller, model) {
      this._super(controller, model);

      let changeset = this.controllerFor(this.get('indexRoute')).get('changeset');
      controller.setProperties({
        changeset,
        record: this.modelFor(this.get('indexRoute')).record
      });
    }

  });

  _exports.default = _default;
});