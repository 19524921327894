define("ods-app/controllers/cliente/index", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "ods-app/models/cliente", "ods-app/helpers/is-empresa"], function (_exports, _abstractModuleIndex, _cliente, _isEmpresa) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    modelName: 'cliente',
    modelClass: _cliente.default,
    editRoute: 'cliente.record',
    reload: true,
    currentUser: Ember.inject.service(),
    modelSortingProperties: Ember.computed(function () {
      return Ember.A([{
        propertyName: 'createdAt'
      }]);
    }),
    columns: Ember.computed(function () {
      let columns = [{
        label: 'Código',
        valuePath: 'codigo',
        sortable: false,
        width: '50px',
        filtrable: true,
        filterName: 'codigo'
      }];

      if ((0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'OPTIMUM')) {
        columns.push({
          label: 'Nombre / Razón social',
          valuePath: 'nombre',
          sortable: false,
          width: '150px',
          filtrable: true,
          filterName: 'nombre'
        });
      }

      if ((0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'EL_TAIER') || (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'DEMO') || (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'SERVINOVA')) {
        columns.push({
          label: 'Nombre',
          valuePath: 'nombreComercial',
          sortable: false,
          width: '50px'
        }, {
          label: 'Razón social',
          valuePath: 'nombre',
          sortable: false,
          width: '150px'
        }, {
          label: 'Empresa en FactoRH',
          valuePath: 'factorhEmpresaStr',
          sortable: false,
          width: '100px'
        });
      }

      columns.push({
        label: 'NIT',
        valuePath: 'nit',
        sortable: false,
        width: '75px',
        filtrable: true,
        filterName: 'nit'
      }, {
        label: 'Teléfono',
        valuePath: 'telefono',
        sortable: false,
        width: '75px'
      }, {
        label: 'Dirección',
        valuePath: 'direccion',
        sortable: false,
        width: '150px'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        sortable: false,
        width: '100px',
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.edit.bind(this)
          },
          resumen: {
            title: 'Resumen',
            buttonClass: 'btn-warning',
            icon: 'file',
            action: this.actions.resumen.bind(this)
          },
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.delete.bind(this)
          }
        }
      });
      return Ember.A(columns);
    }),
    actions: {
      resumen(cliente) {
        this.transitionToRoute('cliente.index.resumen', cliente.get('id'));
      }

    }
  });

  _exports.default = _default;
});