define("ods-app/controllers/proyecto/index", ["exports", "ods-app/helpers/is-empresa"], function (_exports, _isEmpresa) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    toast: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    selectedCliente: null,
    selectedContacto: null,
    selectedDatoFacturacion: null,
    selectedServicio: null,
    columnsContactos: Ember.computed(function () {
      return Ember.A([{
        label: '',
        sortable: false,
        width: '60px',
        cellComponent: 'tables/row/select-row-radio-button',
        table: this,
        align: 'center',
        groupValue: this.selectedContacto,
        onSelectRow: this.actions.selectContacto.bind(this)
      }, {
        label: 'Nombre',
        valuePath: 'nombre',
        sortable: false,
        width: '150px'
      }, {
        label: 'Teléfono',
        valuePath: 'telefono',
        sortable: false,
        width: '100px'
      }, {
        label: 'Correo electrónico',
        valuePath: 'correo',
        sortable: false,
        width: '150px'
      }, {
        label: 'Puesto',
        valuePath: 'puesto',
        sortable: false,
        width: '100px'
      }]);
    }),
    columnsDatos: Ember.computed(function () {
      return Ember.A([{
        label: '',
        sortable: false,
        width: '60px',
        cellComponent: 'tables/row/select-row-radio-button',
        table: this,
        align: 'center',
        groupValue: this.selectedDatoFacturacion,
        onSelectRow: this.actions.selectDatoFacturacion.bind(this)
      }, {
        label: 'NIT',
        valuePath: 'nit',
        sortable: false,
        width: '100px'
      }, {
        label: 'Razón social',
        valuePath: 'razonSocial',
        sortable: false,
        width: '150px'
      }, {
        label: 'Dirección fiscal',
        valuePath: 'direccionFiscal',
        sortable: false,
        width: '100px'
      }]);
    }),
    actions: {
      onClienteChanged() {
        if (!this.selectedCliente) {
          Ember.set(this.model, 'contactos', Ember.A([]));
          this.set('selectedContacto', null);
          this.set('selectedDatoFacturacion', null);
          return;
        }

        return Ember.RSVP.hash({
          contactos: this.store.query('cliente-contacto', {
            filter: {
              cliente: this.selectedCliente.get('id')
            }
          }),
          datosFacturacion: this.store.query('cliente-dato-facturacion', {
            filter: {
              cliente: this.selectedCliente.get('id')
            }
          })
        }).then(({
          contactos,
          datosFacturacion
        }) => {
          Ember.set(this.model, 'contactos', contactos);
          Ember.set(this.model, 'datosFacturacion', datosFacturacion);
        });
      },

      selectContacto(selectedContacto) {
        this.set('selectedContacto', selectedContacto);
      },

      selectDatoFacturacion(selectedDatoFacturacion) {
        this.set('selectedDatoFacturacion', selectedDatoFacturacion);
      },

      crearProyecto() {
        let contactoSelectedId = null;
        let datoFacturacionSelectedId = null;

        if (!this.selectedCliente) {
          this.toast.error('Debe seleccionar un cliente para poder crear un proyecto.');
          return;
        }

        if ((0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'OPTIMUM')) {
          if (!this.selectedContacto) {
            this.toast.error('Debe seleccionar un contacto para poder crear un proyecto.');
            return;
          } else {
            contactoSelectedId = this.selectedContacto.id;
          }

          if (!this.selectedDatoFacturacion) {
            this.toast.error('Debe seleccionar un dato de facturación para poder crear un proyecto.');
            return;
          } else {
            datoFacturacionSelectedId = this.selectedDatoFacturacion.id;
          }
        } else {
          if (this.selectedContacto) {
            contactoSelectedId = this.selectedContacto.id;
          }

          if (this.selectedDatoFacturacion) {
            datoFacturacionSelectedId = this.selectedDatoFacturacion.id;
          }
        }

        if (!this.selectedServicio) {
          this.toast.error('Debe seleccionar un servicio para poder crear un proyecto.');
          return;
        }

        this.transitionToRoute('proyecto.record', 'new', {
          queryParams: {
            cliente: this.selectedCliente.id,
            clienteContacto: contactoSelectedId,
            clienteDatoFacturacion: datoFacturacionSelectedId,
            servicioId: this.selectedServicio.id,
            estatus: this.constants.PROJECT_STATUS_ACTIVE
          }
        });
      },

      searchClients(text) {
        if (!text || text.length < 3) {
          return;
        }

        if (this.contactsQuery) {
          Ember.run.cancel(this.contactsQuery);
        }

        this.contactsQuery = Ember.run.later(() => {
          this.set('availableClients', this.store.query('cliente', {
            filter: {
              codigoNombre: text
            }
          }));
        }, 500);
      }

    }
  });

  _exports.default = _default;
});