define("ods-app/models/entrevista", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    fecha: attr('custom-date', {
      defaultValue() {
        return new Date();
      }

    }),
    estatus: attr('string'),
    tipo: attr('string'),
    aplicacion: belongsTo('aplicacion'),
    requerimiento: belongsTo('requerimiento'),
    observaciones: attr('string'),
    statusToString: Ember.computed('estatus', function () {
      const status = this.constants.INTERVIEW_STATUS.findBy('key', this.estatus);
      return status ? status.name : this.status;
    }),
    tipoToString: Ember.computed('tipo', function () {
      const tipo = this.constants.INTERVIEW_TYPE.findBy('key', this.tipo);
      return tipo ? tipo.name : this.tipo;
    }),
    fechaFormatted: Ember.computed('fecha', function () {
      const momentDate = (0, _moment.default)(this.fecha);

      if (Ember.isNone(this.fecha) || !momentDate.isValid()) {
        return '';
      }

      return momentDate.format('DD/MM/YYYY hh:mm a');
    })
  });

  _exports.default = _default;
});