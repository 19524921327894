define("ods-app/mirage/fixtures/empleados", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    nombre: 'Mario Arturo Salazar',
    proyecto: 'CEMPRO',
    plaza: 'Secretaria',
    telefono: '52365152',
    fechaIngreso: '2020-03-03'
  }];
  _exports.default = _default;
});