define("ods-app/controllers/reporte-usuarios", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { computed } from '@ember/object';
  // import moment from 'moment';
  var _default = Ember.Controller.extend({
    getSerializedFilters() {
      return {
        usuarios: this.changeset.get('usuarios').map(p => p.id) || []
      };
    }

  });

  _exports.default = _default;
});