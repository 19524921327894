define("ods-app/controllers/cliente/record/perfiles-puestos/record/competencias/record", ["exports", "servir-ember-utilities/controllers/abstract-module-record"], function (_exports, _abstractModuleRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    indexCtrl: Ember.inject.controller('cliente.record.perfiles-puestos.record.competencias'),
    indexRoute: 'cliente.record.perfiles-puestos.record.competencias',
    postSaveTransitionRoute: Ember.computed.readOnly('indexRoute'),

    postSave(record) {
      if (this.isNew) {
        if (record.get('tipo') === this.constants.COMPETENCE_TYPE_TECNICA) {
          Ember.set(this.indexCtrl.model, 'competenciasTecnicas', Ember.A([...this.indexCtrl.model.competenciasTecnicas, record]));
        } else if (record.get('tipo') === this.constants.COMPETENCE_TYPE_LIDERAZGO) {
          Ember.set(this.indexCtrl.model, 'competenciasLiderazgo', Ember.A([...this.indexCtrl.model.competenciasLiderazgo, record]));
        }
      }
    },

    actions: {
      discardChanges() {
        if (this.changeset.get('isDirty') || this.record.get('isNew')) {
          this.record.rollbackAttributes();
        }

        this.transitionToRoute(this.get('indexRoute'));
      }

    }
  });

  _exports.default = _default;
});