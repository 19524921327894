define("ods-app/components/app-navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    user: Ember.computed.readOnly('currentUser.user'),
    topNavigation: null,
    navigation: '',
    isTribal: false,

    init() {
      this._super(...arguments);

      this.set('isTribal', window.location.hostname.includes('tribal'));
    },

    actions: {
      changeNavigation(codigoMenuItem) {
        this.set('topNavigation', codigoMenuItem);
      },

      invalidateSession() {
        this.session.invalidate();
      }

    }
  });

  _exports.default = _default;
});