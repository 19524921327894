define("ods-app/controllers/proyecto/record/empleado/cargos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    indexRoute: 'proyecto.record.empleado',
    cargo: '',
    actions: {
      discardChanges() {
        this.transitionToRoute('proyecto.record.empleado', this.proyecto.get('id'));
      }

    }
  });

  _exports.default = _default;
});