define("ods-app/controllers/requerimiento/record/prueba-tecnica", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "ods-app/models/aplicacion", "ods-app/helpers/is-empresa"], function (_exports, _abstractModuleIndex, _aplicacion, _isEmpresa) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    modelClass: _aplicacion.default,
    editRoute: 'aplicacion.record',
    reload: true,
    currentUser: Ember.inject.service(),
    modelSortingProperties: Ember.computed(function () {
      return Ember.A([{
        propertyName: 'id'
      }]);
    }),
    modelFilters: Ember.computed('record', function () {
      return [{
        selectedFilter: {
          filterName: 'requerimiento'
        },
        value: this.get('record.id') || -1
      }, {
        selectedFilter: {
          filterName: 'custom.estatusHistorico'
        },
        value: this.constants.APPLICATION_STATUS_PRUEBAS_TECNICAS
      }];
    }),
    columns: Ember.computed(function () {
      let currentStatus = [this.constants.APPLICATION_STATUS_PRUEBAS_TECNICAS, this.constants.APPLICATION_STATUS_RECHAZADO_PRUEBAS_TECNICAS];
      let actions = {
        edit: {
          title: 'Editar',
          buttonClass: 'btn-primary',
          icon: 'pencil-square-o',
          action: this.actions.edit.bind(this)
        }
      };

      if ((0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'SERVINOVA')) {
        actions.entrevista = {
          title: 'Enviar a entrevista',
          buttonClass: 'btn-warning',
          icon: 'arrow-right',
          action: this.actions.enviarAplicacionAEntrevista.bind(this)
        };
        actions.presentacion = {
          title: 'Enviar a presentación',
          buttonClass: 'btn-secondary',
          icon: 'user',
          action: this.actions.mandarAPresentacion.bind(this)
        };
      }

      actions.enviarATerna = {
        title: 'Enviar a terna activa',
        buttonClass: 'btn-success',
        icon: 'share',
        action: this.actions.asignarAplicacionATernaActiva.bind(this)
      };
      actions.rechazar = {
        title: 'Rechazar aplicación',
        buttonClass: 'btn-danger',
        icon: 'times',
        action: this.actions.rechazarAplicacion.bind(this)
      };
      return Ember.A([{
        label: 'Código',
        valuePath: 'codigo',
        sortable: true,
        width: '100px',
        filtrable: true,
        filterName: 'codigo',
        currentStatus: currentStatus,
        cellComponent: 'requerimiento-aplicacion-cell'
      }, {
        label: 'Candidato',
        valuePath: 'candidato.nombreCompleto',
        sortable: true,
        sortingPropertyName: 'candidato.primerNombre',
        width: '100px',
        filtrable: true,
        filterName: 'candidato.primerNombre',
        currentStatus: currentStatus,
        cellComponent: 'requerimiento-aplicacion-cell'
      }, {
        label: 'Ocupación',
        valuePath: 'candidato.ocupacion',
        sortable: true,
        width: '100px',
        currentStatus: currentStatus,
        cellComponent: 'requerimiento-aplicacion-cell'
      }, {
        label: 'Expectativa salarial',
        valuePath: 'expectativaSalarialFormatted',
        sortable: false,
        width: '100px',
        align: 'right',
        filtrable: true,
        filterName: 'expectativaSalarial',
        currentStatus: currentStatus,
        cellComponent: 'requerimiento-aplicacion-cell'
      }, {
        label: 'Estatus',
        valuePath: 'statusToString',
        sortable: true,
        sortingPropertyName: 'estatus',
        width: '100px',
        filtrable: true,
        filterName: 'estatus',
        currentStatus: currentStatus,
        cellComponent: 'requerimiento-aplicacion-cell'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        width: '100px',
        table: this,
        sortable: false,
        objectActions: actions
      }]);
    }),

    reloadModel() {
      this.set('reload', true);
    },

    actions: {
      asignarAplicacionATernaActiva(aplicacion) {
        let controller = this;

        if (controller.changeset.get('estaDeshabilitado')) {
          this.toast.error('El requerimiento está deshabilitado');
          return;
        } else if (controller.get('record.ternas.length') == 0) {
          this.toast.error('No hay ninguna terna asociada al requerimiento.');
          return;
        } else if (aplicacion.estatus == this.constants.APPLICATION_STATUS_RECHAZADO || aplicacion.estatus == this.constants.APPLICATION_STATUS_RECHAZADO_EN_ENTREVISTA || aplicacion.estatus == this.constants.APPLICATION_STATUS_RECHAZADO_EN_PRUEBAS || aplicacion.estatus == this.constants.APPLICATION_STATUS_RECHAZADO_PRESENTACION || aplicacion.estatus == this.constants.APPLICATION_STATUS_RECHAZADO_PRUEBAS_TECNICAS) {
          this.toast.error('Esta aplicación está rechazada, no puede realizar ningún cambio.');
          return;
        }

        let ternaActiva = controller.get('record.ternas').filterBy('activo').get('firstObject');

        if (!ternaActiva) {
          this.toast.error('Todavía no se ha iniciado el proceso de reclutamiento.');
          return;
        }

        if (ternaActiva.get('aceptada')) {
          this.toast.error('la terna ya ha sido aceptada, no puede realizar ningún cambio.');
          return;
        }

        aplicacion.set('terna', ternaActiva);
        aplicacion.set('estatus', this.constants.APPLICATION_STATUS_EN_TERNA);
        aplicacion.save().then(() => {
          this.reloadModel();
          this.toast.success('Aplicación agregada a terna activa');
        });
      },

      enviarAplicacionAEntrevista(aplicacion) {
        let controller = this;

        if (controller.changeset.get('estaDeshabilitado')) {
          this.toast.error('El requerimiento está deshabilitado');
          return;
        }

        if (aplicacion.estatus == this.constants.APPLICATION_STATUS_RECHAZADO || aplicacion.estatus == this.constants.APPLICATION_STATUS_RECHAZADO_EN_ENTREVISTA || aplicacion.estatus == this.constants.APPLICATION_STATUS_RECHAZADO_EN_PRUEBAS || aplicacion.estatus == this.constants.APPLICATION_STATUS_RECHAZADO_PRESENTACION || aplicacion.estatus == this.constants.APPLICATION_STATUS_RECHAZADO_PRUEBAS_TECNICAS) {
          this.toast.error('Esta aplicación está rechazada, no puede realizar ningún cambio.');
          return;
        }

        let ternaActiva = controller.get('record.ternas').filterBy('activo').get('firstObject');

        if (!ternaActiva) {
          this.toast.error('Todavía no se ha iniciado el proceso de reclutamiento.');
          return;
        }

        if (ternaActiva.get('aceptada')) {
          this.toast.error('la terna ya ha sido aceptada, no puede realizar ningún cambio.');
          return;
        }

        aplicacion.set('estatus', this.constants.APPLICATION_STATUS_EN_ENTREVISTA);
        aplicacion.save().then(() => {
          this.reloadModel();
          this.toast.success('Aplicación enviada a entrevistas');
        });
      },

      mandarAPresentacion(aplicacion) {
        let controller = this;

        if (aplicacion.get('terna.content')) {
          this.toast.error('La aplicacion ya pertenece a una terna');
          return;
        } else if (controller.changeset.get('estaDeshabilitado')) {
          this.toast.error('El requerimiento está deshabilitado');
          return;
        } else if (aplicacion.get('estatus') == this.constants.APPLICATION_STATUS_CONTRATADO) {
          this.toast.error('La aplicación ya ha sido contratada');
          return;
        } else if (aplicacion.get('estatus') == this.constants.APPLICATION_STATUS_COMPLETADO) {
          this.toast.error('La aplicación ya ha sido completada');
          return;
        } else if (aplicacion.get('estatus') == this.constants.APPLICATION_STATUS_ENVIADO_REQUERIMIENTO) {
          this.toast.error('La aplicación pertenece a otro requerimiento');
          return;
        } else if (aplicacion.estatus == this.constants.APPLICATION_STATUS_RECHAZADO_EN_ENTREVISTA || aplicacion.estatus == this.constants.APPLICATION_STATUS_RECHAZADO || aplicacion.estatus == this.constants.APPLICATION_STATUS_RECHAZADO_EN_PRUEBAS || aplicacion.estatus == this.constants.APPLICATION_STATUS_RECHAZADO_PRESENTACION || aplicacion.estatus == this.constants.APPLICATION_STATUS_RECHAZADO_PRUEBAS_TECNICAS) {
          this.toast.error('Esta aplicación está rechazada, no puede realizar ningún cambio.');
          return;
        }

        aplicacion.set('estatus', this.constants.APPLICATION_STATUS_PRESENTACION);
        aplicacion.save().then(() => {
          this.reloadModel();
          this.toast.success('Aplicación enviada a presentación');
        });
      },

      rechazarAplicacion(aplicacion) {
        let controller = this;

        if (controller.changeset.get('estaDeshabilitado')) {
          this.toast.error('El requerimiento está deshabilitado');
          return;
        } else if (aplicacion.estatus == this.constants.APPLICATION_STATUS_RECHAZADO || aplicacion.estatus == this.constants.APPLICATION_STATUS_RECHAZADO_EN_ENTREVISTA || aplicacion.estatus == this.constants.APPLICATION_STATUS_RECHAZADO_EN_PRUEBAS || aplicacion.estatus == this.constants.APPLICATION_STATUS_RECHAZADO_PRESENTACION || aplicacion.estatus == this.constants.APPLICATION_STATUS_RECHAZADO_PRUEBAS_TECNICAS) {
          this.toast.error('Esta aplicación está rechazada, no puede realizar ningún cambio.');
          return;
        }

        this.send('openModal', 'popups/enviar-lista-negra', {
          title: 'Rechazar aplicación',
          message: '¿Desea enviar al candidato a lista negra?',
          buttonActions: [{
            title: 'Enviar',
            methodName: 'enviarAListaNegra',
            buttonType: 'warning'
          }, {
            title: 'No enviar',
            methodName: 'noEnviarAListaNegra',
            buttonType: 'submit'
          }],
          enviarAListaNegra: razonListaNegra => {
            aplicacion.set('estatus', this.constants.APPLICATION_STATUS_RECHAZADO_PRUEBAS_TECNICAS);
            aplicacion.save().then(() => aplicacion.get('candidato')).then(candidato => {
              candidato.set('listaNegra', true);
              candidato.set('razonListaNegra', razonListaNegra);
              candidato.save().then(() => {
                this.reloadModel();
                this.toast.success('Aplicación rechazada');
              });
            });
          },
          noEnviarAListaNegra: () => {
            aplicacion.set('estatus', this.constants.APPLICATION_STATUS_RECHAZADO_PRUEBAS_TECNICAS);
            aplicacion.save().then(() => {
              this.reloadModel();
              this.toast.success('Aplicación rechazada');
            });
          }
        });
      }

    }
  });

  _exports.default = _default;
});