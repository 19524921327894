define("ods-app/templates/reporte-usuarios", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DkqqGbry",
    "block": "{\"symbols\":[\"f\",\"user\"],\"statements\":[[6,\"div\"],[10,\"class\",\"page-header\"],[8],[0,\"\\n  \"],[6,\"h1\"],[8],[0,\"\\n    Reporte de usuarios\\n    \"],[6,\"div\"],[10,\"class\",\"pull-right\"],[8],[0,\"\\n      \"],[6,\"a\"],[10,\"href\",\"#\"],[10,\"class\",\"btn btn-success btn-xs\"],[3,\"action\",[[21,0,[]],\"generarReporte\"]],[8],[0,\"\\n        \"],[6,\"i\"],[10,\"class\",\"fa fa-download\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n        Descargar\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[6,\"div\"],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"col-xs-12 col-lg-6\"],[8],[0,\"\\n      \"],[6,\"div\"],[10,\"class\",\"widget-box\"],[8],[0,\"\\n        \"],[6,\"div\"],[10,\"class\",\"widget-header\"],[8],[0,\"\\n          \"],[6,\"h4\"],[10,\"class\",\"widget-title\"],[8],[0,\"Filtros\"],[9],[0,\"\\n        \"],[9],[0,\"\\n\\n        \"],[6,\"div\"],[10,\"class\",\"widget-body\"],[8],[0,\"\\n          \"],[6,\"div\"],[10,\"class\",\"widget-main\"],[8],[0,\"\\n\"],[4,\"extended-form-for\",[[22,[\"changeset\"]]],null,{\"statements\":[[4,\"component\",[[21,1,[\"ace-power-select-multiple\"]],\"usuarios\",\"Usuarios:\"],[[\"selected\",\"options\",\"placeholder\",\"searchField\",\"onchange\"],[[22,[\"changeset\",\"usuarios\"]],[22,[\"model\",\"users\"]],\"Seleccione...\",\"nombreCompleto\",[26,\"action\",[[21,0,[]],[26,\"mut\",[[22,[\"changeset\",\"usuarios\"]]],null]],null]]],{\"statements\":[[0,\"                \"],[1,[21,2,[\"nombreCompleto\"]],false],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null],[0,\"          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ods-app/templates/reporte-usuarios.hbs"
    }
  });

  _exports.default = _default;
});