define("ods-app/controllers/proyecto/record/documentos", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "ods-app/models/proyecto-archivo", "ods-app/mixins/upload-helper"], function (_exports, _abstractModuleIndex, _proyectoArchivo, _uploadHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend(_uploadHelper.default, {
    modelClass: _proyectoArchivo.default,
    editRoute: 'proyecto.record.documentos.record',
    reload: true,
    loader: Ember.inject.service(),
    ajaxService: Ember.inject.service(),
    modelFilters: Ember.computed('record', function () {
      return [{
        selectedFilter: {
          filterName: 'proyecto'
        },
        value: this.get('record.id') || -1
      }];
    }),
    columns: Ember.computed(function () {
      return Ember.A([{
        label: 'Nombre',
        valuePath: 'nombre',
        sortable: false,
        width: '150px'
      }, {
        label: 'Observaciones',
        valuePath: 'observaciones',
        sortable: false,
        width: '150px'
      }, {
        label: 'Fecha',
        valuePath: 'createdAtFormatted',
        sortable: false,
        width: '50px'
      }, {
        label: 'Usuario',
        valuePath: 'createdBy',
        sortable: true,
        width: '50px'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        sortable: false,
        width: '60px',
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.edit.bind(this)
          },
          descargarDocumento: {
            title: 'Descargar documento',
            buttonClass: 'btn-success',
            icon: 'download',
            action: this.downloadFile.bind(this)
          },
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.delete.bind(this)
          }
        }
      }]);
    }),

    reloadModel() {
      this.set('reload', true);
    }

  });

  _exports.default = _default;
});