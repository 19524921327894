define("ods-app/templates/aplicacion/record/entrevista", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "51ylaQL5",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[6,\"div\"],[10,\"class\",\"pull-right\"],[8],[0,\"\\n\"],[4,\"link-to\",[\"aplicacion.record.entrevista.record\",\"new\"],[[\"class\"],[\"btn btn-success btn-xs\"]],{\"statements\":[[0,\"    \"],[6,\"i\"],[10,\"class\",\"fa fa-plus-circle\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n    Nueva entrevista\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\\n\"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[6,\"div\"],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n    \"],[1,[26,\"tables/paginated-table\",null,[[\"columns\",\"modelName\",\"modelClassObject\",\"modelFilters\",\"load\"],[[22,[\"columns\"]],\"entrevista\",[22,[\"modelClass\"]],[22,[\"modelFilters\"]],[22,[\"reload\"]]]]],false],[0,\"\\n\\n  \"],[6,\"br\"],[8],[9],[0,\"\\n\\n\"],[4,\"extended-form-for\",[[22,[\"changeset\"]]],null,{\"statements\":[[0,\"      \"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n        \"],[6,\"div\"],[10,\"class\",\"col-xs-6\"],[8],[0,\"\\n          \"],[1,[26,\"component\",[[21,1,[\"ace-textarea-field\"]],\"observacionesEntrevista\",\"Observaciones de la entrevista:\"],[[\"placeholder\"],[\"Observaciones entrevista\"]]],false],[0,\"\\n\\n\"],[0,\"        \"],[9],[0,\"\\n        \"],[6,\"div\"],[10,\"class\",\"col-xs-6\"],[8],[0,\"\\n          \"],[1,[26,\"component\",[[21,1,[\"ace-textarea-field\"]],\"observacionesRechazo\",\"Observaciones de rechazo:\"],[[\"placeholder\"],[\"Observaciones rechazo\"]]],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[20,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ods-app/templates/aplicacion/record/entrevista.hbs"
    }
  });

  _exports.default = _default;
});