define("ods-app/routes/proyecto/record/empleado/cargos/agregar", ["exports", "servir-ember-utilities/routes/abstract-module-record", "ember-changeset-validations/validators"], function (_exports, _abstractModuleRecord, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    modelName: 'proyecto-candidato-planilla-cargo',
    indexRoute: 'proyecto.record.empleado',
    validateOnChange: false,
    routeRecordIdentifierDynamicSegment: 'cargo_id',
    proyectoCandidatoPlaceHolderId: '',
    candidatoId: '',
    validations: Ember.computed(function () {
      return {
        proyectoCandidato: (0, _validators.validatePresence)({
          presence: true
        }),
        planillaCargo: (0, _validators.validatePresence)({
          presence: true
        }),
        monto: (0, _validators.validateNumber)({
          gt: 0
        })
      };
    }),
    relatedModelsToQuery: Ember.computed('proyectoCandidatoPlaceHolderId', function () {
      let relatedModels = [];
      relatedModels.push({
        name: 'cargosPlanilla',
        modelName: 'planilla-cargo'
      }, {
        name: 'currentCargosPlanilla',
        modelName: 'proyecto-candidato-planilla-cargo',
        queryParams: {
          filter: {
            'proyectoCandidato.candidato': this.candidatoId
          },
          include: 'planillaCargo'
        }
      });

      if (this.get('proyectoCandidatoPlaceHolderId')) {
        relatedModels.push({
          name: 'proyectoCandidatosPlaceHolder',
          modelName: 'proyecto-candidato',
          queryParams: {
            filter: {
              id: this.get('proyectoCandidatoPlaceHolderId')
            }
          }
        });
      }

      return relatedModels;
    }),

    renderTemplate(controller, model) {
      this._super(controller, model);

      this.render('proyecto/record/empleado/cargos/cargo-submit-button', {
        outlet: 'submit-button'
      });
    },

    createRecordInstance(params, transition) {
      const modelName = this.get('modelName');

      if (!Ember.isNone(transition.queryParams.proyectoCandidatoId)) {
        this.set('proyectoCandidatoPlaceHolderId', transition.queryParams.proyectoCandidatoId);
      } else {
        this.transitionTo('proyecto.record.empleado');
      }

      if (!Ember.isNone(transition.queryParams.candidatoId)) {
        this.set('candidatoId', transition.queryParams.candidatoId);
      }

      return this.store.createRecord(modelName, {
        activo: 1
      });
    },

    setupController(controller, model) {
      this._super(controller, model);

      const proyecto = this.modelFor('proyecto.record').record;
      controller.set('proyecto', proyecto);

      if (!Ember.isNone(controller.get('proyectoCandidatosPlaceHolder'))) {
        let proyectoCandidato = controller.get('proyectoCandidatosPlaceHolder.firstObject');
        controller.set('proyectoCandidato', proyectoCandidato);
      }
    },

    actions: {
      willTransition() {
        return false;
      }

    }
  });

  _exports.default = _default;
});