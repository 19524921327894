define("ods-app/utils/app-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    isTruthhy(value) {
      return value === true || value === 1 || value === 'true' || value === '1' || value === 'yes';
    }

  };
  _exports.default = _default;
});