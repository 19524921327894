define("ods-app/templates/role/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "d4qklB/A",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",\"page-header\"],[8],[0,\"\\n  \"],[6,\"h1\"],[8],[0,\"\\n    Roles\\n    \"],[6,\"div\"],[10,\"class\",\"pull-right\"],[8],[0,\"\\n\"],[4,\"link-to\",[\"role.record\",\"new\"],[[\"classNames\"],[\"btn btn-success btn-xs\"]],{\"statements\":[[0,\"        \"],[6,\"i\"],[10,\"class\",\"fa fa-plus-circle\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n        Crear\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[6,\"div\"],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n    \"],[1,[26,\"tables/paginated-table\",null,[[\"model\",\"columns\",\"modelName\",\"modelClassObject\"],[[22,[\"model\"]],[22,[\"columns\"]],[22,[\"modelName\"]],[22,[\"modelClass\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ods-app/templates/role/index.hbs"
    }
  });

  _exports.default = _default;
});