define("ods-app/services/dates-calculator", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajaxService: Ember.inject.service(),
    loader: Ember.inject.service(),

    /**
     * Array de string dates con días festivos a considerar durante cálculo de días
     * hábiles
     */
    diasFestivos: Ember.computed(function () {
      return [];
    }),

    /**
     * Retorna un momentjs instance con la fecha resultante de agregar los días hábiles
     * a la fecha inicial, tomando en cuenta fines de semana y un array de días
     * festivos alimentados al servicio
     */
    getFechaLuegoDeDiasHabiles(fechaInicial, diasHabiles) {
      let currentDate = (0, _moment.default)(fechaInicial);
      var count = 0;
      var testDate;

      while (count < diasHabiles) {
        // Si la fecha resultante de agregar un día es fin de semana, entonces no
        // contar como día hábil
        testDate = (0, _moment.default)(currentDate).add(1, 'days');

        if (testDate.weekday() === 5 || testDate.weekday() === 6) {
          currentDate.add(1, 'days');
          continue;
        } // Si la fecha resultante de agregar un día es un día festivo entonces no
        // contar como día hábil


        if (Ember.A(this.get('diasFestivos')).includes(testDate.format('YYYY-MM-DD'))) {
          currentDate.add(1, 'days');
          continue;
        }

        currentDate.add(1, 'days');
        count += 1;
      }

      return currentDate;
    },

    getCantidadDiasHabiles(fechaInicial, fechaFinal) {
      var count = 0;
      let currentDate = (0, _moment.default)((0, _moment.default)(fechaInicial).startOf('day'));
      let fechaFinalLocal = (0, _moment.default)((0, _moment.default)(fechaFinal).startOf('day'));

      if (currentDate < fechaFinalLocal) {
        while (currentDate < fechaFinalLocal) {
          // Si la fecha actual es fin de semana, seguir adelante y no sumar
          if (currentDate.weekday() === 5 || currentDate.weekday() === 6) {
            currentDate.add(1, 'days');
            continue;
          } // Si la fecha actual es día festivo, seguir adelante y no sumar


          if (Ember.A(this.get('diasFestivos')).includes(currentDate.format('YYYY-MM-DD'))) {
            currentDate.add(1, 'days');
            continue;
          }

          count += 1;
          currentDate.add(1, 'days');
        }
      } else if (fechaFinalLocal < currentDate) {
        return this.getCantidadDiasHabiles(fechaFinalLocal, currentDate) * -1;
      }

      return count;
    },

    load() {
      this.loader.setIsLoading();
      return this.get('ajaxService').request('/factorh-dias-festivos').then(({
        data: diasFestivos
      }) => {
        let fechas = Ember.A(diasFestivos).map(diaFestivo => diaFestivo.fecha);
        this.set('diasFestivos', fechas);
      }).catch(error => {
        this.toast.error('Ocurrió un error al obtener la lista de departamentos.');
        this.transitionTo(this.get('indexRoute'));
        throw error;
      }).finally(() => this.get('loader').setNotLoading());
    }

  });

  _exports.default = _default;
});