define("ods-app/routes/login", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    activate() {
      Ember.$('body').removeClass('no-skin').addClass('login-layout blur-login');
    },

    deactivate() {
      Ember.$('body').addClass('no-skin').removeClass('login-layout blur-login');
    },

    renderTemplate() {
      this.render({
        outlet: 'login'
      });
    }

  });

  _exports.default = _default;
});