define("ods-app/routes/requerimiento/record/aplicacion/asociar-aplicacion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params, transition) {
      const requerimiento = this.modelFor('requerimiento.record').record;
      return requerimiento.get('proyecto').then(proyecto => {
        let clienteId = proyecto.belongsTo('cliente').id();
        let queries = {
          requirements: this.store.query('requerimiento', {
            filter: {
              'proyecto.cliente.id': clienteId,
              'estatus': {
                '!exact': this.constants.REQUIREMENT_STATUS_CONTRATADO
              },
              'codigo': {
                '!exact': requerimiento.get('codigo')
              }
            }
          })
        };

        if (!Ember.isNone(transition.queryParams.aplicacionId)) {
          const aplicacionId = transition.queryParams.aplicacionId;
          let queryAplicacion = {
            aplicacion: this.store.query('aplicacion', {
              filter: {
                id: aplicacionId
              }
            })
          };
          queries = Object.assign(queries, queryAplicacion);
        }

        return Ember.RSVP.hash(queries);
      });
    },

    setupController(controller, model) {
      this._super(controller, model);

      const requerimiento = this.modelFor('requerimiento.record').record;
      controller.set('requerimiento', requerimiento);

      if (!Ember.isNone(model.aplicacion)) {
        let aplicacion = model.aplicacion.get('firstObject');
        controller.set('aplicacion', aplicacion);
      }
    }

  });

  _exports.default = _default;
});