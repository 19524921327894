define("ods-app/controllers/candidato/record/experiencia-laboral/record", ["exports", "servir-ember-utilities/controllers/abstract-module-record"], function (_exports, _abstractModuleRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    indexCtrl: Ember.inject.controller('candidato.record.experiencia-laboral'),
    indexRoute: 'candidato.record.experiencia-laboral',
    postSaveTransitionRoute: Ember.computed.readOnly('indexRoute'),

    postSave() {
      this.indexCtrl.reloadModel();
    },

    actions: {
      discardChanges() {
        if (this.changeset.get('isDirty') || this.record.get('isNew')) {
          this.record.rollbackAttributes();
        }

        this.transitionToRoute(this.get('indexRoute'));
      }

    }
  });

  _exports.default = _default;
});