define("ods-app/templates/components/validate-link-to", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ej/CHAqJ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[22,[\"hasPermission\"]]],null,{\"statements\":[[4,\"link-to\",[[22,[\"route\"]],[22,[\"parameter\"]]],null,{\"statements\":[[0,\"    \"],[1,[20,\"title\"],false],[0,\" \"],[1,[20,\"text\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[20,\"title\"],false],[0,\" \"],[1,[20,\"text\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ods-app/templates/components/validate-link-to.hbs"
    }
  });

  _exports.default = _default;
});