define("ods-app/controllers/reset-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    loader: Ember.inject.service(),
    toast: Ember.inject.service(),
    session: Ember.inject.service(),
    actions: {
      resetPassword() {
        return this.changeset.validate().then(() => {
          if (!this.changeset.get('isValid')) {
            return;
          }

          this.changeset.set('passwordResetted', true);
          this.loader.setIsLoading();
          return this.changeset.save().then(() => {
            this.toast.success('Cambios guardados correctamente. Vuelva a iniciar sesión.');
            Ember.run.later(() => this.session.invalidate());
          }).catch(error => {
            this.toast.errror('Error al guardar sus cambios. Contacte con el administrador.');
            throw error;
          }).finally(() => this.loader.setNotLoading());
        });
      }

    }
  });

  _exports.default = _default;
});