define("ods-app/controllers/requerimiento/record/informacion-compensacion/planilla-cargo", ["exports", "servir-ember-utilities/controllers/abstract-module-record"], function (_exports, _abstractModuleRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    indexCtrl: Ember.inject.controller('requerimiento.record.informacion-compensacion'),
    indexRoute: 'requerimiento.record.informacion-compensacion',
    postSaveTransitionRoute: Ember.computed.readOnly('indexRoute'),

    postSave(record) {
      if (this.isNew) {
        // Es necesario usar el setter (en vez de hacer un push) para que se actualice la tabla.
        this.indexCtrl.set('planillaCargos', this.indexCtrl.get('planillaCargos').concat(record));
      }
    },

    actions: {
      planillaCargoUpdated() {
        if (this.changeset.get('planillaCargo.id')) {
          this.changeset.set('monto', this.changeset.get('planillaCargo').get('montoDefault'));
        }
      },

      discardChanges() {
        if (this.changeset.get('isDirty') || this.record.get('isNew')) {
          this.record.rollbackAttributes();
        }

        this.transitionToRoute(this.indexRoute);
      }

    }
  });

  _exports.default = _default;
});