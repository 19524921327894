define("ods-app/mixins/ods-app-file-saver", ["exports", "ember-cli-file-saver/mixins/file-saver"], function (_exports, _fileSaver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_fileSaver.default, {
    ajaxService: Ember.inject.service(),
    config: Ember.inject.service(),
    loader: Ember.inject.service(),
    host: Ember.computed.alias('config.API.host'),

    /**
     * This method performs the saving of a file but does not require the fileName
     * or the contentType since it parses those values from the response headers.
     *
     * @param  {[type]} content    [description]
     * @param  {[type]} xhrRequest [description]
     * @return {[type]}            [description]
     */
    saveAttachmentFile(content, xhrRequest) {
      let disposition = xhrRequest.getResponseHeader('Content-Disposition');

      if (!disposition) {
        throw new Error('Got no Content-Disposition header');
      }

      let contentType = xhrRequest.getResponseHeader('Content-Type');

      if (!contentType) {
        throw new Error('Got no Content-Type header');
      }

      let fileName = '';

      if (disposition && disposition.indexOf('attachment') !== -1) {
        let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        let matches = filenameRegex.exec(disposition);

        if (matches != null && matches[1]) {
          fileName = matches[1].replace(/['"]/g, '');
        }
      }

      if (!fileName) {
        throw new Error('No filename got from Content-Disposition header');
      }

      this.saveFileAs(fileName, content, contentType);
    },

    getFileFromServer(url, payload = {}) {
      let ajaxPromise = this.get('ajaxService').request(url, {
        host: this.get('host'),
        dataType: 'arraybuffer',
        data: payload
      });
      this.toast.info('Generando reporte...');
      this.loader.setIsLoading();
      return ajaxPromise.then(content => {
        this.saveAttachmentFile(content, ajaxPromise.xhr);
        this.toast.success('Reporte generado exitosamente');
      }).catch(error => {
        let errorMsg = 'Ocurrió un error';

        if (error && error.message) {
          errorMsg = error.message;
        }

        if (error && error.payload && Array.isArray(error.payload.errors)) {
          errorMsg = error.payload.errors.map(e => e.message).compact().join(', ');
        }

        this.toast.error(errorMsg);
      }).finally(() => this.loader.setNotLoading());
    }

  });

  _exports.default = _default;
});