define("ods-app/templates/pais/departamento", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uM8oS5As",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",\"page-header\"],[8],[0,\"\\n  \"],[6,\"h1\"],[8],[0,\"\\n    \"],[1,[22,[\"pais\",\"nombre\"]],false],[0,\" \"],[6,\"small\"],[8],[0,\"Departametos\"],[9],[0,\"\\n\\n    \"],[6,\"div\"],[10,\"class\",\"pull-right\"],[8],[0,\"\\n\"],[4,\"link-to\",[\"pais.departamento.record\",\"new\"],[[\"classNames\"],[\"btn btn-success btn-xs\"]],{\"statements\":[[0,\"        \"],[6,\"i\"],[10,\"class\",\"fa fa-plus-circle\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n        Crear\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[6,\"div\"],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n    \"],[1,[26,\"tables/paginated-table\",null,[[\"model\",\"columns\",\"modelName\",\"modelClassObject\",\"modelFilters\",\"load\"],[[22,[\"model\"]],[22,[\"columns\"]],[22,[\"modelName\"]],[22,[\"modelClass\"]],[22,[\"modelFilters\"]],[22,[\"reload\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[20,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ods-app/templates/pais/departamento.hbs"
    }
  });

  _exports.default = _default;
});