define("ods-app/routes/reporte-usuarios", ["exports", "servir-ember-utilities/routes/abstract-module-index", "ods-app/mixins/permission-validator", "ods-app/mixins/ods-app-file-saver", "ember-changeset-validations", "ember-changeset"], function (_exports, _abstractModuleIndex, _permissionValidator, _odsAppFileSaver, _emberChangesetValidations, _emberChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend(_permissionValidator.default, _odsAppFileSaver.default, {
    toast: Ember.inject.service(),
    loader: Ember.inject.service(),
    routePermission: 'VIEW_REPORTS_USERS',
    breadcrumbsRoute: Ember.computed(function () {
      return [{
        name: 'Reporte usuarios',
        route: 'reporte-usuarios'
      }];
    }),

    // No aplica tener validaciones
    // validations: computed(function() {
    //   return {
    //     startDate: validatePresence({ presence: true }),
    //     endDate: validatePresence({ presence: true }),
    //   };
    // }),
    model()
    /*params, transition*/
    {
      this.loader.setIsLoading();
      return Ember.RSVP.hash({
        users: this.store.query('usuario', {
          filter: {
            isSuperAdmin: 0,
            activo: 1
          }
        })
      }).then(data => {
        let filterModel = this.store.createRecord('reporte-usuarios');
        return Ember.merge(data, {
          filterModel
        });
      });
    },

    setupController(controller, model) {
      this._super(controller, model);

      this.loader.setNotLoading();
      let changeset = new _emberChangeset.default(model.filterModel, (0, _emberChangesetValidations.default)(this.get('validations')), this.get('validations'));
      controller.set('changeset', changeset);
    },

    actions: {
      generarReporte() {
        this.loader.setIsLoading();
        let changeset = this.controller.get('changeset');
        return changeset.validate().then(() => {
          if (!changeset.get('isValid')) {
            this.loader.setNotLoading();
            this.toast.error('Debe seleccionar fecha inicio y fecha fin.');
            return;
          }

          const filters = this.controller.getSerializedFilters();

          if (!filters) {
            this.loader.setNotLoading();
            return;
          }

          this.getFileFromServer('/reporte-usuarios', filters);
        });
      }

    }
  });

  _exports.default = _default;
});