define("ods-app/routes/proyecto/record/servicio/sirvows-centro-costo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajaxService: Ember.inject.service(),
    toast: Ember.inject.service(),
    loader: Ember.inject.service(),
    sivowsCentroCosto: null,
    indexRoute: 'proyecto.record.servicio',

    model() {
      this.loader.setIsLoading();
      return this.ajaxService.request('/sirvows-centro_costo').then(({
        data: sirvowsCentrosCosto
      }) => ({
        sirvowsCentrosCosto
      })).catch(error => {
        this.toast.error('Ocurrió un error al obtener la lista de centro costo.');
        this.transitionTo(this.indexRoute);
        throw error;
      }).finally(() => this.loader.setNotLoading());
    },

    setupController(controller, model) {
      this._super(controller, model);

      let changeset = this.controllerFor(this.indexRoute).get('changeset');
      controller.set('sivowsCentroCosto', null); // Set the controller variable to show the current 'sivowsCentroCosto'.

      if (changeset.get('centroCosto')) {
        const sivowsCentroCosto = model.sirvowsCentrosCosto.findBy('codigo', changeset.get('centroCosto'));

        if (sivowsCentroCosto) {
          controller.set('sivowsCentroCosto', sivowsCentroCosto);
        } else {
          this.toast.error('El centro costo actual asignado ya no existe en Sirvo.');
        }
      }

      controller.setProperties({
        changeset,
        record: this.modelFor(this.indexRoute).record
      });
    }

  });

  _exports.default = _default;
});