define("ods-app/controllers/notification-config/record/configuracion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    user: Ember.computed.readOnly('currentUser.user'),
    hintClasses: Ember.computed(function () {
      return ['small', 'text-info', 'pull-right'];
    })
  });

  _exports.default = _default;
});