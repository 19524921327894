define("ods-app/routes/proyecto/record/empleado/asignar-cargo", ["exports", "servir-ember-utilities/routes/abstract-module-record"], function (_exports, _abstractModuleRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    modelName: 'proyecto-candidato-planilla-cargo',
    proyectoCandidatoPlaceHolderId: null,
    relatedModelsToQuery: Ember.computed('proyectoCandidatoPlaceHolderId', function () {
      let relatedModels = [];
      relatedModels.push({
        name: 'cargosPlanilla',
        modelName: 'planilla-cargo' // queryParams: {
        //   filter: {
        //   }
        // }

      }, {
        name: 'proyectoCandidatoCargosPlanilla',
        modelName: 'proyecto-candidato-planilla-cargo',
        queryParams: {
          filter: {
            'proyectoCandidato.id': this.get('proyectoCandidatoPlaceHolderId')
          }
        }
      });

      if (this.get('proyectoCandidatoPlaceHolderId')) {
        relatedModels.push({
          name: 'proyectoCandidatosPlaceHolder',
          modelName: 'proyecto-candidato',
          queryParams: {
            filter: {
              id: this.get('proyectoCandidatoPlaceHolderId')
            }
          }
        });
      }

      return relatedModels;
    }),

    createRecordInstance(params, transition) {
      const proyecto = this.modelFor('proyecto.record').record;
      const modelName = this.get('modelName');

      if (!Ember.isNone(transition.queryParams.proyectoCandidatoId)) {
        this.set('proyectoCandidatoPlaceHolderId', transition.queryParams.proyectoCandidatoId);
      }

      return this.store.createRecord(modelName, {
        proyecto
      });
    },

    setupController(controller, model) {
      this._super(controller, model);

      const proyecto = this.modelFor('proyecto.record').record;
      controller.set('proyecto', proyecto);

      if (!Ember.isNone(controller.get('proyectoCandidatosPlaceHolder'))) {
        let proyectoCandidato = controller.get('proyectoCandidatosPlaceHolder.firstObject');
        controller.set('proyectoCandidato', proyectoCandidato);
      }
    }

  });

  _exports.default = _default;
});