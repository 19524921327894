define("ods-app/templates/cliente/record/perfiles-puestos/record/competencias", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "r2dKEDCF",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",\"pull-right\"],[8],[0,\"\\n\"],[4,\"bs-button\",null,[[\"onClick\",\"size\",\"classNames\"],[[26,\"action\",[[21,0,[]],\"nuevaCompetencia\",\"Tecnica\"],null],\"xs\",\"btn btn-success btn-xs\"]],{\"statements\":[[0,\"    \"],[6,\"i\"],[10,\"class\",\"fa fa-plus-circle\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n    Nueva competencia técnica\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\\n\"],[6,\"h4\"],[8],[0,\"\\n  Competencias técnicas\\n\"],[9],[0,\"\\n\\n\"],[1,[26,\"tables/simple-table\",null,[[\"model\",\"columns\"],[[22,[\"model\",\"competenciasTecnicas\"]],[22,[\"columns\"]]]]],false],[0,\"\\n\"],[6,\"br\"],[8],[9],[0,\"\\n\\n\"],[6,\"div\"],[10,\"class\",\"pull-right\"],[8],[0,\"\\n\"],[4,\"bs-button\",null,[[\"onClick\",\"size\",\"classNames\"],[[26,\"action\",[[21,0,[]],\"nuevaCompetencia\",\"Liderazgo\"],null],\"xs\",\"btn btn-success btn-xs\"]],{\"statements\":[[0,\"    \"],[6,\"i\"],[10,\"class\",\"fa fa-plus-circle\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n    Nueva competencia de liderazgo\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\\n\"],[6,\"h4\"],[8],[0,\"\\n  Competencias de liderazgo\\n\"],[9],[0,\"\\n\\n\"],[1,[26,\"tables/simple-table\",null,[[\"model\",\"columns\",\"enableSync\"],[[22,[\"model\",\"competenciasLiderazgo\"]],[22,[\"columns\"]],true]]],false],[0,\"\\n\\n\"],[1,[20,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ods-app/templates/cliente/record/perfiles-puestos/record/competencias.hbs"
    }
  });

  _exports.default = _default;
});