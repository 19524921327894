define("ods-app/instance-initializers/toastr-error-handler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;
  const {
    Logger
  } = Ember;

  function initialize(appInstance) {
    let toast = appInstance.lookup('service:toast');

    Ember.onerror = function (error) {
      toast.error('Ha ocurrido un error');
      throw error;
    };

    Ember.RSVP.on('error', function (error) {
      // Ember 2.X seems not to catch `TransitionAborted` errors caused by regular
      // redirects. We currently do not want to show those, so this adds a workaround.
      // Once https://github.com/emberjs/ember.js/issues/12505 is resolved we will be
      // able to remove this ignored error.
      if (error && error.name === 'TransitionAborted') {
        return true;
      }

      (true && !(false) && Ember.assert(error, false));
    });
    let defaultErrorHandler = Logger.error;

    Logger.error = function (message, cause, stack) {
      defaultErrorHandler(message, cause, stack);
      toast.error('Ha ocurrido un error');
    };
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});