define("ods-app/models/cliente-perfil-puesto", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    cliente: belongsTo('cliente'),
    nombreDepartamento: attr('string'),
    grupoPertenece: attr('string'),
    nombrePuesto: attr('string'),
    jefeInmediatoAdministrativo: attr('string'),
    jefeOperacional: attr('string'),
    directorGeneral: attr('string'),
    puestosSupervisa: attr(),
    objetivoPuesto: attr('string'),
    funcionesResponsabilidadesClave: attr('string'),
    relacionesInternas: attr('string'),
    relacionesExternas: attr('string'),
    formacionAcademica: attr('string'),
    formacionAcademicaCompleta: attr('boolean'),
    gradoAcademico: attr('string'),
    observacionesGradoAcademico: attr('string'),
    maestria: attr('boolean'),
    maestriaEstatus: attr('string'),
    maestriaObservaciones: attr('string'),
    otrosCursos: attr(),
    otrosCursosObservaciones: attr('string'),
    colegiatura: attr('boolean'),
    cantidadTotalExperiencia: attr('string'),
    aniosTipoExperiencia: attr('string'),
    situacionAcademica: attr('string'),
    disponibilidadViaje: attr('boolean'),
    horarioTrabajo: attr('string'),
    frecuencia: attr('string'),
    cambioResidencia: attr('boolean'),
    periodosEspecialesTrabajo: attr('string'),
    beneficios: attr('string'),
    rangoSalarial: belongsTo('rango-salarial'),
    nombre: Ember.computed('cliente', 'nombrePuesto', function () {
      let nombrePartes = [this.nombrePuesto]; // Aunque cliente no estuviera disponible aún, no da error porque devuelve un Proxy.

      if (this.get('cliente').get('nombre')) {
        nombrePartes.push(this.get('cliente').get('nombreComercial'));
      }

      return nombrePartes.join(' - ');
    })
  });

  _exports.default = _default;
});