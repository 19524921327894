define("ods-app/routes/modelo-configuracion/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "ember-changeset-validations/validators"], function (_exports, _abstractModuleRecord, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    moduleTitle: 'Configuración de modelos',
    modelName: 'modelo-configuracion',
    indexRoute: 'modelo-configuracion',
    validations: Ember.computed(function () {
      return {
        nombreModelo: (0, _validators.validatePresence)({
          presence: true
        })
      };
    })
  });

  _exports.default = _default;
});