define("ods-app/routes/recodificar-cliente", ["exports", "servir-ember-utilities/routes/abstract-module-index", "ods-app/mixins/permission-validator", "ember-changeset-validations", "servir-ember-utilities/validators/is-codigo", "servir-ember-utilities/validators/custom-presence", "ember-changeset"], function (_exports, _abstractModuleIndex, _permissionValidator, _emberChangesetValidations, _isCodigo, _customPresence, _emberChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend(_permissionValidator.default, {
    loader: Ember.inject.service(),
    routePermission: 'RECODE_CLIENT',
    breadcrumbsRoute: Ember.computed(function () {
      return [{
        name: 'Recodificar cliente',
        route: 'recodificar-cliente'
      }];
    }),
    validations: Ember.computed(function () {
      return {
        codigo: [(0, _customPresence.default)({
          presence: true
        }), (0, _isCodigo.default)()]
      };
    }),

    model()
    /*params, transition*/
    {
      this.loader.setIsLoading();
      return Ember.RSVP.hash({}).then(data => {
        let filterModel = this.store.createRecord('cliente');
        return Ember.merge(data, {
          filterModel
        });
      });
    },

    setupController(controller, model) {
      this._super(controller, model);

      this.loader.setNotLoading();
      let changeset = new _emberChangeset.default(model.filterModel, (0, _emberChangesetValidations.default)(this.get('validations')), this.get('validations'));
      controller.set('changeset', changeset);
    }

  });

  _exports.default = _default;
});