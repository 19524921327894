define("ods-app/components/alerts/new-app-version", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    user: Ember.computed.readOnly('currentUser.user'),
    actions: {
      doHardReload() {
        this.get('user').set('hasLastVersion', true);
        this.get('user').save().then(() => window.location.reload(true));
      }

    }
  });

  _exports.default = _default;
});