define("ods-app/controllers/planilla-cargo/record/factorh-tipo-descuento", ["exports", "servir-ember-utilities/controllers/abstract-module-record"], function (_exports, _abstractModuleRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    indexRoute: 'planilla-cargo.record',
    factorhTipoDescuento: null,
    actions: {
      onChange(factorhTipoDescuento) {
        if (!factorhTipoDescuento) {
          this.changeset.set('factorhTipoDescuentoId', null);
          this.changeset.set('factorhTipoDescuentoStr', null);
          return;
        }

        this.changeset.set('factorhTipoDescuentoId', factorhTipoDescuento.id);
        this.changeset.set('factorhTipoDescuentoStr', factorhTipoDescuento.descripcion);
      },

      discardChanges() {
        this.changeset.rollback();
        this.record.rollbackAttributes();
        this.transitionToRoute(this.get('indexRoute'));
      }

    }
  });

  _exports.default = _default;
});