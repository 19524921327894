define("ods-app/controllers/config/index", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "ods-app/models/config"], function (_exports, _abstractModuleIndex, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    modelName: 'config',
    modelClass: _config.default,
    editRoute: 'config.record',
    columns: Ember.computed(function () {
      return Ember.A([{
        label: 'Código',
        valuePath: 'codigo',
        filtrable: true,
        filterName: 'codigo',
        sortable: false,
        width: '200px'
      }, {
        label: 'Valor',
        valuePath: 'valor',
        filtrable: true,
        filterName: 'valor',
        sortable: false,
        width: '150px'
      }, {
        label: 'Activo',
        valuePath: 'activoFormatted',
        filtrable: true,
        filterName: 'activo',
        sortable: false,
        width: '70px'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        sortable: false,
        width: '100px',
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.edit.bind(this)
          },
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.delete.bind(this)
          }
        }
      }]);
    })
  });

  _exports.default = _default;
});