define("ods-app/controllers/cliente/index/resumen", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "ods-app/models/cliente-contacto", "ods-app/models/cliente-referencia", "ods-app/models/cliente-dato-facturacion"], function (_exports, _abstractModuleIndex, _clienteContacto, _clienteReferencia, _clienteDatoFacturacion) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    modelClassContacto: _clienteContacto.default,
    modelClassDatoFacturacion: _clienteDatoFacturacion.default,
    modelClassReferencia: _clienteReferencia.default,
    modelFilters: Ember.computed('record', function () {
      return [{
        selectedFilter: {
          filterName: 'cliente'
        },
        value: this.get('record.id') || -1
      }];
    }),
    columnsContactos: Ember.computed(function () {
      return Ember.A([{
        label: 'Nombre',
        valuePath: 'nombre',
        sortable: false,
        width: '100px'
      }, {
        label: 'Teléfono',
        valuePath: 'telefono',
        sortable: false,
        width: '100px'
      }, {
        label: 'Puesto',
        valuePath: 'puesto',
        sortable: false,
        width: '100px'
      }, {
        label: 'Correo',
        valuePath: 'correo',
        sortable: false,
        width: '100px'
      }]);
    }),
    columnsDatoFacturacion: Ember.computed(function () {
      return Ember.A([{
        label: 'NIT',
        valuePath: 'nit',
        sortable: false,
        width: '100px'
      }, {
        label: 'Razón social',
        valuePath: 'razonSocial',
        sortable: false,
        width: '100px'
      }, {
        label: 'Dirección fiscal',
        valuePath: 'direccionFiscal',
        sortable: false,
        width: '100px'
      }]);
    }),
    columnsReferencia: Ember.computed(function () {
      return Ember.A([{
        label: 'Tipo',
        valuePath: 'tipoToString',
        sortable: false,
        width: '100px'
      }, {
        label: 'Nombre',
        valuePath: 'nombre',
        sortable: false,
        width: '100px'
      }, {
        label: 'Contacto',
        valuePath: 'contacto',
        sortable: false,
        width: '100px'
      }, {
        label: 'Teléfono',
        valuePath: 'telefono',
        sortable: false,
        width: '100px'
      }]);
    }),
    actions: {
      discardChanges() {
        this.transitionToRoute('cliente');
      }

    }
  });

  _exports.default = _default;
});