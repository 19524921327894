define("ods-app/templates/role/record/generales", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qLpwp+8E",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[4,\"extended-form-for\",[[22,[\"changeset\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"col-sm-6\"],[8],[0,\"\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-text-field\"]],\"codigo\",\"Código:\"],[[\"placeholder\"],[\"Código\"]]],false],[0,\"\\n\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-text-field\"]],\"nombre\",\"Nombre:\"],[[\"placeholder\"],[\"Nombre\"]]],false],[0,\"\\n\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-textarea-field\"]],\"descripcion\",\"Descripción:\"],[[\"placeholder\"],[\"Descripción\"]]],false],[0,\"\\n\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-checkbox-field\"]],\"activo\",\"Activo\"],null],false],[0,\"\\n\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-checkbox-field\"]],\"puedeSerReclutador\",\"Puede ser reclutador\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ods-app/templates/role/record/generales.hbs"
    }
  });

  _exports.default = _default;
});