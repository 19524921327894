define("ods-app/controllers/recodificar-cliente", ["exports", "servir-ember-utilities/controllers/abstract-module-record", "ember-changeset", "ember-changeset-validations"], function (_exports, _abstractModuleRecord, _emberChangeset, _emberChangesetValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    toast: Ember.inject.service(),
    selectedCliente: '',
    actions: {
      submit() {
        if (!this.selectedCliente) {
          this.toast.error('Debe seleccionar un cliente.');
          return;
        }

        if (!this.changeset.get('codigo')) {
          this.toast.error('El codigo no puede estar vacio.');
          return;
        }

        return this._super(...arguments);
      },

      onClienteChanged() {
        let newChangeset = new _emberChangeset.default(this.selectedCliente, (0, _emberChangesetValidations.default)(this.get('validations')), this.get('validations'));
        this.set('changeset', newChangeset);
      },

      searchClients(text) {
        if (!text || text.length < 3) {
          return;
        }

        if (this.contactsQuery) {
          Ember.run.cancel(this.contactsQuery);
        }

        this.contactsQuery = Ember.run.later(() => {
          this.set('availableClients', this.store.query('cliente', {
            filter: {
              codigoNombre: text
            }
          }));
        }, 500);
      }

    }
  });

  _exports.default = _default;
});