define("ods-app/routes/proyecto/index", ["exports", "servir-ember-utilities/routes/abstract-module-index", "ods-app/mixins/permission-validator"], function (_exports, _abstractModuleIndex, _permissionValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend(_permissionValidator.default, {
    routePermission: 'CREATE_PROJECTS',
    breadcrumbsRoute: Ember.computed(function () {
      return [{
        name: 'Proyectos',
        route: 'proyecto'
      }];
    }),

    model() {
      return Ember.RSVP.hash({
        servicios: this.store.query('servicio', {
          filter: {
            activo: 1
          }
        }),
        contactos: Ember.A([]),
        datosFacturacion: Ember.A([])
      });
    },

    setupController(controller, model) {
      this._super(controller, model);

      controller.setProperties({
        selectedCliente: null,
        selectedContacto: null,
        selectedDatoFacturacion: null,
        selectedServicio: null
      });
    }

  });

  _exports.default = _default;
});