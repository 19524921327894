define("ods-app/routes/menu/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "ember-changeset-validations/validators", "servir-ember-utilities/validators/custom-presence", "servir-ember-utilities/validators/is-codigo"], function (_exports, _abstractModuleRecord, _validators, _customPresence, _isCodigo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    modelName: 'menu',
    indexRoute: 'menu',
    breadcrumbsRoute: Ember.computed('recordId', function () {
      return [{
        name: 'Menús',
        route: 'menu'
      }, {
        name: 'Editar',
        route: 'menu.record',
        queryParam: this.recordId
      }];
    }),
    validations: Ember.computed(function () {
      return {
        codigo: [(0, _customPresence.default)({
          presence: true
        }), (0, _isCodigo.default)()],
        nombre: (0, _validators.validatePresence)({
          presence: true
        })
      };
    })
  });

  _exports.default = _default;
});