define("ods-app/routes/proyectos-existentes/index", ["exports", "servir-ember-utilities/routes/abstract-module-index"], function (_exports, _abstractModuleIndex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    breadcrumbsRoute: Ember.computed(function () {
      return [{
        name: 'Proyectos existentes',
        route: 'proyectos-existentes'
      }];
    })
  });

  _exports.default = _default;
});