define("ods-app/validations/messages", ["exports", "servir-ember-utilities/validations/messages"], function (_exports, _messages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.merge(_messages.default, {
    custom: 'This is a customized error message'
  });

  _exports.default = _default;
});