define("ods-app/models/cliente-contrato", ["exports", "ember-data", "moment", "accounting/format-number"], function (_exports, _emberData, _moment, _formatNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    tiempoVigencia: attr('number'),
    porcentajeFee: attr('number'),
    activo: attr('boolean'),
    fechaInicio: attr('custom-date'),
    fechaFin: attr('custom-date'),
    cliente: belongsTo('cliente'),
    createdAt: attr('custom-date', {
      defaultValue() {
        return new Date();
      }

    }),
    activoFormatted: Ember.computed('activo', function () {
      return this.activo ? 'Sí' : 'No';
    }),
    tiempoVigenciaFormatted: Ember.computed('tiempoVigencia', function () {
      return `${this.tiempoVigencia} meses`;
    }),
    porcentajeFeeFormatted: Ember.computed('porcentajeFee', function () {
      return (0, _formatNumber.default)(this.get('porcentajeFee'), 2);
    }),
    fechaInicioFormatted: Ember.computed('fechaInicio', function () {
      const momentDate = (0, _moment.default)(this.fechaInicio);

      if (Ember.isNone(this.fechaInicio) || !momentDate.isValid()) {
        return '';
      }

      return momentDate.format('DD/MM/YYYY');
    }),
    fechaFinFormatted: Ember.computed('fechaFin', function () {
      const momentDate = (0, _moment.default)(this.fechaFin);

      if (Ember.isNone(this.fechaFin) || !momentDate.isValid()) {
        return '';
      }

      return momentDate.format('DD/MM/YYYY');
    }),
    createdAtFormatted: Ember.computed('createdAt', function () {
      const momentDate = (0, _moment.default)(this.createdAt);

      if (Ember.isNone(this.createdAt) || !momentDate.isValid()) {
        return '';
      }

      return momentDate.format('DD/MM/YYYY');
    })
  });

  _exports.default = _default;
});