define("ods-app/routes/usuario/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "servir-ember-utilities/mixins/route/has-tabs", "ods-app/validations/changeset/usuario"], function (_exports, _abstractModuleRecord, _hasTabs, _usuario) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend(_hasTabs.default, {
    modelName: 'usuario',
    indexRoute: 'usuario',
    defaultTabSubRoute: 'generales',
    breadcrumbsRoute: Ember.computed('recordId', function () {
      return [{
        name: 'Usuarios',
        route: 'usuario'
      }, {
        name: 'Editar',
        route: `usuario.record.${this.defaultTabSubRoute}`,
        queryParam: this.recordId
      }];
    }),
    validations: null,
    relatedModelsToQuery: Ember.computed(function () {
      return [{
        name: 'roles',
        modelName: 'role',
        queryParams: {
          filter: {
            activo: 1
          }
        }
      }, {
        name: 'proyectos',
        modelName: 'proyecto',
        queryParams: {
          filter: {
            estatus: this.constants.PROJECT_STATUS_ACTIVE
          },
          include: 'cliente'
        }
      }, {
        name: 'clientePerfilesPuestos',
        modelName: 'cliente-perfil-puesto'
      }];
    }),

    model(params) {
      this.set('validations', (0, _usuario.default)(params.id === 'new'));
      return this._super(...arguments);
    },

    setupController(controller) {
      this._super(...arguments); // Avoid filling the confirm password field.


      controller.changeset.set('plainPassword', null);
      controller.changeset.set('confirmPassword', null);
    }

  });

  _exports.default = _default;
});