define("ods-app/components/buttons/create-record", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CreateRecordButton = Ember.Component.extend({
    route: null
  });
  CreateRecordButton.reopenClass({
    positionalParams: ['route']
  });
  var _default = CreateRecordButton;
  _exports.default = _default;
});