define("ods-app/routes/proyecto/record/empleado/cargos/listado", ["exports", "servir-ember-utilities/routes/abstract-module-index"], function (_exports, _abstractModuleIndex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    model(params, transition) {
      return Ember.RSVP.hash({
        proyectoCandidatoCargosPlanilla: this.store.query('proyecto-candidato-planilla-cargo', {
          filter: {
            'proyectoCandidato.id': transition.queryParams.proyectoCandidatoId
          }
        })
      });
    },

    renderTemplate(controller, model) {
      this._super(controller, model);

      this.render('proyecto/record/empleado/cargos/cargo-record-button', {
        outlet: 'submit-button'
      });
    }

  });

  _exports.default = _default;
});