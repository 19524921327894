define("ods-app/controllers/permission/record", ["exports", "servir-ember-utilities/controllers/abstract-module-record"], function (_exports, _abstractModuleRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    postSaveTransitionRoute: Ember.computed.readOnly('indexRoute'),
    otherPermissions: Ember.computed('permissions', 'record', function () {
      const availablePermissions = this.permissions.filter(p => p.id !== this.record.id);
      return availablePermissions;
    })
  });

  _exports.default = _default;
});