define("ods-app/routes/cliente/index", ["exports", "servir-ember-utilities/routes/abstract-module-index"], function (_exports, _abstractModuleIndex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    breadcrumbsRoute: Ember.computed(function () {
      return [{
        name: 'Clientes',
        route: 'cliente'
      }];
    }),

    setupController(controller, model) {
      this._super(controller, model);

      controller.set('reload', true);
    }

  });

  _exports.default = _default;
});