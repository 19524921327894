define("ods-app/routes/reporte-planilla", ["exports", "servir-ember-utilities/routes/abstract-module-index", "ods-app/mixins/permission-validator", "ember-changeset", "ember-changeset-validations", "ember-changeset-validations/validators"], function (_exports, _abstractModuleIndex, _permissionValidator, _emberChangeset, _emberChangesetValidations, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    hash
  } = Ember.RSVP;

  var _default = _abstractModuleIndex.default.extend(_permissionValidator.default, {
    loader: Ember.inject.service(),
    routePermission: 'VIEW_PAYROLL_REPORT',
    breadcrumbsRoute: Ember.computed(function () {
      return [{
        name: 'Reporte planilla',
        route: 'reporte-planilla'
      }];
    }),
    validations: Ember.computed(function () {
      return {
        proyecto: (0, _validators.validatePresence)({
          presence: true
        }),
        anio: (0, _validators.validatePresence)({
          presence: true
        }),
        mes: (0, _validators.validatePresence)({
          presence: true
        })
      };
    }),

    model()
    /*params, transition*/
    {
      this.loader.setIsLoading();
      return hash({
        projects: this.store.query('proyecto', {
          filter: {
            'servicioAdquirido.tipoServicio': this.constants.SERVICE_TYPES_PAYROLL_ADMINISTRATION_FACTORH
          },
          include: 'cliente,servicioAdquirido'
        })
      }).then(data => {
        let now = new Date();
        let filterModel = this.store.createRecord('reporte-planilla', {
          anio: now.getFullYear(),
          mes: now.getMonth()
        });
        return Ember.merge(data, {
          filterModel
        });
      }).finally(() => this.loader.setNotLoading());
    },

    years: Ember.computed(function () {
      let nowYear = new Date().getFullYear();
      let years = [nowYear];

      while (nowYear-- >= 2016) {
        years.push(nowYear);
      }

      return years;
    }),

    setupController(controller, model) {
      this._super(controller, model);

      let changeset = new _emberChangeset.default(model.filterModel, (0, _emberChangesetValidations.default)(this.get('validations')), this.get('validations'));
      controller.set('mesAnteriorSeleccionado', false);
      controller.set('mesActualSeleccionado', false);
      controller.set('changeset', changeset);
      controller.set('anios', this.years);
    }

  });

  _exports.default = _default;
});