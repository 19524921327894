define("ods-app/controllers/notification-config/index", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "ods-app/models/notification-config"], function (_exports, _abstractModuleIndex, _notificationConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    modelName: 'notification-config',
    modelClass: _notificationConfig.default,
    editRoute: 'notification-config.record',
    columns: Ember.computed(function () {
      return Ember.A([{
        label: 'Código',
        valuePath: 'code',
        filtrable: true,
        filterName: 'code',
        sortable: false,
        width: '100px'
      }, {
        label: 'Nombre',
        valuePath: 'name',
        filtrable: true,
        filterName: 'name',
        sortable: false,
        width: '150px'
      }, {
        label: 'Evento',
        valuePath: 'eventString',
        sortable: false,
        width: '130px'
      }, {
        label: 'Activo',
        valuePath: 'enabledString',
        sortable: false,
        width: '50px'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        sortable: false,
        width: '100px',
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.edit.bind(this)
          },
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.preDelete.bind(this)
          }
        }
      }]);
    }),
    actions: {
      preDelete(item, row, lightTable) {
        if (item.get('cannotBeRemoved')) {
          this.toast.error('La notificación no se puede eliminar.');
          return;
        }

        this.actions.delete.call(this, item, row, lightTable);
      }

    }
  });

  _exports.default = _default;
});