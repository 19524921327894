define("ods-app/controllers/proyecto/record/empleado/asignar-cargo", ["exports", "servir-ember-utilities/controllers/abstract-module-record"], function (_exports, _abstractModuleRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    modelName: 'proyecto-candidato-planilla-cargo',
    toast: Ember.inject.service(),
    loader: Ember.inject.service(),
    cargo: '',
    activo: true,
    agregarCargo: true,
    columns: Ember.computed(function () {
      return Ember.A([{
        label: 'Empleado',
        valuePath: 'proyectoCandidato.candidato.nombreCompleto',
        sortable: false,
        width: '100px'
      }, {
        label: 'Cargo',
        valuePath: 'planillaCargo.nombre',
        sortable: false,
        width: '100px'
      }, {
        label: 'Monto',
        valuePath: 'monto',
        sortable: false,
        width: '100px'
      }, {
        label: 'Activo',
        valuePath: 'activoFormatted',
        sortable: false,
        width: '100px'
      } // {
      //   label: 'Acciones',
      //   cellComponent: 'tables/cell/object-actions',
      //   width: '100px',
      //   table: this,
      //   sortable: false,
      //   objectActions: {
      //     edit: {
      //       title: 'Editar',
      //       buttonClass: 'btn-primary',
      //       icon: 'pencil-square-o',
      //       action: this.actions.edit.bind(this),
      //     },
      //     delete: {
      //       title: 'Eliminar',
      //       buttonClass: 'btn-danger',
      //       icon: 'trash-o',
      //       action: this.actions.delete.bind(this),
      //     },
      //   }
      // }
      ]);
    }),
    actions: {
      discardChanges() {
        this.set('cargo', null);
        this.set('agregarCargo', true); // this.record.rollbackAttributes();

        this.transitionToRoute('proyecto.record.empleado', this.proyecto.get('id'));
      },

      asignarCargo() {
        if (this.cargo) {
          let proyectoCandidatoPlanillaCargo = this.store.createRecord('proyecto-candidato-planilla-cargo', {
            proyectoCandidato: this.proyectoCandidato,
            planillaCargo: this.cargo,
            monto: this.cargo.get('montoDefault'),
            activo: this.activo
          });
          this.loader.setIsLoading();
          proyectoCandidatoPlanillaCargo.save().then(() => {
            this.toast.success('Registro creado correctamente.');
            this.set('cargo', null);
          }).catch(error => {
            this.toast.error('No se pudo asociar el cargo  al empleado.');
            throw error;
          }).finally(() => this.loader.setNotLoading());
          this.transitionToRoute('proyecto.record.empleado', this.proyecto.get('id'));
        } else {
          this.toast.error('Debe seleccionar un cargo.');
          return;
        }
      },

      verCargos() {
        if (this.agregarCargo) {
          this.set('agregarCargo', false);
        } else {
          this.set('agregarCargo', true);
        }
      }

    }
  });

  _exports.default = _default;
});