define("ods-app/routes/requerimiento/record/ingreso/asociar-planilla", ["exports", "servir-ember-utilities/routes/abstract-module-record", "ember-changeset-validations/validators"], function (_exports, _abstractModuleRecord, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    modelName: 'proyecto-candidato',
    routeRecordIdentifierDynamicSegment: 'proyectocandidato_id',
    indexRoute: 'requerimiento.record.ingreso',
    ajaxService: Ember.inject.service(),
    loader: Ember.inject.service(),
    proyectoPlaceHolderId: null,
    aplicacionPlaceHolderId: null,
    validations: Ember.computed(function () {
      return {
        requerimiento: (0, _validators.validatePresence)({
          presence: true
        }),
        proyecto: (0, _validators.validatePresence)({
          presence: true
        }),
        candidato: (0, _validators.validatePresence)({
          presence: true
        }),
        fechaAlta: (0, _validators.validatePresence)({
          presence: true
        }),
        factorhTipoPlanillaId: (0, _validators.validatePresence)({
          presence: true
        }),
        factorhPuestoId: (0, _validators.validatePresence)({
          presence: true
        }) // numeroSeguroSocial: validatePresence({ presence: true }),

      };
    }),
    relatedModelsToQuery: Ember.computed(function () {
      let relatedModels = [];

      if (this.proyectoPlaceHolderId) {
        relatedModels.push({
          name: 'proyectosPlaceHolder',
          modelName: 'proyecto',
          queryParams: {
            filter: {
              id: this.proyectoPlaceHolderId
            }
          }
        });
      }

      if (this.aplicacionPlaceHolderId) {
        relatedModels.push({
          name: 'aplicacionesPlaceHolder',
          modelName: 'aplicacion',
          queryParams: {
            filter: {
              id: this.aplicacionPlaceHolderId
            },
            include: 'candidato'
          }
        });
      }

      return relatedModels;
    }),

    model(params, transition) {
      let requerimiento = this.modelFor('requerimiento.record').record;

      if (!requerimiento.id || !requerimiento.get('proyecto.id')) {
        this.toast.error('Guarde el requerimiento y asigne un proyecto para poder realizar esta acción.');
        return this.transitionTo(this.indexRoute);
      }

      let proyecto = requerimiento.get('proyecto');

      if (!proyecto.get('factorhDepartamentoStr')) {
        this.toast.error('El proyecto no tiene asignado un departamento de FactoRH.');
        return this.transitionTo(this.indexRoute);
      }

      this.set('proyectoPlaceHolderId', requerimiento.get('proyecto.id'));
      this.set('aplicacionPlaceHolderId', transition.queryParams.aplicacionId);

      if (!this.aplicacionPlaceHolderId) {
        this.toast.error('Seleccione una aplicación para realizar esta acción.');
        return this.transitionTo(this.indexRoute);
      }

      this.loader.setIsLoading();
      return this._super(...arguments).then(parentData => {
        return Ember.RSVP.hash({
          puestosRes: this.ajaxService.request('/factorh-puestos', {
            data: {
              requerimientoId: requerimiento.id
            }
          }),
          tiposPlanillaRes: this.ajaxService.request('/factorh-tipos-planillas', {
            data: {
              requerimientoId: requerimiento.id
            }
          })
        }).then(({
          puestosRes,
          tiposPlanillaRes
        }) => ({
          factorhPuestos: puestosRes.data,
          factorhTiposPlanilla: tiposPlanillaRes.data
        })).then(factorhData => Ember.merge(parentData, factorhData));
      }).finally(() => this.loader.setNotLoading());
    },

    createRecordInstance()
    /*params, transition*/
    {
      let requerimiento = this.modelFor('requerimiento.record').record;
      return this.store.createRecord(this.modelName, {
        requerimiento
      });
    },

    setupController(controller, model) {
      this._super(...arguments);

      let proyectoCandidato = model.record;

      if (!Ember.isNone(controller.get('proyectosPlaceHolder'))) {
        let proyecto = controller.get('proyectosPlaceHolder.firstObject');
        proyectoCandidato.set('proyecto', proyecto);
      }

      if (!Ember.isNone(controller.get('aplicacionesPlaceHolder'))) {
        let aplicacion = controller.get('aplicacionesPlaceHolder.firstObject');
        let fechaIngreso = aplicacion.get('fechaIngreso');
        let fechaSalida = aplicacion.get('fechaFinLabores');
        let candidato = aplicacion.get('candidato');
        proyectoCandidato.set('fechaAlta', fechaIngreso);
        proyectoCandidato.set('fechaBaja', fechaSalida);
        proyectoCandidato.set('candidato', candidato);
      }
    },

    actions: {
      willTransition() {
        return false;
      }

    }
  });

  _exports.default = _default;
});