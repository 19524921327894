define("ods-app/models/usuario", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    username: attr('string'),
    email: attr('string'),
    enabled: attr('boolean', {
      defaultValue: true
    }),
    plainPassword: attr('string', {
      defaultValue: null
    }),
    confirmPassword: attr('string', {
      defaultValue: null
    }),
    role: belongsTo('role'),
    roles: attr(),
    isSuperAdmin: attr('boolean', {
      defaultValue: true
    }),
    codigo: attr('string'),
    nombres: attr('string'),
    apellidos: attr('string'),
    celular: attr('string'),
    telefono: attr('string'),
    dpi: attr('string'),
    nit: attr('string'),
    direccion: attr('string'),
    departamento: attr('string'),
    puesto: attr('string'),
    passwordResetted: attr('boolean', {
      defaultValue: false
    }),
    hasLastVersion: attr('boolean', {
      defaultValue: false
    }),
    requerimientos: hasMany('requerimiento', {
      inverse: 'responsable'
    }),
    proyectos: hasMany('proyecto'),
    perfilPuestos: hasMany('cliente-perfil-puesto'),
    nombreCompleto: Ember.computed('nombres', 'apellidos', function () {
      return `${this.nombres} ${this.apellidos}`;
    }),
    stringify: Ember.computed.alias('nombreCompleto'),
    activoFormatted: Ember.computed('enabled', function () {
      return this.enabled ? 'Sí' : 'No';
    })
  });

  _exports.default = _default;
});