define("ods-app/routes/requerimiento/record/perfil/competencia", ["exports", "servir-ember-utilities/routes/abstract-module-record", "ember-changeset-validations/validators"], function (_exports, _abstractModuleRecord, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    modelName: 'requerimiento-competencia',
    indexRoute: 'requerimiento.record.perfil',
    routeRecordIdentifierDynamicSegment: 'competencia_id',
    validations: Ember.computed(function () {
      return {
        competencia: (0, _validators.validatePresence)({
          presence: true
        }),
        grado: (0, _validators.validatePresence)({
          presence: true
        }),
        tipo: (0, _validators.validatePresence)({
          presence: true
        })
      };
    }),

    createRecordInstance(params, transition) {
      const modelName = this.get('modelName');
      const requerimiento = this.modelFor('requerimiento.record').record;
      let tipo = this.constants.COMPETENCE_TYPE_TECNICA;

      if (transition.queryParams.tipo === 'Liderazgo') {
        tipo = this.constants.COMPETENCE_TYPE_LIDERAZGO;
      }

      return this.store.createRecord(modelName, {
        requerimiento,
        tipo: tipo
      });
    },

    actions: {
      willTransition() {
        return true;
      }

    }
  });

  _exports.default = _default;
});