define("ods-app/templates/cliente/record/contratos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RvbZR1PC",
    "block": "{\"symbols\":[],\"statements\":[[6,\"br\"],[8],[9],[0,\"\\n\\n\"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[6,\"div\"],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n    \"],[1,[26,\"tables/simple-table\",null,[[\"model\",\"modelName\",\"columns\",\"enableSync\"],[[22,[\"model\",\"proyectos\"]],[22,[\"modelName\"]],[22,[\"columns\"]],true]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[20,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ods-app/templates/cliente/record/contratos.hbs"
    }
  });

  _exports.default = _default;
});