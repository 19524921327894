define("ods-app/controllers/proyecto/record/empleado/cargos/listado", ["exports", "servir-ember-utilities/controllers/abstract-module-index"], function (_exports, _abstractModuleIndex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    toast: Ember.inject.service(),
    modelName: 'proyecto-candidato-planilla-cargo',
    editRoute: 'proyecto.record.empleado.cargos.agregar',
    columns: Ember.computed(function () {
      return Ember.A([{
        label: 'Empleado',
        valuePath: 'proyectoCandidato.candidato.nombreCompleto',
        sortable: false,
        width: '100px'
      }, {
        label: 'Cargo',
        valuePath: 'planillaCargo.nombre',
        sortable: false,
        width: '100px'
      }, {
        label: 'Monto',
        valuePath: 'monto',
        sortable: false,
        width: '70px'
      }, {
        label: 'Activo',
        valuePath: 'activoFormatted',
        sortable: false,
        width: '70px'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        width: '100px',
        table: this,
        sortable: false,
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.edit.bind(this)
          },
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.delete.bind(this)
          }
        }
      }]);
    }),
    actions: {
      record() {
        let proyectoCandidatoId = this.model.proyectoCandidatoCargosPlanilla.firstObject.get('proyectoCandidato.id');
        return this.transitionToRoute('proyecto.record.empleado.cargos.agregar', 'new', {
          queryParams: {
            'proyectoCandidatoId': proyectoCandidatoId
          }
        });
      }

    }
  });

  _exports.default = _default;
});