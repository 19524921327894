define("ods-app/controllers/proyecto/record/empleado/cargos/agregar", ["exports", "servir-ember-utilities/controllers/abstract-module-record"], function (_exports, _abstractModuleRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    postSaveTransitionRoute: Ember.computed.alias('indexRoute'),
    validPlanillaCargos: Ember.computed('cargosPlanilla', 'currentCargosPlanilla', function () {
      let currentCargosIds = this.currentCargosPlanilla.mapBy('planillaCargo.id');
      return this.cargosPlanilla.filter(cp => !currentCargosIds.includes(cp.get('id')));
    }),
    actions: {
      submit() {
        this.changeset.set('proyectoCandidato', this.proyectoCandidato);
        return this._super(...arguments);
      }

    }
  });

  _exports.default = _default;
});