define("ods-app/components/app-sidebar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    user: Ember.computed.readOnly('currentUser.user'),
    hierarchicalPermissions: Ember.computed.readOnly('currentUser.menuRootPermissions'),
    tagName: '',
    sidebarCollapsed: false,
    mobileDisplay: false,
    _observeMobileDisplaySidebar: Ember.observer('media.isMobile', 'media.isTablet', function () {
      this.set('sidebarCollapsed', this.get('media.isMobile') || this.get('media.isTablet'));
    })
  });

  _exports.default = _default;
});