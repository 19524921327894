define("ods-app/controllers/proyecto/record/calculo-planilla", ["exports", "ods-app/mixins/ods-app-file-saver"], function (_exports, _odsAppFileSaver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { computed } from '@ember/object';
  var _default = Ember.Controller.extend(_odsAppFileSaver.default, {
    toast: Ember.inject.service(),
    actions: {
      // updateCantidad(changeset, propertyName, value = null) {
      //   changeset.set(propertyName, value);
      //   const montoFacturar = parseFloat(changeset.get('montoFacturar'));
      //   const porcentajeFee = parseFloat(changeset.get('porcentajeFee'));
      //   changeset.set('cantidadFee', (montoFacturar * porcentajeFee)/100);
      // },
      generarReporte() {
        if (!this.anio) {
          this.toast.error('Seleccione un año.');
          return;
        }

        if (!this.mes) {
          this.toast.error('Seleccione un mes.');
          return;
        }

        this.getFileFromServer('/reporte-planilla', {
          proyecto: this.record.get('id') || null,
          anio: this.anio || null,
          mes: this.mes || 0
        });
      }

    }
  });

  _exports.default = _default;
});