define("ods-app/controllers/popups/simple-confirm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    title: null,
    message: null,

    onSubmit() {},

    onCancel() {},

    actions: {
      onCancel() {
        return this.get('onCancel')();
      },

      onSubmit() {
        return this.get('onSubmit')();
      }

    }
  });

  _exports.default = _default;
});