define("ods-app/controllers/popups/multiple-option-confirm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    title: null,
    message: null,
    showCancel: true,
    buttonActions: Ember.computed(function () {
      return [];
    }),

    onSubmit() {},

    actions: {
      onSubmit(methodName) {
        let method = methodName && this.get(methodName) || this.get('onSubmit');
        return method();
      }

    }
  });

  _exports.default = _default;
});