define("ods-app/routes/pais/departamento/municipio", ["exports", "servir-ember-utilities/routes/abstract-module-index"], function (_exports, _abstractModuleIndex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    departamento: null,
    breadcrumbsRoute: Ember.computed(function () {
      return [{
        name: 'Países',
        route: 'pais'
      }, {
        name: 'Departamentos',
        route: 'pais.departamento'
      }, {
        name: 'Municipios',
        route: 'pais.departamento.municipio'
      }];
    }),

    model(params) {
      this.set('departamento', null);

      if (!params.departamento_id) {
        throw new Error('This route expects departamento_id parameter.');
      }

      return this.store.findRecord('departamento', params.departamento_id).then(departamento => {
        this.set('departamento', departamento);
        return Ember.A([]);
      });
    },

    setupController(controller, model) {
      controller.set('reload', true);
      controller.set('departamento', this.get('departamento'));

      this._super(controller, model);
    },

    renderTemplate: function () {
      this.render('pais/departamento/municipio', {
        into: 'application'
      });
    }
  });

  _exports.default = _default;
});