define("ods-app/templates/notification-config/record", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+FlhQgGj",
    "block": "{\"symbols\":[\"nav\"],\"statements\":[[6,\"div\"],[10,\"class\",\"page-header\"],[8],[0,\"\\n  \"],[6,\"h1\"],[8],[0,\"\\n\"],[4,\"if\",[[22,[\"record\",\"isNew\"]]],null,{\"statements\":[[0,\"      Crear notificación\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      Editar notificación\\n\"]],\"parameters\":[]}],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"pull-right\"],[8],[0,\"\\n      \"],[1,[26,\"forms/controls/ace-async-save-button\",null,[[\"onClick\"],[[26,\"action\",[[21,0,[]],\"submit\"],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[6,\"div\"],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n\"],[4,\"bs-nav\",null,[[\"type\"],[\"tabs\"]],{\"statements\":[[4,\"component\",[[21,1,[\"item\"]]],null,{\"statements\":[[4,\"component\",[[21,1,[\"link-to\"]],\"notification-config.record.configuracion\"],null,{\"statements\":[[0,\"          Configuración\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[21,1,[\"item\"]]],null,{\"statements\":[[4,\"component\",[[21,1,[\"link-to\"]],\"notification-config.record.plantilla\"],null,{\"statements\":[[0,\"          Plantilla\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"tab-content\"],[8],[0,\"\\n      \"],[1,[20,\"outlet\"],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ods-app/templates/notification-config/record.hbs"
    }
  });

  _exports.default = _default;
});