define("ods-app/routes/proyecto/record/documentos/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "ember-changeset-validations/validators"], function (_exports, _abstractModuleRecord, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    routeRecordIdentifierDynamicSegment: 'documento_id',
    modelName: 'proyecto-archivo',
    indexRoute: 'proyecto.record.documentos',
    validations: Ember.computed(function () {
      return {
        nombre: (0, _validators.validatePresence)({
          presence: true
        }),
        proyecto: (0, _validators.validatePresence)({
          presence: true
        })
      };
    }),

    model() {
      return this._super(...arguments).then(modelData => {
        return Ember.RSVP.all([modelData.record.get('upload')]).then(() => modelData);
      });
    },

    createRecordInstance() {
      const proyecto = this.modelFor('proyecto.record').record;
      const modelName = this.get('modelName');
      return this.store.createRecord(modelName, {
        proyecto: proyecto
      });
    },

    setupController(controller, model) {
      this._super(controller, model); // WARNING: This line creates the changeset for the file.


      controller._setNuevoArchivo();
    },

    actions: {
      willTransition() {
        return true;
      }

    }
  });

  _exports.default = _default;
});