define("ods-app/mirage/serializers/application", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.JSONAPISerializer.extend({
    serialize(primaryResource, request) {
      let json = _emberCliMirage.JSONAPISerializer.prototype.serialize.apply(this, arguments);

      const reqPageNumber = request.queryParams['page_number'];
      const reqPageSize = request.queryParams['page_size'];
      const pageEnabled = reqPageNumber !== undefined && reqPageSize !== undefined;

      if (!pageEnabled) {
        json.meta = {
          totalItems: json.data.length
        };
        return json;
      }

      const pageNumber = parseInt(reqPageNumber);
      const pageSize = parseInt(reqPageSize);
      json.meta = {
        totalItems: request.queryParams.totalItems,
        itemsPerPage: pageSize,
        currentPage: pageNumber
      };
      return json;
    }

  });

  _exports.default = _default;
});