define("ods-app/templates/tutorial/record", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5UA5GOgR",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[6,\"div\"],[10,\"class\",\"page-header\"],[8],[0,\"\\n  \"],[6,\"h1\"],[8],[0,\"\\n\"],[4,\"if\",[[22,[\"record\",\"isNew\"]]],null,{\"statements\":[[0,\"      Crear tutorial\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      Config \"],[6,\"small\"],[8],[1,[22,[\"record\",\"codigo\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"pull-right\"],[8],[0,\"\\n      \"],[1,[26,\"forms/controls/ace-async-save-button\",null,[[\"onClick\"],[[26,\"action\",[[21,0,[]],\"submit\"],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[6,\"div\"],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n\"],[4,\"extended-form-for\",[[22,[\"changeset\"]]],null,{\"statements\":[[0,\"      \"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n        \"],[6,\"div\"],[10,\"class\",\"col-sm-6\"],[8],[0,\"\\n          \"],[1,[26,\"component\",[[21,1,[\"ace-text-field\"]],\"codigo\",\"Código:\"],[[\"placeholder\"],[\"Código\"]]],false],[0,\"\\n\\n          \"],[1,[26,\"component\",[[21,1,[\"ace-text-field\"]],\"link\",\"Link:\"],[[\"placeholder\"],[\"Link\"]]],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ods-app/templates/tutorial/record.hbs"
    }
  });

  _exports.default = _default;
});