define("ods-app/controllers/servicio/tutorial", ["exports", "servir-ember-utilities/controllers/abstract-module-index"], function (_exports, _abstractModuleIndex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    actions: {
      discardChanges() {
        this.transitionToRoute('servicio');
      }

    }
  });

  _exports.default = _default;
});