define("ods-app/models/permission", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    codigo: attr('string'),
    nombre: attr('string'),
    descripcion: attr('string'),
    activo: attr('boolean', {
      defaultValue: true
    }),
    parent: belongsTo('permission', {
      inverse: null
    }),
    prioridad: attr('number'),
    requireSuperAdmin: attr('boolean', {
      defaultValue: false
    }),
    menu: belongsTo('menu'),
    activoFormatted: Ember.computed('activo', function () {
      return this.activo ? 'Sí' : 'No';
    })
  });

  _exports.default = _default;
});