define("ods-app/routes/reporte-perfiles-puesto", ["exports", "servir-ember-utilities/routes/abstract-module-index", "ods-app/mixins/permission-validator", "ods-app/mixins/ods-app-file-saver", "ember-changeset-validations", "ember-changeset-validations/validators", "ember-changeset"], function (_exports, _abstractModuleIndex, _permissionValidator, _odsAppFileSaver, _emberChangesetValidations, _validators, _emberChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend(_permissionValidator.default, _odsAppFileSaver.default, {
    toast: Ember.inject.service(),
    loader: Ember.inject.service(),
    routePermission: 'VIEW_REPORTS_JOB_PROFILES',
    breadcrumbsRoute: Ember.computed(function () {
      return [{
        name: 'Reporte perfiles de puesto',
        route: 'reporte-perfiles-puesto'
      }];
    }),
    validations: Ember.computed(function () {
      return {
        cliente: (0, _validators.validatePresence)({
          presence: true
        })
      };
    }),

    model()
    /*params, transition*/
    {
      this.loader.setIsLoading();
      return Ember.RSVP.hash({
        clients: this.store.query('cliente', {})
      }).then(data => {
        let filterModel = this.store.createRecord('reporte-perfiles-puesto');
        return Ember.merge(data, {
          filterModel
        });
      });
    },

    setupController(controller, model) {
      this._super(controller, model);

      this.loader.setNotLoading();
      let changeset = new _emberChangeset.default(model.filterModel, (0, _emberChangesetValidations.default)(this.get('validations')), this.get('validations'));
      controller.set('changeset', changeset);
    },

    actions: {
      generarReporte() {
        this.loader.setIsLoading();
        let changeset = this.controller.get('changeset');
        return changeset.validate().then(() => {
          if (!changeset.get('isValid')) {
            this.loader.setNotLoading();
            this.toast.error('Debe seleccionar un cliente.');
            return;
          }

          const filters = this.controller.getSerializedFilters();

          if (!filters) {
            this.loader.setNotLoading();
            return;
          }

          this.getFileFromServer('/reporte-perfiles-puesto', filters);
        });
      }

    }
  });

  _exports.default = _default;
});