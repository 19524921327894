define("ods-app/controllers/proyecto/record/empleado", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "ods-app/models/proyecto-candidato"], function (_exports, _abstractModuleIndex, _proyectoCandidato) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    modelName: 'proyecto-candidato',
    modelClass: _proyectoCandidato.default,
    modelFilters: Ember.computed('record', function () {
      return [{
        selectedFilter: {
          filterName: 'proyecto'
        },
        value: this.get('record.id') || -1
      }];
    }),
    columns: Ember.computed(function () {
      return Ember.A([{
        label: 'Nombre',
        valuePath: 'candidato.nombreCompleto',
        sortable: false,
        width: '200px',
        filtrable: true,
        filterName: 'candidato.nombreCompleto'
      }, {
        label: 'Puesto',
        valuePath: 'factorhPuestoStr',
        sortable: false,
        width: '150px',
        filtrable: true,
        filterName: 'factorhPuestoStr'
      }, {
        label: 'Fecha de alta',
        valuePath: 'fechaAltaFormatted',
        sortable: false,
        width: '100px'
      }, {
        label: 'Fecha de baja',
        valuePath: 'fechaBajaFormatted',
        sortable: false,
        width: '100px'
      }, {
        label: 'Activo',
        valuePath: 'activoFormatted',
        sortable: false,
        width: '50px',
        filtrable: true,
        filterName: 'activo'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        width: '100px',
        table: this,
        sortable: false,
        objectActions: {
          verCandidato: {
            title: 'Ver información del candidato',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.verCandidato.bind(this)
          },
          verEmpleado: {
            title: 'Ver información del empleado',
            buttonClass: 'btn-warning',
            icon: 'user',
            action: this.actions.verEmpleado.bind(this)
          },
          agregarCargo: {
            title: 'Agregar cargo',
            buttonClass: 'btn-success',
            icon: 'file',
            action: this.actions.agregarCargo.bind(this)
          },
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.delete.bind(this)
          }
        }
      }]);
    }),
    actions: {
      agregarCargo(proyectoCandidato) {
        const proyectoCandidatoId = proyectoCandidato.get('id');
        const candidatoId = proyectoCandidato.get('candidato.id');
        this.transitionToRoute('proyecto.record.empleado.cargos.agregar', 'new', {
          queryParams: {
            'proyectoCandidatoId': proyectoCandidatoId,
            'candidatoId': candidatoId
          }
        });
      },

      verCandidato(proyectoCandidato) {
        const candidatoId = proyectoCandidato.get('candidato.id');
        this.transitionToRoute('candidato.record.informacion', candidatoId);
      },

      verEmpleado(proyectoCandidato) {
        this.transitionToRoute('proyecto.record.empleado.informacion', proyectoCandidato.get('id'));
      }

    }
  });

  _exports.default = _default;
});