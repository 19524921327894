define("ods-app/controllers/cliente/record/proyectos", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "ods-app/helpers/is-empresa"], function (_exports, _abstractModuleIndex, _isEmpresa) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    modelName: 'proyecto',
    editRoute: 'proyecto.record.servicio',
    currentUser: Ember.inject.service(),
    columns: Ember.computed(function () {
      let columns = [{
        label: 'Código',
        valuePath: 'codigo',
        sortable: false,
        width: '100px'
      }, {
        label: 'Estatus',
        valuePath: 'statusToString',
        sortable: false,
        width: '100px'
      }, {
        label: 'Fecha de solicitutd',
        valuePath: 'fechaSolicitudFormatted',
        sortable: false,
        width: '100px'
      }];

      if ((0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'OPTIMUM')) {
        columns.push({
          label: 'Fecha de alta',
          valuePath: 'fechaAltaFormatted',
          sortable: false,
          width: '100px'
        }, {
          label: 'Monto a facturar',
          valuePath: 'montoFacturarFormatted',
          sortable: false,
          width: '100px',
          align: 'right'
        });
      }

      if ((0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'EL_TAIER') || (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'DEMO') || (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'SERVINOVA')) {
        columns.push({
          label: 'Depto. en FactoRH',
          valuePath: 'factorhDepartamentoStr',
          sortable: false,
          width: '100px'
        });
      }

      columns.push({
        label: 'Servicio',
        valuePath: 'servicioAdquirido.nombre',
        sortable: false,
        width: '150px'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        sortable: false,
        width: '100px',
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.edit.bind(this)
          } // delete: {
          //   title: 'Eliminar',
          //   buttonClass: 'btn-danger',
          //   icon: 'trash-o',
          //   action: this.actions.delete.bind(this),
          // }

        }
      });
      return Ember.A(columns);
    })
  });

  _exports.default = _default;
});