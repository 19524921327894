define("ods-app/routes/cliente/record/perfiles-puestos/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "servir-ember-utilities/mixins/route/has-tabs", "ember-changeset-validations/validators"], function (_exports, _abstractModuleRecord, _hasTabs, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend(_hasTabs.default, {
    modelName: 'cliente-perfil-puesto',
    indexRoute: 'cliente.record.perfiles-puestos',
    defaultTabSubRoute: 'descriptor',
    routeRecordIdentifierDynamicSegment: 'perfilespuestos_id',
    validations: Ember.computed(function () {
      return {
        nombreDepartamento: (0, _validators.validatePresence)({
          presence: true
        }),
        grupoPertenece: (0, _validators.validatePresence)({
          presence: true
        }),
        nombrePuesto: (0, _validators.validatePresence)({
          presence: true
        }),
        jefeInmediatoAdministrativo: (0, _validators.validatePresence)({
          presence: true
        }),
        jefeOperacional: (0, _validators.validatePresence)({
          presence: true
        }),
        directorGeneral: (0, _validators.validatePresence)({
          presence: true
        }),
        rangoSalarial: (0, _validators.validatePresence)({
          presence: true
        })
      };
    }),

    createRecordInstance()
    /*params, transition*/
    {
      const modelName = this.get('modelName');
      const cliente = this.modelFor('cliente.record').record;
      return this.store.createRecord(modelName, {
        cliente
      });
    },

    actions: {
      willTransition() {
        return true;
      }

    }
  });

  _exports.default = _default;
});