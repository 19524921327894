define("ods-app/controllers/cliente/record/perfiles-puestos", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "ods-app/mixins/ods-app-file-saver", "ods-app/models/cliente-perfil-puesto", "ods-app/utils/common"], function (_exports, _abstractModuleIndex, _odsAppFileSaver, _clientePerfilPuesto, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend(_odsAppFileSaver.default, {
    editRoute: 'cliente.record.perfiles-puestos.record',
    modelClass: _clientePerfilPuesto.default,
    reload: true,
    modelFilters: Ember.computed('record', function () {
      return [{
        selectedFilter: {
          filterName: 'cliente'
        },
        value: this.get('record.id') || -1
      }];
    }),
    columns: Ember.computed(function () {
      return Ember.A([{
        label: 'Departamento',
        valuePath: 'nombreDepartamento',
        sortable: false,
        width: '100px',
        filtrable: true,
        filterName: 'tipo'
      }, {
        label: 'Puesto',
        valuePath: 'nombrePuesto',
        sortable: false,
        width: '100px',
        filtrable: true,
        filterName: 'nombre'
      }, {
        label: 'Jefe inmediato administrativo',
        valuePath: 'jefeInmediatoAdministrativo',
        sortable: false,
        width: '100px'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        width: '100px',
        sortable: false,
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.edit.bind(this)
          },
          copiar: {
            title: 'Copiar',
            buttonClass: 'btn-success',
            icon: 'copy',
            action: this.actions.copiarPerfilPuesto.bind(this)
          },
          descargar: {
            title: 'Descargar pdf',
            buttonClass: 'btn-success',
            icon: 'download',
            action: this.actions.downloadPdf.bind(this)
          },
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.delete.bind(this)
          }
        }
      }]);
    }),

    reloadModel() {
      this.set('reload', true);
    },

    actions: {
      copiarPerfilPuesto(perfilPuesto) {
        this.send('openModal', 'popups/simple-confirm', {
          title: `¿Está seguro que quiere copiar el perfil de puesto?`,
          message: ``,
          onSubmit: () => {
            let nuevoPerfilPuesto = this.store.createRecord('cliente-perfil-puesto', {
              cliente: perfilPuesto.get('cliente'),
              nombreDepartamento: perfilPuesto.get('nombreDepartamento'),
              grupoPertenece: perfilPuesto.get('grupoPertenece'),
              nombrePuesto: perfilPuesto.get('nombrePuesto'),
              jefeInmediatoAdministrativo: perfilPuesto.get('jefeInmediatoAdministrativo'),
              jefeOperacional: perfilPuesto.get('jefeOperacional'),
              directorGeneral: perfilPuesto.get('directorGeneral'),
              puestosSupervisa: perfilPuesto.get('puestosSupervisa'),
              objetivoPuesto: perfilPuesto.get('objetivoPuesto'),
              funcionesResponsabilidadesClave: perfilPuesto.get('funcionesResponsabilidadesClave'),
              relacionesInternas: perfilPuesto.get('relacionesInternas'),
              relacionesExternas: perfilPuesto.get('relacionesExternas'),
              formacionAcademica: perfilPuesto.get('formacionAcademica'),
              formacionAcademicaCompleta: perfilPuesto.get('formacionAcademicaCompleta'),
              gradoAcademico: perfilPuesto.get('gradoAcademico'),
              observacionesGradoAcademico: perfilPuesto.get('observacionesGradoAcademico'),
              maestria: perfilPuesto.get('maestria'),
              maestriaEstatus: perfilPuesto.get('maestriaEstatus'),
              maestriaObservaciones: perfilPuesto.get('maestriaObservaciones'),
              otrosCursos: perfilPuesto.get('otrosCursos'),
              otrosCursosObservaciones: perfilPuesto.get('otrosCursosObservaciones'),
              colegiatura: perfilPuesto.get('colegiatura'),
              cantidadTotalExperiencia: perfilPuesto.get('cantidadTotalExperiencia'),
              aniosTipoExperiencia: perfilPuesto.get('aniosTipoExperiencia'),
              situacionAcademica: perfilPuesto.get('situacionAcademica'),
              disponibilidadViaje: perfilPuesto.get('disponibilidadViaje'),
              horarioTrabajo: perfilPuesto.get('horarioTrabajo'),
              frecuencia: perfilPuesto.get('frecuencia'),
              cambioResidencia: perfilPuesto.get('cambioResidencia'),
              periodosEspecialesTrabajo: perfilPuesto.get('periodosEspecialesTrabajo'),
              beneficios: perfilPuesto.get('beneficios')
            });
            return nuevoPerfilPuesto.save().then(() => {
              this.store.query('competencia', {
                filter: {
                  clientePerfilPuesto: perfilPuesto.get('id')
                }
              }).then(competencias => {
                competencias.forEach(competencia => {
                  let nuevaCompetencia = this.store.createRecord('competencia', {
                    clientePerfilPuesto: nuevoPerfilPuesto,
                    competencia: competencia.get('competencia'),
                    grado: competencia.get('grado'),
                    tipo: competencia.get('tipo')
                  });
                  nuevaCompetencia.save();
                });
              });
              this.toast.success('Perfil de puesto copiado correctamente.');
              this.reloadModel();
              this.transitionToRoute('cliente.record.perfiles-puestos.record', nuevoPerfilPuesto.id);
            }).catch(error => (0, _common.handleChangesetSaveErrors)(nuevoPerfilPuesto, error, this.toast));
          }
        });
      },

      downloadPdf(perfilPuesto) {
        return this.getFileFromServer(`/cliente/perfil-puesto/${perfilPuesto.get('id')}/descarga_pdf`);
      }

    }
  });

  _exports.default = _default;
});