define("ods-app/controllers/requerimiento/record/ingreso", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    toast: Ember.inject.service(),
    datesCalculator: Ember.inject.service(),
    currentDate: null,
    loader: Ember.inject.service(),
    aplicacionesContratadasOCompletadas: Ember.computed('changeset.aplicaciones.@each.estatus', function () {
      return this.get('changeset.aplicaciones').filter(aplicacion => aplicacion.get('estatus') === this.constants.APPLICATION_STATUS_CONTRATADO || aplicacion.get('estatus') === this.constants.APPLICATION_STATUS_COMPLETADO);
    }),
    hasPlazasPendientesDeConfirmar: Ember.computed('changeset.{aplicaciones.@each.estatus,cantidadAplicaciones}', function () {
      let aplicacionesContratadasOCompletadas = this.get('changeset.aplicaciones').filter(aplicacion => aplicacion.get('estatus') === this.constants.APPLICATION_STATUS_CONTRATADO || aplicacion.get('estatus') === this.constants.APPLICATION_STATUS_COMPLETADO);
      return this.get('changeset.cantidadPlazas') > aplicacionesContratadasOCompletadas.length;
    }),
    hasPlazasPendientesDeSeleccionar: Ember.computed('changeset.{aplicaciones.@each.estatus,cantidadAplicaciones}', function () {
      let aplicacionesConfirmadas = this.get('changeset.aplicaciones').filter(aplicacion => aplicacion.get('estatus') === this.constants.APPLICATION_STATUS_CONTRATADO || aplicacion.get('estatus') === this.constants.APPLICATION_STATUS_CONFIRMADO_PENDIENTE || aplicacion.get('estatus') === this.constants.APPLICATION_STATUS_COMPLETADO);
      return this.get('changeset.cantidadPlazas') > aplicacionesConfirmadas.length;
    }),
    cantidadDiasProceso: Ember.computed('changeset.{fechaFinalizacion,fechaSolicitud}', function () {
      if (!this.changeset.get('fechaSolicitud')) {
        return;
      }

      var comparacion = new Date();

      if (this.changeset.get('fechaFinalizacion')) {
        comparacion = this.get('changeset.fechaFinalizacion');
      }

      let diferencia = this.get('datesCalculator').getCantidadDiasHabiles(this.changeset.get('fechaSolicitud'), comparacion);
      return diferencia;
    }),
    actions: {
      seleccionarAplicacion(aplicacion, param2, status) {
        if (status) {
          aplicacion.set('seleccionado', true);
          aplicacion.set('estatus', this.constants.APPLICATION_STATUS_CONFIRMADO_PENDIENTE);
        } else {
          aplicacion.set('seleccionado', false);
          aplicacion.set('estatus', this.constants.APPLICATION_STATUS_EN_TERNA);
        }

        return aplicacion.save();
      },

      reactivarUltimaTerna(ternaActiva) {
        const aplicacionesTerna = ternaActiva.get('aplicaciones');
        const requerimiento = ternaActiva.get('requerimiento');
        const cantidadPlazas = requerimiento.get('cantidadPlazas');
        let contador = 0;
        aplicacionesTerna.forEach(aplicacion => {
          if (aplicacion.get('estatus') === this.constants.APPLICATION_STATUS_CONTRATADO || aplicacion.get('estatus') === this.constants.APPLICATION_STATUS_COMPLETADO) {
            contador += 1;
          }
        });

        if (contador === cantidadPlazas) {
          this.toast.error('No es posible reiniciar las selecciones.');
          return;
        }

        this.set('cantidadAplicaciones', contador);
        aplicacionesTerna.forEach(aplicacion => {
          if (aplicacion.seleccionado && aplicacion.get('estatus') !== this.constants.APPLICATION_STATUS_CONTRATADO && aplicacion.get('estatus') !== this.constants.APPLICATION_STATUS_COMPLETADO) {
            aplicacion.set('seleccionado', false);
            aplicacion.set('fechaIngreso', null);
            aplicacion.set('estatus', this.constants.APPLICATION_STATUS_EN_TERNA);
            aplicacion.save();
          }
        });
        ternaActiva.set('aceptada', false);
        return ternaActiva.save().then(() => {
          requerimiento.set('estatus', this.constants.REQUIREMENT_STATUS_VACANTE);
          Ember.RSVP.resolve(requerimiento).then(requerimiento => requerimiento.save());
        });
      },

      guardarCambios(aplicacion, propiedad, fecha) {
        if (!aplicacion.get(propiedad)) {
          return;
        }

        fecha.setHours(0, 0, 0, 0);
        aplicacion.set(propiedad, fecha);
        aplicacion.save();
      },

      asociarPlanilla(aplicacion) {
        const aplicacionId = aplicacion.get('id');

        if (!aplicacionId) {
          this.toast.error('No hay una aplicacion seleccionada.');
          return;
        }

        if (!aplicacion.get('fechaIngreso')) {
          this.toast.error('Ingrese la fecha de ingreso de la aplicación.');
          return;
        } // Si el tipo de servicio del proyecto de este requerimiento es ya administración
        // de planilla en FactoRH entonces intentar procesar y no levantar el modal


        let requerimiento = aplicacion.get('requerimiento');
        let proyecto = requerimiento.get('proyecto');
        let tipoServicio = proyecto.get('servicioAdquirido.tipoServicio');

        if (this.constants.SERVICE_TYPES_PAYROLL_ADMINISTRATION_FACTORH === tipoServicio) {
          if (!proyecto.get('factorhDepartamentoId')) {
            this.toast.error('El proyecto debe tener asociado un departamento de FactoRH.');
            return;
          }

          let proyectoCandidato = this.store.createRecord('proyecto-candidato', {
            proyecto: proyecto,
            requerimiento: requerimiento,
            candidato: aplicacion.get('candidato'),
            fechaAlta: aplicacion.get('fechaIngreso'),
            activo: true
          });
          this.loader.setIsLoading();
          return proyectoCandidato.save().then(() => {
            aplicacion.set('estatus', this.constants.APPLICATION_STATUS_CONTRATADO);
            aplicacion.save();
            this.toast.success('Registro creado correctamente.');
          }).catch(error => {
            var errorMessage = 'No se pudo asociar el candidato al proyecto';

            if (!Ember.isNone(error.errors)) {
              errorMessage += JSON.stringify(error.errors);
            }

            this.toast.error(errorMessage);
            throw error;
          }).finally(() => this.loader.setNotLoading());
        }

        this.transitionToRoute('requerimiento.record.ingreso.asociar-proyecto', {
          queryParams: {
            'aplicacionId': aplicacionId
          }
        });
      },

      marcarCompletada(aplicacion) {
        if (!aplicacion.get('fechaIngreso')) {
          this.toast.error('Ingrese la fecha de ingreso de la aplicación.');
          return;
        }

        this.loader.setIsLoading();
        aplicacion.set('estatus', this.constants.APPLICATION_STATUS_COMPLETADO);
        return aplicacion.save().then(() => {
          this.toast.success('Aplicación completada correctamente.');
        }).catch(error => {
          var errorMessage = 'No se pudo completar la aplicación.';

          if (!Ember.isNone(error.errors)) {
            errorMessage += JSON.stringify(error.errors);
          }

          this.toast.error(errorMessage);
          throw error;
        }).finally(() => this.loader.setNotLoading());
      },

      asociarPlanillaElTaier(aplicacion) {
        const aplicacionId = aplicacion.get('id');
        let requerimiento = aplicacion.get('requerimiento');

        if (!aplicacionId) {
          this.toast.error('No hay una aplicación seleccionada.');
          return;
        }

        if (!aplicacion.get('fechaIngreso')) {
          this.toast.error('Ingrese la fecha de ingreso de la aplicación.');
          return;
        }

        if (requerimiento.get('plaza') === this.constants.JOB_TYPES_EL_TAIER_TEMPORAL) {
          if (!aplicacion.get('fechaFinLabores')) {
            this.toast.error('Ingrese la fecha de fin de labores de la aplicación.');
            return;
          }
        }

        this.transitionToRoute('requerimiento.record.ingreso.asociar-planilla', 'new', {
          queryParams: {
            aplicacionId
          }
        });
      },

      mandarLinkElTaier(aplicacion) {
        this.send('openModal', 'popups/simple-confirm', {
          title: `¿Está seguro que desea enviar el link al candidato?`,
          message: null,
          onSubmit: () => {
            aplicacion.set('notificado', true);
            aplicacion.save();
          }
        });
      }

    }
  });

  _exports.default = _default;
});