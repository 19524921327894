define("ods-app/models/candidato", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    aplicaciones: hasMany('aplicacion'),
    codigo: attr('string'),
    primerNombre: attr('string'),
    segundoNombre: attr('string'),
    primerApellido: attr('string'),
    segundoApellido: attr('string'),
    apellidoCasada: attr('string'),
    uid: attr('string'),
    nit: attr('string'),
    pasaporte: attr('string'),
    numeroSeguroSocial: attr('string'),
    sexo: attr('string'),
    fechaNacimiento: attr('custom-date'),
    emails: attr(),
    celulares: attr(),
    telefonos: attr(),
    numerosEmergencia: attr(),
    ocupacion: attr('string'),
    gradoAcademico: attr('string'),
    gradoAcademicoDescripcion: attr('string'),
    experiencia: attr('string'),
    archivos: hasMany('candidato-archivo'),
    paisNacimiento: belongsTo('pais'),
    departamentoNacimiento: belongsTo('departamento'),
    municipioNacimiento: belongsTo('municipio'),
    paisVecindad: belongsTo('pais'),
    departamentoVecindad: belongsTo('departamento'),
    municipioVecindad: belongsTo('municipio'),
    observaciones: attr('string'),
    listaNegra: attr('boolean', {
      defaultValue: false
    }),
    disponible: attr('boolean', {
      defaultValue: true
    }),
    razonListaNegra: attr('string'),
    proyectoCandidatos: hasMany('proyecto-candidato'),
    factorhEmpleadoId: attr('number'),
    direccion: attr('string'),
    tieneCuentaBi: attr('boolean', {
      defaultValue: false
    }),
    tieneCuentaExtranjero: attr('boolean', {
      defaultValue: false
    }),
    tipoCuentaBi: attr('string'),
    numeroCuentaBi: attr('string'),
    nombreBancoExtranjero: attr('string'),
    tipoCuentaExtranjero: attr('string'),
    numeroCuentaExtranjero: attr('string'),
    hashRequerimiento: attr('string'),
    experiencias: hasMany('experiencia-laboral'),
    estadoCivil: attr('string'),
    tieneHijos: attr('boolean', {
      defaultValue: false
    }),
    cantidadHijos: attr('number'),
    uidExtension: attr('string'),
    contactoEmergencia: attr('string'),
    parentescoContactoEmergencia: attr('string'),
    tieneFamiliarEnEmpresa: attr('string'),
    tieneEnfermedadCronica: attr('boolean', {
      defaultValue: false
    }),
    enfermedadCronica: attr('string'),
    tituloNivelMedio: attr('string'),
    estudiaUniversidad: attr('boolean', {
      defaultValue: false
    }),
    carreraUniversidad: attr('string'),
    horarioUniversidad: attr('string'),
    tieneUniversidad: attr('boolean', {
      defaultValue: false
    }),
    tituloUniversidad: attr('string'),
    tieneMaestria: attr('boolean', {
      defaultValue: false
    }),
    tituloMaestria: attr('string'),
    tieneEstudioTecnico: attr('boolean', {
      defaultValue: false
    }),
    tituloEstudioTecnico: attr('string'),
    hablaOtroIdioma: attr('boolean', {
      defaultValue: false
    }),
    otrosIdiomas: attr(),
    ultimaExperiencia: Ember.computed('experiencias', function () {
      let lastExperiencia = this.experiencias.get('lastObject');
      return lastExperiencia;
    }),
    primerReq: Ember.computed('aplicaciones', function () {
      const primerReq = this.aplicaciones.get('firstObject');
      return primerReq;
    }),
    fechaNacimientoFormatted: Ember.computed('fechaNacimiento', function () {
      const momentDate = (0, _moment.default)(this.fechaNacimiento);

      if (Ember.isNone(this.fechaNacimiento) || !momentDate.isValid()) {
        return '';
      }

      return momentDate.format('DD/MM/YYYY');
    }),
    telefono: Ember.computed('telefonos', function () {
      return this.telefonos.firstObject;
    }),
    uidNombre: Ember.computed('uid', 'pasaporte', 'nombreCompleto', function () {
      return `${this.uid ? this.uid : this.pasaporte} - ${this.nombreCompleto}`;
    }),
    nombreCompleto: Ember.computed('primerNombre', 'segundoNombre', 'primerApellido', 'segundoApellido', function () {
      return `${this.primerNombre} ${this.segundoNombre || ''} ${this.primerApellido} ${this.segundoApellido || ''}`;
    })
  });

  _exports.default = _default;
});