define("ods-app/controllers/requerimiento/record/informacion-compensacion/documentos", ["exports", "servir-ember-utilities/controllers/abstract-module-record", "ods-app/mixins/upload-helper"], function (_exports, _abstractModuleRecord, _uploadHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend(_uploadHelper.default, {
    indexCtrl: Ember.inject.controller('requerimiento.record.informacion-compensacion'),
    indexRoute: 'requerimiento.record.informacion-compensacion',
    postSaveTransitionRoute: Ember.computed.readOnly('indexRoute'),

    postSave() {
      this.indexCtrl.reloadModel();
    },

    processSave(changeset) {
      let superRef = this._super.bind(this);

      if (changeset.get('upload.id')) {
        return superRef(...arguments);
      }

      return this.uploadCurrentFile().then(uploadRecord => {
        changeset.set('upload', uploadRecord);
        return superRef(...arguments);
      });
    },

    submit() {
      let superRef = this._super.bind(this);

      if (this.changeset.get('upload.id')) {
        return superRef(...arguments);
      } // Validation requires the file be validated as well. Even though the parent
      // validates the changeset, we validate it here to so that if it has errors,
      // they are displayed at the same time as file changeset errors are displayed.


      return Ember.RSVP.all([this.changeset.validate(), this.fileChangeset.validate()]).then(() => {
        if (!this.changeset.get('isValid')) {
          this.toast.error('Hay errores con el formulario.');
          return;
        }

        if (!this.fileChangeset.get('isValid')) {
          this.toast.error('Hay errores con el archivo.');
          return;
        }

        return superRef(...arguments);
      });
    },

    actions: {
      discardChanges() {
        if (this.changeset.get('isDirty')) {
          this.record.rollbackAttributes();
        }

        this.transitionToRoute(this.get('indexRoute'));
      }

    }
  });

  _exports.default = _default;
});