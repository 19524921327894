define("ods-app/models/factura", ["exports", "ember-data", "moment", "accounting/format-number"], function (_exports, _emberData, _moment, _formatNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    fechaInicio: attr('custom-date'),
    fechaFin: attr('custom-date'),
    fechaFacturacion: attr('custom-date', {
      defaultValue() {
        return new Date();
      }

    }),
    monto: attr('number'),
    estatus: attr('string'),
    proyecto: belongsTo('proyecto'),
    serie: attr('string'),
    numero: attr('string'),
    llaveSirvo: attr('string'),
    nombreFacturaEmision: attr('string'),
    direccionFacturaEmision: attr('string'),
    descripcionFacturaEmision: attr('string'),
    fechaTransaccion: attr('custom-date'),
    createdAt: attr('custom-date'),
    observaciones: attr('string'),
    fechaInicioFormatted: Ember.computed('fechaInicio', function () {
      const momentDate = (0, _moment.default)(this.fechaInicio);

      if (Ember.isNone(this.fechaInicio) || !momentDate.isValid()) {
        return '';
      }

      return momentDate.format('DD/MM/YYYY');
    }),
    fechaFinFormatted: Ember.computed('fechaFin', function () {
      const momentDate = (0, _moment.default)(this.fechaFin);

      if (Ember.isNone(this.fechaFin) || !momentDate.isValid()) {
        return '';
      }

      return momentDate.format('DD/MM/YYYY');
    }),
    fechaFacturacionFormatted: Ember.computed('fechaFacturacion', function () {
      const momentDate = (0, _moment.default)(this.fechaFacturacion);

      if (Ember.isNone(this.fechaFacturacion) || !momentDate.isValid()) {
        return '';
      }

      return momentDate.format('DD/MM/YYYY');
    }),
    statusToString: Ember.computed('estatus', function () {
      const status = this.constants.BILLING_STATUS.findBy('key', this.estatus);
      return status ? status.name : this.status;
    }),
    montoFormatted: Ember.computed('monto', function () {
      return (0, _formatNumber.default)(this.get('monto'), 2);
    }),
    createdAtFormatted: Ember.computed('createdAt', function () {
      const momentDate = (0, _moment.default)(this.createdAt);

      if (Ember.isNone(this.createdAt) || !momentDate.isValid()) {
        return '';
      }

      return momentDate.format('DD/MM/YYYY');
    })
  });

  _exports.default = _default;
});